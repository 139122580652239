import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  logoClass: {
    top: "28px",
    left: "304px",
    width: "180px",
    height: "85px",
    cursor: 'pointer'
  },
  menuItems: {
    my: 2,
    color: "#414389",
    fontSize: "22px",
    margin: "6px 20px",
    display: "block",
    textTransform: "inherit",
    paddingLeft: '4px',
    paddingRight: '4px',

    '&:hover': {
    "@media (max-width:1110px)": {
        // paddingBottom: "8px !important",
        // borderBottomStyle: "solid !important",
        // borderBottomWidth: "3.1px !important",
        width: "inherit !important"
      },
      // textDecoration: 'underline !important',
      paddingBottom: "8px !important",
      borderBottomStyle: "solid !important",
      borderBottomWidth: "3.1px !important",
      width: "inherit",
    },
    '&:focus':{
      paddingLeft: '4px',
      paddingRight: '4px',
      paddingBottom: "8px !important",
      borderBottomStyle: "solid !important",
      borderBottomWidth: "3.1px !important",
      // width: "inherit",
      // border : '2px solid black'
    }
  },
  mobileItems: {
    color: "#414389",
    fontSize: "22px",
    padding: "6px 24px",
    textTransform: "none",
    margin: "0 auto",
    display: "none !important",
    "@media (max-width:1110px)": {
      display: "block !important",
    },
  },
  persona: {
    flexGrow: 0,
    display: "flex",
    justifyContent: "center",
    marginLeft: "1rem",
    marginTop: '-1.5%',
    "@media (max-width:1110px)": {
      margin:0,
      marginTop: '3.5rem',
      right: '50px',
      cursor: 'pointer',
      display: 'inline-block',
      position: 'absolute',
      transform: 'translateY(-50%)'
    },
  },
  navigation: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "1.25rem 5rem 1.25rem 5rem",
    backgroundColor: "#fff",
    boxShadow: "0 2px 2px 2px #00000029",
    // "@media (max-width:1110px)": {
    //   padding: "1.25rem 5rem 1.25rem 1.25rem",
    // },
    "@media (max-width:1110px)": {
      padding: "1.25rem",
    },
  },
  navigationMenu: {
    marginLeft: "auto",
    "& ul": {
      display: "flex",
      padding: "0",
      "@media (max-width:1110px)": {
        flexDirection: "column",
        position: "absolute",
        top: "112px",
        left: "0",
        width: "100%",
        height: "calc(100vh - -80px)",
        backgroundColor: "white",
        borderTop: "1px solid #00000029",
        display: "none",
      },
    },
    "& li": {
      listStyleType: "none",
      margin: "0 1rem",
      "@media (max-width:1110px)": {
        margin: "0",
      },
      "& :hover": {
        "@media (max-width:1110px)": {
          backgroundColor: "#eee",
          textAlign: "center",
        margin: "0",
        },
      },
      "& a": {
        textDecoration: "none",
        display: "block",
        "@media (max-width:1110px)": {
          width: "100%",
          padding: "1.5rem 0",
          borderRadius: "0 !important",
        },
      },
    },
  },
  navigationMenuExpanded: {
    "& ul": {
      "@media (max-width:1110px)": {
        display: "block",
      },
    },
  },
  hamburger: {
    border: "0",
    height: "40px",
    width: "40px",
    padding: "0.5rem",
    borderRadius: "50%",
    cursor: "pointer",
    transition: "background-color 0.2s ease-in-out",
    position: "absolute",
    top: "50%",
    right: "260px",
    fontSize: "1.375rem",
    fontFamily: "AssistantSemiBold",
    color: "#2273c3",
    transform: "translateY(-50%)",
    display: "none",
    "@media (max-width:1110px)": {
      display: "block",
    },
  },
  text: {
    display:'inline-block', 
    width: '8rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }, 
  avatarName: {
    color: '#271C6F',
    fontSize: "1.375rem",
    paddingLeft: '0.5rem',
    fontFamily: "AssitantSemiBold",
    textAlign: "left",
    "@media (max-width:1110px)": {
      display: "block",
    },
  },
  userDownIcon: {
    width: "1.5rem",
    height: "1.5rem",
    position: "relative",
    bottom: "0.125rem",
    left: "0.125rem",
  },
  userRole: {
    float: 'left',
    color: "#767676",
    fontSize: "1.125rem",
    fontFamily: "AssitantSemiBold, Arial,Roboto, SansSerif",
  },
  logoutClass: {
    textTransform: 'capitalize',
    color: '#271C6F',
    fontSize: "1rem",
    fontFamily: "AssitantSemiBold",
    backgroundColor:'transparent',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  menuPaper: {
    // position:'relative',
    width: '467px',
    height: '495px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #CFCFCF',
    borderRadius: '8px',
    opacity: 1
  },
  notificationIcon: {
    position: 'relative',
    display: 'inline-block',
   "@media (max-width:1110px)": {
      display: 'inline-block',
      top: '55%',
      right: '320px',
      cursor: 'pointer',
      position: 'absolute',
      transform: 'translateY(-50%)'
    },
  },
  badge: {
    color: 'white',
    padding: '6px 14.5px 1px 1px',
    fontSize: '0.8rem',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  headingIcon:{
    display:'flex',
    justifyContent: 'space-between',
    alignItems:'center',
    padding:'1rem',
  },
  chkBox:{
    width:'1.25rem',
    height:'1.25rem',
  },
  notificationPopup:{
    marginTop:'30px',
    maxHeight:'80vh',
  },
  notificationBody:{
    height:'90%',
  },

  notificationContainer:{
    height:'300px',
    display:'flex',
    flexDirection:'column',
    overflowY:'scroll',
    flexWrap:'nowrap'

  },
  notificationHeading:{
    textAlign: 'left',
    font: 'bold 1.25rem AssistantRegular',
    letterSpacing: '0px',
    color: '#404040',
    margin:0,
    opacity: 1
  },
  notificationText:{
    padding: '1rem 0 1rem 0.75rem',
    margin: 0,
    letterSpacing: '0px',
    color: '#000000',
    '&.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label':{
      font: '1.125rem AssistantRegular',

    }
  },
  viewAllButton:{
    position: 'relative',
    border: 'none',
    backgroundColor: 'white',
    padding: '0.75rem',
    textAlign: 'center',
    font: '1.25rem AssistantRegular',
    fontWeight:'600',
    color: '#271C6F',
    cursor: 'pointer'
  },
  markAllButton:{
    font: '1.25rem AssistantRegular',
    fontWeight:'600',
    backgroundColor:'white',
    color: '#271C6F',
    border:'none',
    cursor: 'pointer',
  },
  selectAllButton:{
      font: '1.25rem AssistantRegular',
      fontWeight:'600',
      backgroundColor:'white',
      color: '#271C6F',
      alignItems:'center',
      display:'flex'
  },
  infoClass:{
    border:'none',
    backgroundColor:'#FFFFFF'
  }
});

export const setHeaderRole = (headerRole: any,) => {

  if (headerRole.includes('Supervisor')) {
   return ('Supervisor');
  } else if (headerRole.includes('CaseWorker')) {
    return('Case Worker');
  } else if (headerRole.includes('RespondentAdmin')) {
    return('Respondent Admin');
  } else if (headerRole.includes('OmbudsmanAdmin')) {
    return('Ombudsman Admin');
  }
  return headerRole;
}