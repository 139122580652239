import React from "react";

interface Props {
  border?: string;
  bgColor?: string
  textColor?: string;
  padding?: string;
  height?: string;
  id?: string;
  radius?: string;
  width?: string;
  disable?: boolean
  children?: React.ReactNode;
  onClick: () => void;
  className?:string;
  refprop?:React.MutableRefObject<any>
}

const Button: React.FC<Props> = ({
  border,
  bgColor,
  textColor,
  padding,
  height,
  id,
  radius,
  width,
  disable,
  children,
  onClick,
  className,
  refprop
}) => {
  return (
    <button
    id={id}
    tabIndex={0}
    autoFocus={true}
    className={className}
    ref={refprop}
    disabled={disable}
    onClick={onClick}
    onKeyDown={(e) => { if(e.key==='Enter') {onClick()}}}
    style={{
        color: textColor,
        backgroundColor: bgColor,
        border,
        padding,
        borderRadius: radius,
        height,
        width,
      }}
    >
      {children}
    </button>
  );
}

export default Button;