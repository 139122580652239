import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import {makeStyles} from '@mui/styles';
import Icon_deleteFile from '../../assets/icons/Icon_Cross.svg';
import Icon_Upload_Successfull from '../../assets/icons/Icon_Upload Successfull.svg';
import {useLocation, useNavigate} from "react-router-dom";
import { DRUPALSERVER } from '../../common/drupal-api/drupal-api-urls';
import DrupalService from '../../common/drupal-api/drupal-api-service';
import { Dispatch } from 'redux';
import { useDispatch, useSelector} from 'react-redux';
import {clearFiles} from '../../stores/uploadFiles/UploadSlice';
import CommonService from '../../common/api/common-api-service';
import { UPLOAD_SERVER } from '../../common/upload-api/api-urls';
import { clearPercentages } from '../../stores/uploadFiles/UploadProgressSlice';
import { updateBlobCount, updateWaitStatus} from '../../stores/uploadFiles/UploadStatusSlice';
import {Snackbar, Alert} from '@mui/material';
import {addExtractedZip, clearExtractedZip} from '../../stores/uploadFiles/UploadZipSlice';

const commonApi = new CommonService('upload');
const drupalApi = new DrupalService();
const defaultDrupalData = [
    { 
        "needHelp": "Need help with uploading documents?", 
        "packageStatus": "Package upload status", 
        "sizeLimit": "100 MB", 
        "dragAndDrop": "Drag and drop to upload files here", 
        "or": "Or", 
        "browseFilesBtn": "Browse files", 
        "supportedType": "Document types supported: .PDF, .DOC, .DOCX, .CSV, .TXT, .XLSX, .XLS, .JPG, .JPEG, .PNG, .GIF, .MP3, .WMA, .AVI, .MP4, .ZIP", 
        "fileSize": "Maximum file size: 3 MBs for each document", 
        "backBtn": "Back to your case details", 
        "proceedBtn": "Proceed", 
        "completedLabel": "% completed",
        'networkError': 'There was network error uploading the files please try again later.'
    }
];

const UploadProgressBar = ({ files, deleteFile, pnx, about, percentage}: any) => {
    const classes = useStyles();
    let navigate = useNavigate(); 
    const dispatch: Dispatch<any> = useDispatch();
    const { pathname } = useLocation();
    const incId = pathname.split('/');
    const [ isDisabled, setIsDisabled ] = useState<boolean>(true);
    const [openToaster, setOpenToaster] = useState(false);
    const [drupalData, setDrupalData] = useState<any>(defaultDrupalData);
    const uploadStatusOkay = useSelector((store:any)=>store.uploadStatusSlice?.uploadStatus);
    const reduxBlobCount = useSelector((store:any)=>store.uploadStatusSlice?.blobCount);
    useEffect(() => {
        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getUploadMainPage, 'get')
            .then((res) => {
                if (res.length > 0) {
                    setDrupalData(res);
                } else {
                    setDrupalData(defaultDrupalData);
                }
            })
            .catch((_e: any) => {
                setDrupalData(defaultDrupalData);
            });
    }, []);

    const getPercentage = (input:number)=>{
        if(!input) return 0;
        return input;
    }

    const listener = (event: React.KeyboardEvent<HTMLElement>, name:string) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
          deleteFile(name);
        }
      };

    const fileType =(name:string)=>{
        let parts = name?.split(".");
        return (parts[parts.length-1]);
    }
    let zippedFiles = []
    const removeZipFile=async ()=>{
        let payloadRequestedForZip = {
            packageId: sessionStorage.getItem('packageId'),
            documentIds: zippedFiles
        }
        await files.forEach((file: any) => {
            if (fileType(file.name) === "zip"){
                zippedFiles.push(percentage[file.name]?.documentId)
            }
        })
        if (zippedFiles.length === 0)
        return;
        commonApi.httpConnection(UPLOAD_SERVER.SERVICES.getZipFiles, 'post', payloadRequestedForZip)
            .then(async(res)=>{
                if (res?.status === 200) {
                    res.data.map((unzippedFile:any)=>{
                        dispatch(addExtractedZip(unzippedFile))
                    })
                }

        })
    }
    useEffect( () => {
        if(reduxBlobCount===files.length){
            dispatch(updateWaitStatus(false));
        }
        setIsDisabled(reduxBlobCount!==files.length);
    }, [percentage, reduxBlobCount])
    const handleCloseToaster = () => {
        setOpenToaster(false);
    };
    useEffect(()=>{
        if(uploadStatusOkay === false){
            setOpenToaster(true)
        }
    },[uploadStatusOkay]);

    const deleteFileFunc = (fileName:string)=>{
        const payloadForDelete = {
            "filePath" : sessionStorage.getItem('packageId') + "/" + fileName
        } 
        commonApi.httpConnection(UPLOAD_SERVER.SERVICES.postRemoveFile, 'post', payloadForDelete )
        .then(async (res) => {
            console.log(res)
        })
    }
    const deleteFileAll = async ()=>{
        dispatch(clearFiles());
        dispatch(clearPercentages());
        dispatch(clearExtractedZip())
        await files.map((file:any)=>{
            deleteFileFunc(file?.name)
        })
        sessionStorage.removeItem('packageId');
        dispatch(updateBlobCount(0));
        navigate(`/view-cases-details/${incId[incId.length-1]}`)
    }

    return (
        <div id='main-content' className = {classes.progress}>
            <Snackbar
            anchorOrigin={{vertical: "top", horizontal:'center'}}
            className={classes.snackBar}
            open={openToaster}
            autoHideDuration={10000}
            onClose={handleCloseToaster}>
                <Alert
                    onClose={handleCloseToaster}
                    severity="error"
                    sx={{ fontSize: '1.25rem' }}>
                    {drupalData[0]?.networkError ? drupalData[0]?.networkError : defaultDrupalData[0]?.networkError}
                </Alert>
            </Snackbar>
            {files.map((file: any,index:any) => {
                return (
                        <div key={index+'.'} className = {classes.progressBar} role="alert" aria-label={file.name+" "+percentage+"% uploaded"}>
                            <img style={{ marginRight: '1rem'}} src={Icon_Upload_Successfull} alt="" />
                            <div style={{width: '100%', marginRight: '2.5rem'}}>
                                <div className={classes.fileDetails}>
                                    <p className = {classes.fileName}>{file.name}</p>
                                    <p className = {classes.fileSize}>{(file.size/1048576).toFixed(2)} MB</p>
                                </div>
                                <LinearProgress className = {classes.progressBarCompleted} variant="determinate" value={getPercentage(percentage[file.name]?.percentage)} />
                                <p style={{font: '20px AssistantRegular', marginBlock: 0}}>{Math.floor(getPercentage(percentage[file.name]?.percentage))}{drupalData[0].completedLabel}</p>
                            </div>
                            <img className={classes.closeBtn} tabIndex={0} src={Icon_deleteFile} onKeyDown={(event:React.KeyboardEvent<HTMLElement>)=>listener(event,file.name)} onClick = {() => {deleteFile(file.name)}} alt={file.name+" Delete file"} title="Delete file" />
                        </div>
                )
            })}
            <div className = {classes.buttons}>
                <button className = {classes.backCta} onClick = {() => {deleteFileAll()}}>{drupalData[0].backBtn}</button>
                <button disabled ={isDisabled} className = {classes.proceedBtn} onClick = {() => { navigate(`/view-cases-upload-preview`, { state: { 'files': files, 'pnx':pnx, 'about': about, 'incId':incId[incId.length-1] }});}}>{drupalData[0].proceedBtn}</button>
            </div>
        </div>
    )
}

export default UploadProgressBar

const useStyles = makeStyles({
    fileDetails: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        marginBottom: '0.5rem'
    },
    closeBtn: {
        cursor: 'pointer'
    },
    snackBar: {
        marginTop: '10rem',
        bottom: "43.75rem",
        border: "1px solid red",
        backgroundColor: "#EAFFCC",
        textAlign:"left",
        font: "normal normal normal 24px/35px Assistant",
        color:" #404040",
    },
    progress: {
       display: 'flex',
       flexDirection: 'column',
       '@media (max-width: 1000px)' : {
        borderTop: 'none'
      }
    },
    fileSize: {
        font: '1.125rem AssistantRegular',
        color: '#858585',
        marginBlock: 0,
        textAlign: 'right'
    },
    progressBar: {
        border: '1px solid #D0D0D0',
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem',
        marginBottom: '1rem'
    },
    progressInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem'
    },
    fileName: {
        font: '1.375rem AssistantSemiBold',
        color: '#404040',
        marginBlock: '0',
        overflowWrap: 'anywhere'
    },
    progressBarMui: {
        '& .css-eglki6-MuiLinearProgress-root': {
            backgroundColor: '#F2F2F2',
            borderRadius: '11px'
        },
        height: '0.75rem',
        borderRadius: '11px',
    },
    progressBarCompleted: {
        '& .css-5xe99f-MuiLinearProgress-bar1': {
            backgroundColor: '#271C6F !important'
        },
        '& .css-eglki6-MuiLinearProgress-root': {
            backgroundColor: '#F2F2F2 !important',
            borderRadius: '11px'
        },
        height: '0.75rem',
        borderRadius: '11px',
    },
    buttons: {
        alignSelf: 'flex-end',
        marginTop: '2.063rem',
        '@media (max-width: 1000px)' : {
            alignSelf: 'flex-start'
          }
    },
    backCta: {
        padding: '0.688rem 1.5rem',
        border: '1px solid #D22D64',
        color: '#D22D64',
        font: '1.25rem AssistantSemiBold',
        width: 'fit-content',
        borderRadius: '30px',
        marginRight: '1.5rem',
        backgroundColor: '#fff',
        cursor: 'pointer',
        '&:hover':{
            backgroundColor: '#FAE5EC'
        },
        gridArea: 'backCta',
        '@media (max-width: 480px)' : {
            width: '100%'
          }
    },
    proceedBtn: {
        backgroundColor: '#D22D64',
        color: '#fff',
        font: '1.25rem AssistantSemiBold',
        padding: '0.688rem 1.5rem',
        borderRadius: '1.5rem',
        border: 'none',
        marginTop: '2.063rem',
        cursor: 'pointer',
        '&:hover':{
            backgroundColor: '#B8134A'
        },
        '&:disabled': {
            backgroundColor: '#e0e0e0',
            color: '#7e7e7e'
        },
        '@media (max-width: 480px)' : {
            width: '100%'
          }
    }
})