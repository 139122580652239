import { createSlice } from "@reduxjs/toolkit";

interface ProgressType {
    featureFlagData: []
}

const initialState:ProgressType ={
    featureFlagData:[]
}

const FeatureFlagSlice = createSlice({
    name: 'featureFlagReducer',
    initialState,
    reducers: {
        featureFlag: (state:any,action:any)=>{
            console.log("action.payload >", action.payload)
          state.featureFlagData = action.payload;
        },
        
    }
});

export const {featureFlag} = FeatureFlagSlice.actions;
export default FeatureFlagSlice.reducer;