import axios from "axios";
import { DRUPALSERVER } from "./drupal-api-urls.js";

export default class DrupalService {
  requestOptions;
  constructor() {
    this.state = {
      data: []
    }
     
    this.requestOptions = {
      headers: {
        "Content-Security-Policy": "default-src 'none'",
        Authorization: `${'Bearer '}${localStorage.getItem('ACT')}`,
        //   "Content-Type": "application/json", 
        // "Access-Control-Allow-Origin": "*", 
        // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS', 
      }
    };
    this.baseUrl = DRUPALSERVER.BASE_URL
  }

  httpConnection = async (url, method, body) => {
    if (method === "get") {
     
        const response = await this.httpConnectionGet(url, this.requestOptions);
        const data = response.data ? response.data : "";
        if (data.httpStatusCode === 401) {
          return this.errorHandling(response);
        } else if (data !== "") {
          return data;
        } else {
          return this.errorHandling(response);
        }
    }
  };

  httpConnectionGet = async (url, body) => {
    this.requestOptions = {
      headers: {
        "Content-Security-Policy": "default-src 'none'",
        //"state-validation" : window.drupalState,
        Authorization: `${'Bearer '}${localStorage.getItem('ACT')}`,
        //   "Content-Type": "application/json", 
        // "Access-Control-Allow-Origin": "*", 
        // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS', 
      }
    };
    try {
      return await axios.get(`${this.baseUrl}${url}`, this.requestOptions).then((res) => {
        return res;
      }).catch((err)=>{
        return err;
      });
    } catch (error) {
      // //console.log("AXIOS ERROR", error.response);
      return error.response;
    }
  };

  errorHandling = response => {
    if (response.status === 401) {
      return true;
    }
    return response;
  };
}

