import React, { useEffect, useState, useMemo } from 'react';
import { useMsal } from "@azure/msal-react";
import {
    useParams,
    useLocation,
    useNavigate,
    matchPath
} from "react-router-dom";
import queryString from "query-string";
import LogoutWarning from './LogoutWarning';
import CommonService from './common/api/common-api-service';
import { SERVER } from './common/api/api-urls';

const whitelist = [
    '/a',
    '/b',

];

export const IdleTimer = ({forcedlogouttimeinminutes,popupshowcasetimeinminutes,differenceinminutes}) => {
    const router = useRouter();
    const { instance } = useMsal();
    let timeout: NodeJS.Timeout | null = null;
    let warningTimeout: NodeJS.Timeout | null = null;
    const navigate = useNavigate();
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const handleClose = () =>{
        setTimeout(()=>{ setShowWarning(false)},3000);
        const commonAPI = new CommonService();
    
        commonAPI.httpConnection(SERVER.SERVICES.ongoingActiveSession, 'get')
        .then(res=>{
            setShowWarning(false);
        }).catch((e)=> setShowWarning(false))
        
    }
    useEffect(() => {
        let preventReset = false;
        for (const path of whitelist) {
            if (path === router.pathname) {
                preventReset = true;
            }
        }
        if (preventReset) {
            return;
        }
        restartAutoReset();


        window.addEventListener('mousemove', onMouseMove);

        return () => {
            if(warningTimeout){
                clearTimeout(warningTimeout);
            }
            if (timeout) {
                clearTimeout(timeout);
                window.removeEventListener('mousemove', onMouseMove);
            }
        };
    }, [router.pathname]);

    const handlelogout = () => {
        
        const commonAPI = new CommonService();
        localStorage.setItem('tabsOpen','0');
        setTimeout(()=>{ setShowWarning(false)},3000);
        commonAPI.httpConnection(SERVER.SERVICES.logoutRequest, 'get')
        .then(res=>{
            const account = instance.getAllAccounts()[0];
            const valueLogout = instance.getAccountByHomeId(account.homeAccountId);
            sessionStorage.clear();
            localStorage.removeItem('ACT');
            localStorage.setItem('tabsOpen','0');
            localStorage.removeItem('tabsOpen');
            instance.logout({ account: valueLogout });
            navigate('/logout');
        }).catch((e)=>{
            const account = instance.getAllAccounts()[0];
            const valueLogout = instance.getAccountByHomeId(account.homeAccountId);
            sessionStorage.clear();
            localStorage.removeItem('ACT');
            localStorage.setItem('tabsOpen','0');
            localStorage.removeItem('tabsOpen');
            instance.logout({ account: valueLogout });
            navigate('/logout');
        })
          
        
    
    };
    const goBackToHome = () => {
        handlelogout();
    };
    const restartAutoReset = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
        if(warningTimeout){
            clearTimeout(warningTimeout);
        }
        warningTimeout = setTimeout(()=>{
            setShowWarning(true);
        },parseInt(popupshowcasetimeinminutes)*60000);

        timeout = setTimeout(() => {
            goBackToHome();
        }, parseInt(forcedlogouttimeinminutes)*60000); 
    };
    const onMouseMove = () => {
        restartAutoReset();
    };
    return <div>
        <LogoutWarning open={showWarning} handleLogout={handlelogout} handleClose = {handleClose}/>;
    </div>
};

// Hook
export function useRouter() {
    const params = useParams();
    const location = useLocation();
    const history = useNavigate();
    const match = matchPath;
    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
        return {
            // For convenience add push(), replace(), pathname at top level
            push: history,
            replace: history,
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
            query: {
                ...queryString.parse(location.search), // Convert string to object
                ...params,
            },
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            match,
            location,
            history,
        };
    }, [params, match, location, history]);
}