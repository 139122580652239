import { useEffect, useState } from 'react'
import ExclaimatoryIcon from '../assets/icons/Exclaimatory.svg';
import { makeStyles } from '@mui/styles';
import Button from '../common/component-lib/Button';
import { DRUPALSERVER } from '../common/drupal-api/drupal-api-urls';
import DrupalService from '../common/drupal-api/drupal-api-service';
import React from 'react';

const CompatabilityMsg = (props) => {
    const [drupalData, setdrupalData] = useState<any>(null);
    const classes = useStyles();
    const [isActive, setIsActive] = useState(false);
    const compatabilityMessages = {
        footermessage: "For best user experience, please use latest version of Chrome, Edge or Safari browser on your laptop or desktop only.",
        flashmessage: "For best user experience, please use latest version of Chrome, Edge or Safari browser on your laptop or desktop only.",
        dismissButton: "Dismiss"
    }
    useEffect(() => {
        const drupalApi = new DrupalService();
        drupalApi.httpConnection(DRUPALSERVER.SERVICES.getCompatabilityMessages, 'get')
            .then((res: any) => {
                res && (res[0] && res[0] !== '<' ? setdrupalData(res[0].footermessage) : setdrupalData(compatabilityMessages.footermessage));
            })
            .catch((_e: any) => {
                setdrupalData(compatabilityMessages.footermessage)
            });


    }, [])

    const handleClick = () => {
        setIsActive(true);
        sessionStorage.setItem('compatability','dismissed');
        props.handleCompDismiss();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    return (

        <>
            {drupalData &&
                <div className={isActive ? classes.isActive : classes.compatabilityContainer}>
                    <div className={classes.container1}>
                        <img src={ExclaimatoryIcon} alt='' className={classes.mticon} />
                        <div className={classes.contentContainer}>
                            {drupalData}
                        </div>
                    </div>
                    <div className={classes.buttonStyle}>
                        <Button
                            border='0.0625rem solid #D22D64'
                            textColor='#D22D64'
                            bgColor='#FDFCDD'
                            height='2.5rem'
                            onClick={handleClick}
                            radius='60px'
                            width='6rem'
                        >
                            <span className={classes.paddingLeftRight}>{drupalData?.dismissButton? drupalData?.dismissButton : compatabilityMessages.dismissButton}</span>
                        </Button>
                    </div>
                </div>
            }
        </>
    )
}

export default CompatabilityMsg

const useStyles = makeStyles({
    compatabilityContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContents: 'flex-start',
        backgroundColor: '#FDFCDD',
        borderBottom: '4px solid #F0E39A',
        width: '100%'
    },
    container1: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '1rem 0rem 0rem 1rem',
        marginLeft: '4rem'
    },
    contentContainer: {
        width: '72%',
        marginTop: '0.5%',
        '& h2': {
            fontFamily: 'AssistantSemiBold',
            color: '#d22d64',
            fontSize: '1.5rem',
            marginBlock: '0'
        },
        '& p': {
            fontFamily: 'AssistantRegular',
            color: '#404040',
            marginBlock: '0',
            marginTop: '0.5625rem',
            fontSize: '1.25rem'
        }
    },

    mticon: {
        marginRight: '1.25rem',
        marginBottom: '5.4%',
        paddingTop: '0.45rem'
    },
    buttonStyle: {
        marginRight: '6rem'
    },
    paddingLeftRight: {
        fontFamily: 'AssistantSemiBold',
        fontSize: '1.125rem',
        cursor: 'pointer'
    },
    isActive: {
        display: 'none'
    }
})