import * as actionTypes from "./actionTypes"

export function addOrgList(stateDigitalPortal: IDigitalPortal) {
  const action: DigitalPortalAction = {
    type: actionTypes.ADD_ORG_LIST,
    stateDigitalPortal,
  }

  return simulateHttpRequest(action)
}

export function serverMessage(ServerMesagePortal: ServerMessage) {
  const action: ServerMesageAction = {
    type: actionTypes.SERVER_MESSAGE,
    ServerMesagePortal,
  }

  return simulateHttpRequest(action)
}

export function updateOrgList(stateDigitalPortal: IDigitalPortal) {
  const action: DigitalPortalAction = {
    type: actionTypes.UPDATE_ORG_LIST,
    stateDigitalPortal,
  }
  return simulateHttpRequest(action)
}

export function simulateHttpRequest(action: DigitalPortalAction) {
  return (dispatch: DispatchType) => {
    setTimeout(() => {
      dispatch(action)
    }, 500)
  }
}
export function simulateUploadRequest(action: UploadFileAction) {
  return (dispatch: DispatchTypeUpload) => {
    setTimeout(() => {
      dispatch(action)
    }, 501)
  }
}
export function simulateServerRequest(action: ServerMesageAction) {
  return (dispatch: DispatchTypeServer) => {
    setTimeout(() => {
      dispatch(action)
    }, 502)
  }
}
export function uploadFilesRequest(uploadFileReduxState: FileType){
  const action: UploadFileAction = {
    type: actionTypes.UPLOAD_FILES,
    uploadFileReduxState
  }
  return simulateUploadRequest(action);
}