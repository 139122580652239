import axios from "axios";
import { SERVER } from "./api-urls.js";
import * as CryptoJS from 'crypto-js';
import { UPLOAD_SERVER } from "../upload-api/api-urls.js";

export default class CommonService {
  token;
  requestOptions;
  api_call;
  constructor(api_call) {
    //let val = sessionStorage.getItem('ACT');//JSON.parse(sessionStorage.getItem('03fc40e2-66cd-42fb-9b05-2cd648d84a46.a8125f1d-53b8-4a22-8b49-a4928e33a243-login.windows.net-accesstoken-f5d1ead0-fec9-46a9-9dca-0c1db1f3ace4-a8125f1d-53b8-4a22-8b49-a4928e33a243-api://637fca8b-7cfd-4497-85d9-cb28df605067/respondent/apiaccess--'));
    //if(val)
    this.api_call = api_call;
    let date = (new Date().getTime() / 1000).toFixed(0).toString();

    this.requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "Correlation-Id":`${sessionStorage.getItem('correlationId')}`,
       // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        "Content-Security-Policy": "default-src 'none'",
        Authorization: `${'Bearer '}${localStorage.getItem('ACT')}`

      }
    };
    this.baseUrl = this.api_call?UPLOAD_SERVER.BASE_URL:SERVER.BASE_URL

  }

  httpConnection = async (url, method, body) => {
    switch (method) {
      case "get":
        const response = await this.httpConnectionGet(url, this.requestOptions);
        const data = response.data ? response.data : "";
        if (data.httpStatusCode === 401|| data.httpStatusCode === 400) {
          return this.errorHandling(response);
        } else if (response.status === 200 || data !== "") {
          return data;
        } else {
          return this.errorHandling(response);
        }

      case "post":
        const responsePost = await this.httpConnectionPost(url, body);
        const dataPost = responsePost.data ? responsePost.data : "";
        if (responsePost.status === 500 || responsePost.status === 401 || responsePost.status === 400 || responsePost.status === 403 ) {           
          return this.errorHandling(dataPost);
        }
        else if (responsePost.status === 200 || dataPost !== "") {
          return dataPost;
        } else {      
          return this.errorHandling(responsePost);
        }

      case "put":
        const responsePut = await this.httpConnectionPut(url, body);
        const dataPut = responsePut.data ? responsePut.data : "";
        if (dataPut !== "") {
          return dataPut;
        } else {
          return this.errorHandling(responsePut);
        }
      case "delete":
        const responseDelete = await this.httpConnectionDelete(url, body);
        const dataDelete = responseDelete.data ? responseDelete.data : "";
        if (dataDelete !== "") {
          return dataDelete;
        } else {
          return this.errorHandling(responseDelete);
        }
      default:
        break;
    }
  };

  httpConnectionGet = async (url, body) => {
    try {
      let date = (new Date().getTime() / 1000).toFixed(0).toString();

      this.requestOptions = {
        headers: {
          "Content-Type": "application/json",
          "Correlation-Id":`${sessionStorage.getItem('correlationId')}`,
          "Content-Security-Policy": "default-src 'none'",
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          "Access-Control-Allow-Headers": "*",
          Authorization: `${'Bearer '}${localStorage.getItem('ACT')}`
        }
      };
      return await axios.get(`${this.baseUrl}${url}`, this.requestOptions);
    } catch (error) {
      // //console.log("AXIOS ERROR", error.response);
      return error.response;
    }
  };

  httpConnectionPost = async (url, body) => {
    try {
      let date = (new Date().getTime()/1000).toFixed(0).toString();

      this.requestOptions = {
        headers: {
          "Content-Type": "application/json",
          "Correlation-Id":`${sessionStorage.getItem('correlationId')}`,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          "Access-Control-Allow-Headers": "*",
         "Content-Security-Policy": "default-src 'none'",
          Authorization:  `${'Bearer '}${localStorage.getItem('ACT')}`,
        }
      };
      return await axios.post(
        `${this.baseUrl}${url}`,
        body,
        this.requestOptions
      );
    } catch (error) {
      ////console.log("AXIOS ERROR", error.response);
      return error.response;
    }
  };
  httpConnectionPut = async (url, body) => {
    try {
      let date = (new Date().getTime() / 1000).toFixed(0).toString();

      this.requestOptions = {
        headers: {
          "Content-Type": "application/json",
          "Correlation-Id":`${sessionStorage.getItem('correlationId')}`,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          "Content-Security-Policy": "default-src 'none'",
          "Access-Control-Allow-Headers": "*",
          Authorization: `${'Bearer '}${localStorage.getItem('ACT')}`,

        }
      };
      return await axios.put(
        `${this.baseUrl}${url}`,
        body,
        this.requestOptions
      );
    } catch (error) {
      ////console.log("AXIOS ERROR", error.response);
      return error.response;
    }
  };
  httpConnectionDelete = async (url, body) => {
    try {
      let date = (new Date().getTime() / 1000).toFixed(0).toString();

      this.requestOptions = {
        headers: {
          "Content-Type": "application/json",
          "Correlation-Id":`${sessionStorage.getItem('correlationId')}`,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          "Access-Control-Allow-Headers": "*",
          "Content-Security-Policy": "default-src 'none'",
          Authorization: `${'Bearer '}${localStorage.getItem('ACT')}`,

        }
      };
      return await axios.delete(
        `${this.baseUrl}${url}`,
        this.requestOptions
      );
    } catch (error) {
      ////console.log("AXIOS ERROR", error.response);
      return error.response;
    }
  };

 
  errorHandling = response => {
    if( response){
      return Promise.reject(response)
    }
    //else {
      // if (!response.data){
      //   response.message="Something went wrong or Kindly Check Internet Connection!!"
      // }
      // else{
      //   response.message = response.data;
      //   return Promise.reject(response)
      // }
      
   // }
    return Promise.reject(response)

  };

}
