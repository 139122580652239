/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Switch from '@mui/material/Switch';
import {
    Box,
    Grid,
    Button,
    Card,
    CardContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';


interface PopupCookiesProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    drupalDataCookies: any;
    setBannerState: any;
}

const decideVal = (cookieType:any) => {
    if(Cookies.get(cookieType) && Cookies.get(cookieType) === 'true') {
        return true;
    }
    else {
        return false;
    }
}
export const PopupCookies: React.FC<PopupCookiesProps> = ({ show, setShow, drupalDataCookies,setBannerState }) => {
    let valA: boolean = decideVal('analytical-cookies')
    let valF: boolean = decideVal('functional-cookies')
    const [functionalCookie, setFunctionalCookie] = useState(valF);
    const [analyticalCookie, setAnalyticalCookie] = useState(valA);
    const [value, setValue] = useState<number>(0);
    useEffect(()=>{
        if(Cookies.get('analytical-cookies') && Cookies.get('analytical-cookies') === 'true'){
            valA = true
        }
        else{
            valA = false
        }
        if(Cookies.get('functional-cookies') && Cookies.get('functional-cookies') === 'true'){
            valF = true
        }else{
            valF = false
        }

        setAnalyticalCookie(valA);
        setFunctionalCookie(valF);
    },[Cookies.get('accepted-cookie'),Cookies.get('functional-cookies'),Cookies.get('analytical-cookies')])
    const closeDialogAcceptAll = (e: any) => {
        let inThirtyDays = new Date(new Date().getTime() + 1 * 60 * 1000 );
        inThirtyDays.setDate(inThirtyDays.getDate() + 30);
        Cookies.set('necessary-cookies', `${''}${'true'}`,{expires:inThirtyDays});
        Cookies.set('functional-cookies', `${''}${'true'}`,{expires:inThirtyDays});
        Cookies.set('analytical-cookies', `${''}${'true'}`,{expires:inThirtyDays});
        
        Cookies.set('accepted-cookie','true',{expires:inThirtyDays})
        setBannerState(false);
        e.preventDefault();
        setShow(false);
        window.location.reload()
    };
    
    const closeDialog = (e: any) => {
        let inThirtyDays = new Date(new Date().getTime() + 1 * 60 * 1000 );
        inThirtyDays.setDate(inThirtyDays.getDate() + 30);
        Cookies.set('necessary-cookies', `${''}${'true'}`,{expires:inThirtyDays});
        if(functionalCookie === true){
            Cookies.set('functional-cookies', `${''}${functionalCookie}`,{expires:inThirtyDays});
        }else{
            Cookies.remove('functional-cookies');
        }
        if(analyticalCookie === true) {
            Cookies.set('analytical-cookies', `${''}${analyticalCookie}`,{expires:inThirtyDays})
        }else{
            Cookies.remove('analytical-cookies')
        }
        
        Cookies.set('accepted-cookie','true',{expires:inThirtyDays})
        setBannerState(false);
        e.preventDefault();
        setShow(false);
        setValue(0)
    };
    function onPreferenceToggle(e: any) {
        let inThirtyDays = new Date(new Date().getTime() + 1 * 60 * 1000 );
        inThirtyDays.setDate(inThirtyDays.getDate() + 30);
        return Cookies.set('necessary-cookies', e?.target?.checked,{expires:inThirtyDays});
    }
    const togglePreferenceCookie = (e: any) => {
        onPreferenceToggle(e?.target?.checked);
    };

    function onFunctionalToggle(e: any) {
        let inThirtyDays = new Date(new Date().getTime() + 1 * 60 * 1000 );
        inThirtyDays.setDate(inThirtyDays.getDate() + 30);
        Cookies.set('functional-cookies', e?.target?.checked,{expires:inThirtyDays});
    }
    function onAnalyticalToggle(e: any) {
        let inThirtyDays = new Date(new Date().getTime() + 1 * 60 * 1000 );
        inThirtyDays.setDate(inThirtyDays.getDate() + 30);
        Cookies.set('analytical-cookies', e?.target?.checked,{expires:inThirtyDays});
    }
    const toggleFunctionalCookie = (e: any) => {
        let inThirtyDays = new Date(new Date().getTime() + 1 * 60 * 1000 );
        inThirtyDays.setDate(inThirtyDays.getDate() + 30);
        setFunctionalCookie(e?.target?.checked);
        Cookies.set('functional-cookies', e?.target?.checked,{expires:inThirtyDays});
        onFunctionalToggle(e?.target?.checked);
    };

    const toggleAnalyticalCookies = (e: any) => {
        let inThirtyDays = new Date(new Date().getTime() + 1 * 60 * 1000 );
        inThirtyDays.setDate(inThirtyDays.getDate() + 30);
        setAnalyticalCookie(e?.target?.checked);
        Cookies.set('analytical-cookies', e?.target?.checked,{expires:inThirtyDays});
        onAnalyticalToggle(e?.target?.checked);
        setValue(1)
    };

    const checkAnalyticalCookie =()=>{
            window.location.reload();
    }
    const classes = useStyles();

   
    return show &&
          <Dialog
            onClose={closeDialog}
            role='dialog'
            aria-labelledby='customized-dialog-title'
            open={show}
            maxWidth={false}
            PaperProps={{ sx: { width: '51.5rem',padding:'2rem',borderRadius:'0px',boxShadow:'none' } }}
          > 
            <div >
                <Box  >
                    <div className={classes.cookiesPopupHeader}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                height: 'auto',
                                border: 'none',
                                boxShadow: 'none'
                            }}
                        >
                            <h2 id='modal-modal-title' style={{ color: '#D22D64', margin: '0px', fontSize: '1.75rem', fontFamily: 'AssistantSemibold' }}>{drupalDataCookies[0].popupHeading}</h2>
                            <CloseIcon
                                id="close-icon-cookies"
                                tabIndex={0}
                                aria-label={'close dialog'}
                                role={'button'}
                                aria-hidden={false}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        closeDialog(e);
                                    }
                                }}
                                onClick={() => setShow(false)}
                                style={{ marginLeft: '60%' }}
                            />
                        </Box>
                        <hr className={classes.divider} />
                    </div>

                    <Grid container spacing={1}>
                        <Card className={classes.gridCardHeader}>
                            <CardContent className={classes.totalAdminClass}>
                                <b>{drupalDataCookies[0].popupSubHeading} </b>
                            </CardContent>
                            <CardContent className={classes.totalAdminClass}>
                                {drupalDataCookies[0].popupSubHeadingText}
                            </CardContent>
                        </Card>

                        <Grid container spacing={1}>
                            <Card className={classes.gridCard}>
                                <CardContent className={classes.totalAdminClass}>
                                    <b > {drupalDataCookies[0].necessaryCookies}</b>
                                </CardContent>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Switch
                                        sx = {{
                                            marginLeft: '0.625rem',
                                            '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                                                backgroundColor: '#271C6F',
                                                height: '100%'
                                            },
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                color: '#271C6F',
                                                height: '100%'
                                            },
                                            '& .MuiSwitch-switchBase' :{
                                                color: '#767676'
                                            }
                                        }}
                                        id="necessary-cookie"                                     
                                        className={classes.switchCookie}
                                        disabled
                                        defaultChecked
                                        checked={true}
                                        onChange={togglePreferenceCookie}
                                        aria-labelledby="cookiesType"
                                        inputProps={{ 'aria-label': 'Necessary Cookies toggle button' }}
                                        
                                    />
                                    <CardContent className={classes.cookieClass} id="cookiesType">
                                     {drupalDataCookies[0].necessaryCookiesText}
                                    </CardContent>
                                </Box>
                            </Card>
                        </Grid>

                        <Grid container spacing={1}>
                            <Card className={classes.gridCard}>
                                <CardContent className={classes.totalAdminClass}>
                                    <b >{drupalDataCookies[0].functionalCookies}</b>
                                </CardContent>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Switch
                                        id={"functionalCookies" + functionalCookie}
                                        disableRipple
                                        className={classes.switchCookie}
                                        checked={functionalCookie}
                                        onChange={toggleFunctionalCookie}
                                        aria-labelledby="cookiesType" 
                                        inputProps={{ 'aria-label': 'Functional Cookies toggle button' }}
                                        sx={{
                                            '&:focus-within': {
                                              border: '2px solid black',
                                              borderRadius: '4px'                                                                  
                                            },marginLeft: '0.625rem',
                                            '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                                                backgroundColor: '#271C6F',
                                                height: '100%'
                                            },
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                color: '#271C6F',
                                            },
                                            '& .MuiSwitch-switchBase' :{
                                                color: '#767676'
                                            }
                                          }}
                                    />
                                    <CardContent className={classes.cookieClass} id="cookiesType">
                                        {drupalDataCookies[0].functionalCookiesText}
                                    </CardContent>
                                </Box>
                            </Card>
                        </Grid>

                        <Grid container spacing={1}>
                            <Card className={classes.gridCard}>
                                <CardContent className={classes.totalAdminClass}>
                                    <b >{drupalDataCookies[0].analyticalCookies}</b>
                                </CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginLeft: '5px'
                                    }}
                                >
                                    <Switch
                                        id= {'analytical-cookie'+ analyticalCookie}
                                        disableRipple
                                        className={classes.switchCookie}
                                        checked={analyticalCookie}
                                        onChange={toggleAnalyticalCookies}
                                        aria-labelledby="cookiesType"
                                        inputProps={{ 'aria-label': 'Analytical Cookies toggle button' }}
                                        sx={{
                                            '&:focus-within': {
                                              border: '2px solid black',
                                              borderRadius: '4px',                        
                                            },marginLeft: '0.625rem',
                                            '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                                                backgroundColor: '#271C6F',
                                                height: '100%'
                                            },
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                color: '#271C6F',
                                            },
                                            '& .MuiSwitch-switchBase' :{
                                                color: '#767676'
                                            }
                                          }}
                                    />
                                    <CardContent className={classes.cookieClass} id="cookiesType">
                                        {drupalDataCookies[0].analyticalCookiesText}
                                    </CardContent>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>

                    <div>
                        <Card className={classes.buttonClass}>
                            <Button
                                id='accept-all-btn'
                                disableRipple
                                className='column-reset-btn'
                                onClick={closeDialogAcceptAll}
                            >
                                {drupalDataCookies[0].acceptButton}
                            </Button>
                            <Button
                                id='save-proceed-btn'
                                disableRipple
                                onClick={(e)=>{
                                    closeDialog(e);
                                    checkAnalyticalCookie()
                                }}
                                className='column-apply-btn'
                            >
                                {' '}
                                {drupalDataCookies[0].saveButton}
                            </Button>
                        </Card>
                    </div>
                </Box>
            </div>
         </Dialog> 
};

const useStyles = makeStyles({
    totalAdminClass: {
        fontFamily: 'AssistantRegular',
        fontSize: '1rem',
        color: '#404040',
        paddingTop: 0,
        border: 'none',
        boxShadow: 'none'
    },
    cookieClass: {
        fontFamily: 'AssistantRegular',
        fontSize: '1rem',
        color: '#404040',
        paddingTop: 0,
        marginLeft: '2.5rem'
    },
    switchCookie: {
        marginLeft: '0.625rem',
        '& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#271C6F',
            height: '100%'
        },
        '& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked': {
            color: '#271C6F',
        }
    },
    buttonClass: {
        float: 'right',
        margin: '1rem 0',
        background: 'transparent',
        boxShadow: 'none'
    },
    resetButton: {
        fontFamily: 'AssistantSemiBold',
        fontSize: '1.1rem',
        background: 'transparent',
        border: '0.063rem solid #D22D64',
        borderRadius: '1.563rem',
        opacity: '1',
        color: '#D22D64',
        textTransform: 'none',
        marginRight: '2rem',
        marginLeft: '2rem'
    },
    proceedButton: {
        fontFamily: 'AssistantSemiBold',
        fontSize: '1.1rem',
        background: '#D22D64 1% 1% no-repeat padding-box!important',
        border: '0.063rem solid #D22D64',
        borderRadius: '1.563rem',
        // opacity: '1',
        color: '#fff',
        textTransform: 'none'
    },
    cookiesSet: {
        marginRight: '0'
    },
    gridCard: {
        marginLeft: '0.438rem',
        border: 'none',
        boxShadow: 'none'
    },
    gridCardHeader: {
        marginTop: '0.938rem',
        marginBottom: '0',
        border: 'none',
        boxShadow: 'none'
    },
    cookiesPopupHeader: {
        flexDirection: 'row'
    },
    divider: {
        opacity: '40%'
    }
});
