import * as actionTypes from "./actionTypes"

const initialState: DigitalPortalState = {
    stateDigitalPortal: {
        act: "",
        idt: "",
        orglist: [
            {
              "accountid": ""//e0a75409-4fbe-4b7e-837f-b7f7a9169b1c
            },
            {
              "accountid": ""//a26921d3-6265-4ec0-a360-dc293035b561
            },
            {
              "accountid": ""//6ce71f84-4f68-47f3-a6ff-5c5ae2b5107f
            }
          ]
    },
}

const reducer = (
    state: DigitalPortalState = initialState,
    action: DigitalPortalAction
  ): DigitalPortalState => {
    switch (action.type) {
      case actionTypes.ADD_ORG_LIST:
        const newArticle: IDigitalPortal = {
          act: action.stateDigitalPortal.act, // not really unique
          idt: action.stateDigitalPortal.idt,
          orglist: action.stateDigitalPortal.orglist,
        }
        return {
          ...state,
          stateDigitalPortal: newArticle,
        }
      
    }
    return state
  }
  
  export default reducer