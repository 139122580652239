import React, { useState, useEffect } from 'react';
import { Grid, Card } from '@mui/material';
import { PopupCookies } from './PopupCookies';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import { makeStyles } from '@mui/styles';
import { DRUPALSERVER } from '../common/drupal-api/drupal-api-urls';
import DrupalService from '../common/drupal-api/drupal-api-service';
import { staticCookiesData, staticDrupalData } from '../services/utils';

export type GlobalContent = {
    isDialogOpen: boolean;
    setDialogState: React.Dispatch<React.SetStateAction<boolean>>;
    isBannerOpen: boolean;
    setBannerState: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CookieContext = React.createContext<GlobalContent>({
    isDialogOpen: false,
    setDialogState: () => { },
    isBannerOpen: false,
    setBannerState: () => { }
});

const useStyles = makeStyles({
    cookieConsentModel: {
        height: '19.5rem',
        width: '100% !important',
        paddingBottom: '2.5rem',
        "@media (max-width:1000px)": {
            height: '25rem',
            paddingBottom: '2.5rem',
            width: '100% !important',
          },
    },
    contentCookies: {
        backgroundColor: '#eeecec',
        width: '100%',
        position: 'absolute',
        height: 'auto',
        '& > .MuiGrid-container': {
            width: '100%',
            margin: '0',
            display: 'block',
            /* left: 30%; */
            /* top: 20%; */
            textAlign: 'center'
        }
    },

    cookieCardBox: {
        height: 'auto',
        width: '100%',
        margin: '0',
        display: 'block',
        textAlign: 'center'
    },
    buttonClass: {
        background: 'transparent',
        boxShadow: 'none',
        marginBottom: '2.5rem'
    },
    resetButton: {
        fontFamily: 'AssistantSemiBold',
        fontSize: '1.15rem',
        color: '#2B2A6D',
        textTransform: 'none',
        marginRight: '3rem',
        '&:focus': {
            border: '2px solid black'
        },
        '&:hover': {
            textDecorationLine: 'underline',
            textDecorationColor: '#2B2A6D'
        }
    },
    proceedButton: {
        marginRight: '3rem',
        marginLeft: '2rem',
        fontFamily: 'AssistantSemiBold',
        fontSize: '1.1rem',
        background: '#D22D64 1% 1% no-repeat padding-box!important',
        border: '0.06rem solid #D22D64',
        borderRadius: '1.563rem',
        // opacity: '1',
        color: '#fff',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        textTransform: 'none',
        '&:focus': {
            border: '2px solid black'
        }
    },
    cookiesCard: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'block',
        margin: '0 12.5%'
    },
    cookiesHeader: {
        color: '#404040',
        fontSize: '28px',
        fontFamily: 'AssistantSemiBold',
        marginBlockStart: '2.5rem',
        marginBlockEnd: '1rem'
    },
    cookiesubHeader: {
        justifyContent: 'center',
        alignItems: 'inherit',
        color: '#404040',
        fontSize: '24px',
        fontFamily: 'AssistantSemiBold',
        marginBottom: '0.875rem !important',
        marginBlockStart: '0',
        marginBlockEnd: '1rem'
    },
    cookiesCard2: {
        justifyContent: 'center',
        alignItems: 'center',
        color: '#404040',
        fontSize: '20px',
        fontFamily: 'AssistantRegular',
        marginBlockStart: '0',
        marginBlockEnd: '1.5rem'
    },
    cookiesPolicy: {
        color: '#271C6F',
        textDecoration: 'none',
        fontSize: '20px',
        fontFamily: 'AssistantSemiBold',
        '&:hover': {
            textDecoration: 'underline'
        },
        '&:focus': {
            textDecoration: 'underline'
        }
    }
});
const cookiesActionTrue = (cookieType:any) => {
    if (!Cookies.get(cookieType)) {
        Cookies.set(cookieType, 'true', { expires: 30 });
    }
}
const cookiesActionFalse = (cookieType:any) => {
    if (!Cookies.get(cookieType)) {
        Cookies.set(cookieType, 'false');
    }
}
function CookiesBannar() {
    const [isCookieShow, setIsCookieShow] = useState(false);
    const [bannerState, setBannerState] = useState(true);
    const [drupalData, setDrupalData] = useState(staticCookiesData);
    const drupalApi = new DrupalService();

    const classes = useStyles();
    if (!Cookies.get('accepted-cookie')) {
        cookiesActionTrue('necessary-cookies');
    }
    useEffect(() => {
        drupalForCookies();
        Cookies.get('accepted-cookie') ? setBannerState(false) : setBannerState(true);
        // eslint-disable-next-line
    }, []);

    const rejectCookies = () => {
        cookiesActionFalse('necessary-cookies');
        Cookies.remove('functional-cookies');
        Cookies.remove('analytical-cookies');
        setBannerState(false);
    }
    const acceptCookies = () => {
        let inThirtyDays = new Date(new Date().getTime() + 1 * 60 * 1000);
        inThirtyDays.setDate(inThirtyDays.getDate() + 30);
        cookiesActionTrue('necessary-cookies');
        cookiesActionTrue('functional-cookies');
        cookiesActionTrue('analytical-cookies');

        Cookies.set('accepted-cookie', 'true', { expires: inThirtyDays })
        setBannerState(false);
        window.location.reload()
    };

    const manageConsent = () => {
        setIsCookieShow(true);
        let inThirtyDays = new Date(new Date().getTime() + 1 * 60 * 1000);
        inThirtyDays.setDate(inThirtyDays.getDate() + 30);
        if (isCookieShow === true) Cookies.set('necessary-cookies', 'true', { expires: inThirtyDays });
    };

    const drupalForCookies = () => {
        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getCookies, 'get')
            .then((res) => {
                if (res.length > 0) {
                    setDrupalData(res);
                  } else {
                    setDrupalData(staticCookiesData);
                  }
            })
            .catch(() => {
                setDrupalData(staticCookiesData);
            });
    };

    return  (
        bannerState && (
        <div className={classes.cookieConsentModel}>
            <div className={classes.contentCookies}>
                <Grid container spacing={8} style={cookieCardBox}>
                    <div className={classes.cookiesCard}>
                        <h2 className={classes.cookiesHeader}>{drupalData[0].cookiesHeader}</h2>
                        <p className={classes.cookiesubHeader} dangerouslySetInnerHTML={{ __html: drupalData[0]?.subHeader }}></p>
                    </div>
                    <Card className={classes.buttonClass}>
                        <Button
                            id="accept-additional-cookie"
                            disableRipple
                            onClick={acceptCookies}
                            className={classes.proceedButton}
                        >
                            {' '}
                            {drupalData[0].proceedButton}
                        </Button>
                        <Button
                            id="reject-additional-cookie"
                            disableRipple
                            onClick={rejectCookies}
                            className={classes.proceedButton}
                        >
                            {' '}
                            Reject additional cookies
                        </Button>
                        <Button
                        id="view-manage-cookie"
                            disableRipple
                            className={classes.resetButton}
                            onClick={manageConsent}
                        >
                            View & manage cookies
                        </Button>

                    </Card>
                </Grid>
            </div>
            <PopupCookies
                show={isCookieShow}
                setShow={setIsCookieShow}
                drupalDataCookies={drupalData}
                setBannerState={setBannerState}
            />
        </div>
        )
    ) 
}
const cookieCardBox = {
    height: 'auto',
    width: '100%'
};

export default CookiesBannar;
