import React, { useEffect } from "react";
import CommonService from "../common/api/common-api-service";
import { SERVER } from "../common/api/api-urls";
import { errorComponentToast } from "../common/ErrorComponent/ErrorComponent";
export default function ZendeskSSO() {
    const jwttoken = () => {
        const commonApi = new CommonService();
        commonApi
        .httpConnection(SERVER.SERVICES.zendeskForm, 'get' )
        .then((res) => {
          window.open(res?.url, '_self')
        })
        .catch((e) => {
          errorComponentToast(e.data, 'An error occured')
        });
      }
    useEffect(()=>{
        jwttoken();
    },[]);
    return (<></>);
}
