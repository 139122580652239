import { Grid, RadioGroup, Radio, Tooltip } from '@mui/material';
import React, {useEffect, useState} from 'react';
import ViewIcon from '../../assets/icons/View icon.svg';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Dispatch} from "redux";
import Info from '../../assets/icons/Info_type.svg';
import FileUploadStepper from './FileUploadStepper';
import {makeStyles} from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import CancelPopup from '../../common/component-lib/CancelPopup';
import { DRUPALSERVER } from '../../common/drupal-api/drupal-api-urls';
import DrupalService from '../../common/drupal-api/drupal-api-service';
import CommonService from '../../common/api/common-api-service';
import { UPLOAD_SERVER } from '../../common/upload-api/api-urls';
import {removeFile, clearFiles} from "../../stores/uploadFiles/UploadSlice";
import LargeIconPdf from '../../assets/icons/Large_Icon_Pdf.svg';
import LargeIconAudio from '../../assets/icons/Icon_Audio.svg';
import LargeIconDoc from '../../assets/icons/Icon_Document.svg';
import LargeIconImg from '../../assets/icons/Icon_Image_large.svg';
import LargeIconVideo from '../../assets/icons/Icon_Video.svg';
import LargeIconZip from '../../assets/icons/Icon_Zip.svg';
import { updatePercentage } from '../../stores/uploadFiles/UploadProgressSlice';
import Loader from '../.././common/component-lib/Loader';
import {clearExtractedZip, removeExtractedZip} from "../../stores/uploadFiles/UploadZipSlice";
import {updateBlobCount} from '../../stores/uploadFiles/UploadStatusSlice'

const defaultDrupalData = [
    {
        "addDetails":"Add document details",
        "addMoreBtn":"Add more files",
        "typeDocument":"Type of document:",
        "businessResponse":"Business response",
        "otherType":"Other document type",
        "comments":"Comments",
        "mainPlaceholder":"Add comments common for all the documents you are about to upload here.",
        "preview":"Preview",
        "removeBtn":"Remove",
        "reason":"Reason for upload",
        "selectLabel":"Select complainant issue type(s)",
        "subPlaceholder":"Add your comments to specify why you would like to update your case",
        "cancelBtn":"Proceed to scan and upload",
        "proceedBtn":"Some descriptive information about \\\u0026quot;Business response\\\u0026quot; and \\\u0026quot;other document type\\\u0026quot; will be displayed here.",
        "tooltipInfo":"Cancel",
        "options":"Select, Jurisdiction query, Incorrect business, Incorrect product, Other",
        'networkError': 'There was network error uploading the files please try again later.'
    }
];
const maxNoOfFiles= 50;

const drupalApi = new DrupalService();
const commonApi = new CommonService('upload');
 const ViewCaseUploadDocPreview =() =>{
    const { state } = useLocation();
    const dispatch:Dispatch<any> = useDispatch();
    const stateValue: any = state;
    const [files, setFiles] = useState(stateValue.files);
    const classes = useStyles();
    const [reasonTextCharacters, setReasonTextCharacters] = useState<any>([]);
    const [packageComment, setPackageComment] =useState<string>('');
    const [disableCta, setDisableCta] = useState(true);
    const [otherCheck, setOtherCheck] = useState(false);
    const [type, setType] = useState<string>('');
    const [drupalData, setDrupalData] = useState<any>(defaultDrupalData);
    const [value, setValue] = useState(["Select"])
    const [config, setConfig] = useState<any>([]);
    const [zipFilesLength] = useState<number>(0);
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const [loading, setLoading] = useState<boolean>(false);

    let packageId = sessionStorage.getItem('packageId')

    const uploadError=()=>{
        dispatch(clearFiles());
        setLoading(false)
        navigate(`/view-case-document-upload-success`, {
            state: {
                about: stateValue.about,
                incId: stateValue.incId,
                packageId: packageId,
                packageComment: packageComment,
                files: docID,
                businessResponse: type,
                pnx: stateValue.pnx,
                flag: true
            }
        })
        sessionStorage.removeItem('packageId')
        dispatch(clearExtractedZip())
    }
    useEffect(() => {
        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getUploadPreviewPage, 'get')
            .then((res) => {
                if (res.length > 0) {
                    setDrupalData(res);
                } else {
                    setDrupalData(defaultDrupalData);
                }
            })
            .catch((_e: any) => {
                setDrupalData(defaultDrupalData);
            });

            commonApi.httpConnection(UPLOAD_SERVER.SERVICES.getSharedConfiguration,'get')
            .then((res)=>{
              setConfig(res?.data[1]?.documentCodes)
            })
            .catch((e)=>{
                console.warn(e);
            })
    }, []);

    const deleteItem = (index:any, fileName:string) => {
        dispatch(removeFile(fileName));
        dispatch(updatePercentage({name:fileName, percentage:0, documentId: '', uploadDone: false}));
        setFiles((_items:any) => files.filter((_:any, i:number) => i !== index));
        dispatch(updateBlobCount(-1));
        const payloadForDelete ={
            "filePath" : packageId + "/" + fileName
        } 
        commonApi.httpConnection(UPLOAD_SERVER.SERVICES.postRemoveFile, 'post', payloadForDelete)
        .then(async (res) => {
            console.log(res)
        })
    };
    const deleteExtractedFile = (fileName:string) => {
        dispatch(removeExtractedZip(fileName))
    }
    let docID: { documentId: string; fileName: string; }[] = []
     const reduxPercentage = useSelector((store:any)=>store.uploadProgress.percentages)
     const extractedZipFiles = useSelector((store:any)=> store.uploadZipSlice.extractedZipFiles)
    const encodeOutput = (output) =>{
        const div = document.createElement('div');
        div.textContent = output;
        return div.innerHTML;
    }
     const handleProcced = async () => {
        files?.forEach(async (file: any, index: number) => {
            docID.push(
                {
                    documentId: reduxPercentage[file.name].documentId,
                    fileName: files[index]?.name
                }
            )
        })
        extractedZipFiles?.forEach(async (unZipFile: any) => {
            docID.push(
                {
                    documentId: unZipFile?.documentId,
                    fileName: unZipFile?.fileName
                }
            )
        })
         setLoading(true)
         const getItems =()=>{
             const items:any = [];
             files?.map((file:any, index:number)=>{
                 items.push({
                     documentId: reduxPercentage[file.name].documentId,
                     metadata: [
                         {
                         "key": 'fileType',
                         "value": fileType(file?.name)
                        },
                        {
                            "key": 'originatorComments',
                            "value": reasonTextCharacters[index] ? reasonTextCharacters[index] :""
                        },
                        {
                            "key": 'fileName',
                            "value": file?.name
                        },
                        {
                            "key": 'documentCode',
                            "value": value[index]
                        }
                    ]
                 })
             })
             extractedZipFiles?.map((unZipFile:any, index:number)=>{
                items.push({
                    documentId: unZipFile?.documentId,
                    metadata: [
                        {
                        "key": 'fileType',
                        "value": fileType(unZipFile?.fileName)
                       },
                       {
                           "key": 'originatorComments',
                           "value": reasonTextCharacters[files.length-zipFilesLength+ index] ? encodeOutput(reasonTextCharacters[files.length-zipFilesLength+index]) :""
                       },
                       {
                           "key": 'fileName',
                           "value": unZipFile?.fileName
                       },
                       {
                           "key": 'documentCode',
                           "value": value?.[files.length-zipFilesLength+index]
                       }
                   ]
                })
            })
             return items
         }
         const payloadForDelete = {
            "packageId" : packageId,
            "items": getItems()
        } 
         
        commonApi.httpConnection(UPLOAD_SERVER.SERVICES.postMetaData, 'post', payloadForDelete )
        .then(async (res) => {
            if (res.status === 'OK'){
                dispatch(clearFiles());
                setLoading(false)
                navigate(`/view-case-document-upload-success`, {
                    state: {
                        about: stateValue.about,
                        incId: stateValue.incId,
                        packageId: packageId,
                        packageComment: packageComment,
                        files: docID,
                        businessResponse: type,
                        pnx: stateValue.pnx
                    }
                })
                sessionStorage.removeItem('packageId')
                dispatch(clearExtractedZip())
            }
            else{
                uploadError()
            }
        }).catch(()=>{
            uploadError()
        })
     };
    useEffect(()=>{
        let ans = false;
        value.forEach((val:string,index:number)=>{
            if((val === 'DT2010') && !(reasonTextCharacters.length>index && reasonTextCharacters[index].length>0)) {
                ans = true;
            }
        })
        setOtherCheck(ans);
    },[value,reasonTextCharacters,otherCheck]);

    useEffect(()=>{
        setDisableCta((value.length!== files.length || files.length === 0 || type.length===0 || packageComment.length===0 || otherCheck || value.includes('') || value.includes('Select')));
    },[reasonTextCharacters,value,type,packageComment,otherCheck]);


    let mainplaceholder = drupalData[0]?.mainPlaceholder.replaceAll("&#039;", "'").replaceAll("<br />", "").replaceAll("&quot;", "\"");
    let subplaceholder = drupalData[0]?.subPlaceholder.replaceAll("&#039;", "'").replaceAll("<br />", "").replaceAll("&quot;", "\"");
    
    function DropDown(index:any, _name: any) {
        const handleChange=(event: any , _index:number)=>{
            let newV=[...value]
            newV[_index] = event.target.value
            setValue(newV)
        }
        config?.sort(function (a:any, b:any) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          
          let configValue:any[]  = []
          let dropdownValues = []
          config?.map((b:any)=>{
            if (b.code === 'DT2010'){
                configValue = config.filter((item: any) => item.code === 'DT2010')
                dropdownValues = config.filter((item: any) => item.code !== 'DT2010')
            }
          })
          if (configValue.length !== 0){
              dropdownValues.push(configValue[0])
          }
        return (
          <div>
            <select aria-label="Type of file*" aria-required={true} key ={index.name} className = {classes.selectDocReason} value={value[index.index]} onChange={(e) => {handleChange(e,index.index) }}>
                    <option className = {classes.optionText} value="Select" selected disabled>Select</option>
                    {dropdownValues?.map((reason:any) => (
                        <option className = {classes.optionText} key={reason?.name} value = {reason?.code}> 
                        {reason?.name}  
                    </option>))}
                </select>
            </div>
        )
    }

    const dialogHandling = () => {
        setOpen(true);
    }

    const handleChangeTextArea=(reason: string , index:number)=>{
        let newV=[...reasonTextCharacters]
        newV[index]= reason;
        setReasonTextCharacters(newV)
    }
    const decideImage=(name:string)=>{
        let parts = name.split(".");
        let extension = parts[parts.length-1].toLowerCase();
        if(extension==='pdf') return (<img src={LargeIconPdf} className={classes.imgPreview} alt='' />);
        else if(extension==='png' || extension==='jpeg' || extension==='jpg' || extension==='gif') return (<img src={LargeIconImg} className={classes.imgPreview} alt='' />);
        else if(extension==='doc' ||extension==='docx' ||extension==='csv' ||extension==='xlsx' ||extension==='xls' ||extension==='txt') return (<img src={LargeIconDoc} className={classes.imgPreview} alt='' />);
        else if(extension==='wma' ||extension==='avi' ||extension==='mp4') return (<img src={LargeIconVideo} className={classes.imgPreview} alt='' />);
        else if(extension==='zip' || extension==='rar') return (<img src={LargeIconZip} className={classes.imgPreview} alt='' />);
        else if(extension==='mp3') return (<img src={LargeIconAudio} className={classes.imgPreview} alt='' />);
    }
    const decideFile=(file:any)=>{
            return (<Document file={file.url} noData={decideImage(file.name)}>
                         <Page className = {classes.previewImg} width = {216} pageNumber={1} />
                     </Document>);
    }
    const decideSrc=(data:any)=>{
        if(data){
            return URL.createObjectURL(data);
        } 
        else{
            return undefined; 
        } 
    }
    const viewFile=(data:any)=>{
        window.open(URL.createObjectURL(data), '_blank', 'noopener,noreferrer');
    }
    const fileType =(name:string)=>{
        let parts = name?.split(".");
        return (parts[parts.length-1].toUpperCase());
    }

    return (
        <>
        {
            loading ? <Loader/> :
        <div id='main-content'>
            <FileUploadStepper step = {2} pnx = {stateValue.about} />
            <hr className={classes.subDividerLine} />
            <div>
                
                <div className = {classes.headerContainer}>
                    <h2 className = {classes.subHeading}>{drupalData[0].addDetails}</h2>
                    {files?.length<maxNoOfFiles && <button className = {classes.addFilesBtn} onClick = {() => {navigate(`/view-cases-upload/${stateValue.incId}`, {state:{'pnx':stateValue.pnx}})} }>{drupalData[0].addMoreBtn}</button>}
                </div>
                <div className={classes.addContainer}>
                <div>
                    <div className={classes.dispRow}>
                        <p style={{font: '1.375rem AssistantSemiBold',marginBlock: 0,  marginRight: '1rem'}}>{drupalData[0].typeDocument} <span className = {classes.asterisk}>*</span></p>
                        <Tooltip 
                            tabIndex={0}
                            className={classes.tooltipExample}
                            componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: '#E4EEF8',
                                    font: '1.25rem AssistantRegular',
                                    color: '#000000',
                                    border: '1px solid #82AFDD',
                                    borderRadius: '4px',
                                    '& .MuiTooltip-arrow': {
                                        fontSize: '1.25rem',
                                        color: '#E4EEF8',
                                    },
                                    '.css-kudwh-MuiTooltip-arrow::before':{
                                        border: '1px solid #82AFDD'
                                    }
                                  },
                                },
                              }}
                            title={drupalData[0].proceedBtn?.replaceAll("\\&quot;",'"')} 
                            placement={'right-start'} arrow>
                            <img id="tooltip-upload" src={Info} alt="" />
                        </Tooltip>
                    </div>
                    <RadioGroup row
                        onChange={(e) => {
                            setType(e.target.value);
                        }}
                        aria-required={true}
                    >
                        <FormControlLabel
                            value='Business response'
                            style={{color: '#271C6F'}}
                            control={
                                <Radio
                                aria-label= "Is this your full business file?* Full Business File"
                                style={{color: '#271C6F'}}
                                disableRipple
                                sx={{color: "#271C6F", '&:focus-within': { color: "#271C6F", backgroundColor: 'rgba(39, 28, 111, 0.08)' }}}
                                classes={{ root: classes.radio}}
                                />
                            }
                            label={<span className={classes.radioLabel}>{drupalData[0].businessResponse} </span>}
                        />{' '}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControlLabel
                            value='Other document type'
                            control={
                                <Radio
                                style={{color: '#271C6F'}}
                                aria-label="Is this your full business file?* Other"
                                disableRipple
                                sx={{
                                    '.css-p3k7on-MuiButtonBase-root-MuiRadio-root.Mui-checked':{
                                        color: '#271C6F'
                                    },
                                    color: "#271C6F", '&:focus-within': { color: "#271C6F", backgroundColor: 'rgba(39, 28, 111, 0.08)' }}}
                                classes={{ root: classes.radio}}
                                />
                            }
                            label={<span className={classes.radioLabel}>{drupalData[0].otherType}</span>}
                        />
                    </RadioGroup>
                </div>
                <Grid item md={12}>
                    <div className = {classes.RightCardheading1}> 
                        <div className = {classes.purposeHeading1}>
                            <div className= {classes.purposeHeading1}>
                                <p className = {classes.purposeTitle}>{drupalData[0].comments} <span className = {classes.asterisk}>*</span></p>
                                <Tooltip 
                                    tabIndex={0}
                                    className={classes.tooltipExample}
                                    componentsProps={{
                                        tooltip: {
                                        sx: {
                                            bgcolor: '#E4EEF8',
                                            font: '1.25rem AssistantRegular',
                                            color: '#000000',
                                            border: '1px solid #82AFDD',
                                            borderRadius: '4px',
                                            '& .MuiTooltip-arrow': {
                                                fontSize: '1.25rem',
                                                color: '#E4EEF8',
                                            },
                                            '.css-kudwh-MuiTooltip-arrow::before':{
                                                border: '1px solid #82AFDD'
                                            }
                                        },
                                        },
                                    }}
                                    title={mainplaceholder?.replaceAll("\\&quot;",'"')} 
                                    placement={'right-start'} arrow>
                                    <img src={Info} alt="" style={{marginTop:'0.5rem', marginLeft: '1rem'}} />
                                </Tooltip>
                            </div>
                            <p aria-live="polite" role="alert" className = {classes.maxCharacters}>Max characters: {500-(packageComment?.length)}</p>
                        </div>
                        <textarea
                            maxLength = {500}
                            className = {classes.CommentStyle}
                            aria-label="Comments" 
                            aria-required="true"
                            onChange = {(e) => {setPackageComment(e.target.value)}}
                            placeholder={mainplaceholder}
                        />
                    </div>
                </Grid>
            </div>
           {files?.map((file: any,index:number) => {     
                return (  
                <div key={index+'.'}>
                    <Grid container spacing={3} className = {classes.previewCardBox}>
                        <Grid item xs="auto"  className = {classes.previewCardLeft}>
                            <div>
                        <div>
                            { (file.type?.substring(0,6) === 'image/' && file.type!=='image/gif')? 
                                <img className = {classes.imgPreview} src={decideSrc(file)} alt='null'/> : (decideFile(file))
                            }
                        </div>
                        <div tabIndex={0} onKeyDown={(e)=>{
                            if(e.code==='Enter'){
                                viewFile(file);
                            }
                        }} role="button" aria-label={"preview " + file.name} onClick={()=>viewFile(file)} className = {classes.previewContainer}>
                            <img src={ViewIcon} alt=""/>
                            <p className = {classes.previewText}>{drupalData[0].preview}</p>
                        </div>
                        </div>
                        </Grid>

                        <Grid  item md={8} xs={12}>
                        <Grid item md={12} className = {classes.removeBtnFlex}> 
                            <div>
                                <p className = {classes.fileName}>{file.name}</p>
                                <p className = {classes.fileType}>{fileType(file.name)}</p>
                            </div>
                            <div className = {classes.deleteContainer} key={index}> 
                                <button aria-label={"remove "+file.name} className = {classes.deleteBtn} onClick={()=>deleteItem(index, file.name)}>
                                    {drupalData[0].removeBtn}
                                </button>
                            </div>
                      </Grid>
                        <Grid item md={12}>
                            <div className = {classes.RightCardheading}>
                                <p className = {classes.reasonTitle}>{drupalData[0].reason} <span className = {classes.asterisk}>*</span></p>
                                <DropDown index = {index}/>
                            </div>     
                        </Grid>
                        <Grid item md={12}>
                            <div className = {classes.RightCardheading}> 
                            <div className = {classes.purposeHeading}>
                                <p className = {classes.purposeTitle}>{drupalData[0].comments} {value[index]==='DT2010' && <span className = {classes.asterisk}>*</span>}</p>
                                <p aria-live="polite" role="alert" className = {classes.maxCharacters}>Max characters: {500-(reasonTextCharacters[index]?reasonTextCharacters[index].length: 0)}</p>
                            </div>
                            <textarea
                                aria-label="Comments"
                                aria-required={value[index]==='DT2010'}
                                maxLength = {500}
                                className = {classes.SpecCommentStyle}
                                onChange = {(e) => {handleChangeTextArea(e.target.value, index)}}
                                placeholder={subplaceholder} />
                            </div> 
                            </Grid>
                            </Grid>
                    </Grid>
                  </div>
                 )
                })
                }
                {extractedZipFiles ? extractedZipFiles?.map((file: any,index:number) => {       
                return (  
                <div key={index+'.'}>
                    <Grid container spacing={3} className = {classes.previewCardBox}>
                        <Grid item xs="auto"  className = {classes.previewCardLeft}>
                            <div>
                        <div>
                            {
                                decideImage(file.fileName)
                            }
                        </div>
                        <div 
                        tabIndex={0} 
                         className = {classes.previewContainer}
                         role="button"
                         aria-label={"preview " + file.fileName}
                         >
                            <img src={ViewIcon} alt=""/>
                            <p className = {classes.previewText}>{drupalData[0].preview}</p>
                        </div>
                        </div>
                        </Grid>

                        <Grid  item md={8} xs={12}>
                        <Grid item md={12} className = {classes.removeBtnFlex}> 
                            <div>
                                <p className = {classes.fileName}>{file.fileName}</p>
                                <p className = {classes.fileType}>{fileType(file.fileName)}</p>
                            </div>
                            <div className = {classes.deleteContainer} key={index}> 
                                <button aria-label={"remove "+file.fileName} className = {classes.deleteBtn} onClick={()=>deleteExtractedFile(file.fileName)}>
                                    {drupalData[0].removeBtn}
                                </button>
                            </div>
                      </Grid>
                        <Grid item md={12}>
                            <div className = {classes.RightCardheading}>
                                <p className = {classes.reasonTitle}>{drupalData[0].reason} <span className = {classes.asterisk}>*</span></p>
                                <DropDown index = {files.length - zipFilesLength + index}/>
                            </div>     
                        </Grid>
                        <Grid item md={12}>
                            <div className = {classes.RightCardheading}> 
                            <div className = {classes.purposeHeading}>
                                <p className = {classes.purposeTitle}>{drupalData[0].comments} {value[index]==='DT2010' && <span className = {classes.asterisk}>*</span>}</p>
                                <p aria-live="polite" role="alert" className = {classes.maxCharacters}>Max characters: {500-(reasonTextCharacters[index]?reasonTextCharacters[index].length: 0)}</p>
                            </div>
                            <textarea
                                aria-required={value[files.length-zipFilesLength+index]==='DT2010'}
                                maxLength = {500}
                                className = {classes.SpecCommentStyle}
                                onChange = {(e) => {handleChangeTextArea(e.target.value, files.length -zipFilesLength + index)}}
                                placeholder={subplaceholder} />
                            </div> 
                            </Grid>
                            </Grid>
                    </Grid>
                  </div>
                 )
                })
                : <></>
                }
                </div>
                <div className = {classes.buttons}>
                    <button className = {classes.backCta} onClick = {dialogHandling}>{drupalData[0].tooltipInfo}</button>
                    <button data-testid={'proceedBtn'} className = {classes.proceedBtn} disabled={disableCta} onClick = {handleProcced}>{drupalData[0].cancelBtn}</button>
                </div>
                <CancelPopup
                    open={open}
                    onClose={handleClose}
                    incId={stateValue.incId}
                    pnx={stateValue.pnx}
                />
        </div> }
        </>

        
    )
}

export default ViewCaseUploadDocPreview

const useStyles = makeStyles({
    selectOption: {
        fontSize: '1.25rem',
        fontFamily: 'AssistantRegular'
    },
    tooltipExample:{
        cursor: 'pointer'
    },
    dispRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom : '1rem'
    },
    radioLabel: {
        font: '1.25rem AssistantRegular',
        color: '#404040'
    },
    addContainer: {
        background: '#F0F8FF',
        border: '1px solid #90C9FB',
        padding: '2rem 1.5rem'
    },
    radio: {
        '&$checked': {
            color: '#271C6F'
        }
    },
    subDividerLine: {
        backgroundColor: '#70707030',
        height: '1px',
        border: 0
    },
    helpGrid: {
        padding: '5.625rem 1.5rem 1rem 1.5rem',
        display: 'grid',
        backgroundColor: '#fff',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '3.25rem',
        '@media (max-width: 900px)' : {
            display: 'block'
          }
    },
    optionText: {
        margin: '11px 16px 11px 16px',
        color: '#404040',
        '&:hover': {
            backgroundColor: '#271C6F'
        }
    },
    previewImg: {
        border: '1px solid #5191CD',
        borderBottom: 'none'
    },
    imgPreview: {
        border: '1px solid #5191CD',
        borderBottom: 'none',
        maxWidth: '9.5rem',
        padding: '1rem',
        '@media (max-width: 400px)': {
            width: '150px'
        }
    },
    removeBtnFlex: {
        display: 'flex', 
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        '@media (max-width: 690px)': {
            display: 'block'
        }
    },
    deleteContainer: {
        paddingRight: '2rem'
    },
    previewContainer: {
        display: 'flex',
        alignItems:'center',
        backgroundColor: '#CEE1F1',
        border: '1px solid #5191CD',
        borderTop: 'none',
        cursor: 'pointer'
    },
    previewText: {
        font: '1.25rem AssistantSemiBold',
        color: '#271C6F',
        marginBlock: '0.5rem'
    },
    cardHead: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '24px 0 24px 11px',
        border: '1px solid',
        marginTop: '14px',
        backgroundColor: '#eaeaea'
    },
    cardInfoLeft:{
        display: 'flex',
        padding: '24px 0 24px 14px',
        border: '1px solid'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        marginBottom: '2rem',
        paddingBottom: '0',
        alignItems: 'center',
        '@media (max-width: 660px)': {
            display: 'block'
        }
    },
    subHeading: {
        marginBlock: '0',
        marginRight: '1.5rem',
        font: '1.75rem AssistantSemiBold',
        color: '#404040'
    },
    addFilesBtn: {
        padding: '0.688rem 1.5rem',
        border: '1px solid #D22D64',
        color: '#D22D64',
        font: '1.25rem AssistantSemiBold',
        width: 'fit-content',
        height: 'fit-content',
        cursor: 'pointer',
        borderRadius: '30px',
        backgroundColor: '#fff',
        '@media (max-width: 660px)': {
            marginTop: '2rem',
            width: '100%'
        },
        '&:hover':{
            backgroundColor: '#FAE5EC',
        }
    },
    previewCardBox: {
          marginLeft: '0',
          marginTop: '0',
          height: 'auto',
          width: 'auto',
          background: '#FFFFFF',
          borderBottom: '3px solid #f7f7f7',
          padding: '2.25rem 0',
          '@media (max-width: 690px)': {
              justifyContent: 'center'
          }
    },
    previewCardLeft: {
        '& .css-zow5z4-MuiGrid-root>.MuiGrid-item': {
            paddingTop: '0'
        }
    },
    previewCardRight: {
        backgroundColor: '#rgb(255, 255, 255, 0.7)',
        display: 'flex',
    },
    reasonTitle: {
        font: '1.375rem AssistantSemiBold',
        color: '#404040',
        marginBlock: '0',
        '@media (max-width: 690px)': {
            marginBlockStart: '1rem'
        }

    },
    selectDocReason: {
        width:'28rem',
        padding: '0.75rem 0.5rem',
        border: '1px solid #404040',
        borderRadius: '4px',
        font: '1.375rem AssistantRegular',
        color: '#404040',
        marginTop: '0.5rem',
        '@media (max-width: 660px)': {
            width: '90%'
        },
        '.selected:after': {
            position: 'absolute',
            content: "",
            top: '14px',
            right: '10px',
            width: '0',
            marginRight: '12px',
            height: 0,
            border: '6px solid transparent',
            borderColor: '#fff transparent transparent transparent'
          }
    },
    fileName: {
        color: '#404040',
        font: '1.5rem AssistantSemiBold',
        marginBlock: '0',
        marginRight: '1.5rem',
        wordWrap: 'break-word'
    },
    fileType: {
        color: '#404040',
        font: '1.25rem AssistantRegular',
        wordWrap: 'break-word',
        marginTop: '0.5rem',
    },
    deleteBtn: {
        padding: '0.688rem 1.5rem',
        border: '1px solid #271C6F',
        color: '#424287',
        font: '1.25rem AssistantSemiBold',
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: '30px',
        backgroundColor: '#fff',
        cursor: 'pointer',
        '&:hover':{
            backgroundColor: '#271C6F1A'
        },
        '@media (max-width: 690px)': {
            width: '100%'
        }

    },
    asterisk: {
        color: '#D22D64',
        font: '1.375rem AssistantSemiBold'
    },
    purposeHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '60%',
        '@media (max-width: 660px)': {
            display: 'block',
            width: 'auto'
        }
    },
    purposeHeading1: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 660px)': {
            display: 'block',
            width: 'auto'
        }
    },
    purposeTitle: {
        font: '1.375rem AssistantSemiBold',
        color: '#404040',
        marginTop: '1rem',
        marginBottom: '0.5rem'
    },
    maxCharacters: {
        font: '20px AssitantRegular',
        color: '#404040',
        marginBottom: '0.5rem'
    },
    boldTextType: {
        color: '#222222',
        fontSize: '15px',
        fontFamily: 'AssistentSemiBold',
        paddingBottom: '33px'
    },
    quickCaseInfo: {
        color: 'blue',
        fontSize: '18px',
        fontFamily: 'AssistantSemiBold',
        paddingLeft: '30rem'
    },
    
    respondentName: {
        paddingTop: '13px',
        listStyleType: 'none',
        color: '#222222',
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '2',
    },
    respondentEmail: {
        paddingTop: '13px',
        listStyleType: 'none',
        color: '#2B2A6D',
        fontSize: '20px',
        fontFamily: 'AssistantRegular',
        lineHeight: '2',
    },
    RightCardheading: {
        color: '#404040',
        fontFamily: 'AssistantSemiBold',
        fontSize: '20px'
    },
    RightCardheading1: {
        color: '#404040',
        fontFamily: 'AssistantSemiBold',
        fontSize: '20px',
        width: '70%'
    },
    CommentStyle: {
        width: '100%',
        height: '10rem',
        border: '1px solid #404040',
        borderRadius: '4px',
        font: '1.375rem AssistantRegular',
        color: '#767676',
        padding: '0.75rem 1rem',
        '@media (max-width: 660px)': {
            width: '80%'
        }
    },
    SpecCommentStyle: {
        width: '60%',
        height: '10rem',
        border: '1px solid #404040',
        borderRadius: '4px',
        font: '1.25rem AssistantRegular',
        color: '#767676',
        padding: '0.75rem 1rem',
        '@media (max-width: 660px)': {
            width: '80%'
        }
    },
    cardInfoLeftChild1: {
        display: 'grid',
        gap: '12px',
        marginBottom: '16px',
    },
    agreeContainer: {
        display: 'flex',
        alignItems: 'baseline',
        backgroundColor: '#F0F8FF',
        border: '1px solid #CEE1F1'
    },
    agreeText: {
        font: '1.375rem AssistantRegular',
        color: '#000',
        marginBlock: '1.188rem'
    },
    blueText: {
        color: '#2273C3',
        font: '1.375rem AssitantSemiBold'
    },
    agreeCheckbox: {
        color: '#271C6F',
        '&.Mui-checked': {
            color: '#271C6F'
        },
        '&:focus': {
            border: '1px solid red'
        }
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        padding: '2.063rem 0rem',
        paddingBottom: '0rem',
        '@media (max-width: 640px)': {
            display: 'block'
        }
    },
    backCta: {
        padding: '0.688rem 1.5rem',
        border: '1px solid #D22D64',
        color: '#D22D64',
        font: '1.25rem AssistantSemiBold',
        width: 'fit-content',
        borderRadius: '30px',
        backgroundColor: '#fff',
        cursor: 'pointer',
        marginRight: '1.5rem',
        gridArea: 'backCta',
        '&:hover':{
            backgroundColor: '#FAE5EC'
        },
        '@media (max-width: 640px)': {
            marginTop: '1rem',
            width: '100%'
        }
    },
    proceedBtn: {
        backgroundColor: '#D22D64',
        color: '#fff',
        font: '1.25rem AssistantSemiBold',
        padding: '0.688rem 1.5rem',
        borderRadius: '1.5rem',
        cursor: 'pointer',
        border: 'none',
        '&:disabled': {
            border: '1px solid #767676B3',
            backgroundColor: '#F7F7F7',
            color: '#767676B3',
            '&:hover':{
                cursor: 'default',
                backgroundColor: '#F7F7F7'
            }
        },
        '@media (max-width: 640px)': {
            width: '100%'
        },
        '&:hover':{
            backgroundColor: '#B8134A'
        }
    }
})


