
import { makeStyles } from '@mui/styles';
import React from 'react';
import warn from './assets/icons/Icon_Warning.svg'
import 'react-toastify/dist/ReactToastify.css';
import {BootstrapDialog,InfoDialogTitle } from './components/collapsible-table/Table/PopupTemplate';
interface IncorrectPopupProps {
	open: boolean;
	handleLogout: () => void;
	handleClose: () => void;
}

const LogoutWarning = ({ open, handleLogout, handleClose }: IncorrectPopupProps) => {
	const classes = useStyle();
	
	return (
		<div>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="info-dialog-title"
				open={open}
				classes={{ paper: classes.paper }}
			>
				<InfoDialogTitle id="info-dialog-title" onClose={handleClose}>
				</InfoDialogTitle>
				<div className={classes.displayCenter}>
					<div>
						<img src={warn} alt="warn" />
					</div>
					<div>
						<p className={classes.removeHeading}>Warning: Account will log out</p>
						<p className={classes.removeSubHeadingOne} >
							Session has been inactive over it's time limit. Session will be logged out in 2 minutes. Press continue to continue the session.
						</p>
						
					</div>
					<div className={classes.buttonDes}>
						<button className={classes.discardBtn} onClick={handleLogout}>
							Logout
						</button>
						<button className={classes.addNumBtn} onClick={handleClose}>
							Continue
						</button>
					</div>
				</div>
			</BootstrapDialog>
		</div>
	);
}

export default LogoutWarning;

const useStyle = makeStyles({
	dialogTitle: {
		margin: 0,
		fontSize: "1.75rem",
		fontFamily: "AssistantSemiBold"
	},
	keyTitle: {
		color: '#40404099',
		fontSize: '1.25rem',
		fontFamily: 'AssistantRegular'
	},
	caseRefContainer: {
		fontFamily: 'AssistantSemiBold',
		padding: '0 1.25rem'
	},
	paper: {
		minWidth: "40rem",
		"@media(max-width:767px)": {
			minWidth: '92%'
		},
		"@media (min-device-width: 768px)  and (max-device-width: 1023px)": {
			minWidth: '50%'
		}
	},
	dialogHeader: {
		display: 'flex',
		justifyContent: 'center',
		color: '#D22D64'
	},
	discardBtn: {
		padding: '0.688rem 1.5rem',
		color: '#D22D64',
		borderRadius: '1.5rem',
		backgroundColor: '#FFFFFF',
		border: '0.0625rem solid #D22D64',
		fontSize: '1.25rem',
		fontFamily: 'Assistant',
		opacity: '1',
		marginRight: '2rem',
		marginTop: '1rem',
		'&:hover': {
			backgroundColor: '#FAE5EC',
			cursor: 'pointer'
		},
		"@media(max-width:767px)": {
			fontSize: '1.1rem',
			width: '90%',
			marginRight: '0'
		},
		"@media (min-device-width: 768px)  and (max-device-width: 1023px)": {
			marginBottom: '2rem'
		}
	},

	addNumBtn: {
		padding: '0.688rem 1.5rem',
		marginRight: '1.5rem',
		fontSize: '1.25rem',
		backgroundColor: '#D22D64',
		color: '#FFFFFF',
		border: '1px solid #D22D64',
		borderRadius: '1.5rem',
		'&:hover': {
			backgroundColor: '#B8134A',
			cursor: 'pointer'
		},
		"@media(max-width:767px)": {
			width: '90%',
			marginTop: '2.5rem',
			marginRight: '0rem'
		}
	},
	logBtn: {
		padding: '0.688rem 1.5rem',
		marginRight: '1.5rem',
		fontSize: '1.25rem',
		backgroundColor: '#D22D64',
		color: '#FFFFFF',
		border: '1px solid #D22D64',
		borderRadius: '1.5rem',
		'&:hover': {
			backgroundColor: '#B8134A',
			cursor: 'pointer'
		},
		"@media(max-width:767px)": {
			fontSize: '1.2rem',
			width: '97%',
			marginRight: '0rem',
			marginTop: '2rem',
			display: 'block'
		},
		display: 'none'
	},
	buttonDes: {
		textAlign: 'center',
		paddingLeft: '1rem',
		marginBottom: '3.25rem',
		"@media(max-width:767px)": {
			paddingLeft: '0'
		},
	},
	displayCenter: {
		textAlign: 'center',
		padding: '0rem 5rem',
		"@media(max-width:767px)": {
			padding: '0rem'
		}
	},
	removeHeading: {
		fontSize: '1.6rem',
		color: '#404040',
		fontFamily: 'AssistantSemiBold',
		"@media(max-width:767px)": {
			padding: '0rem 3rem 0 3rem'
		}
	},
	removeSubHeading: {
		fontSize: '1.375rem',
		color: '#404040',
		lineHeight: '1.9rem',
		fontFamily: 'AssistantRegular',
		"@media(max-width:767px)": {
			display: 'block',
			padding: '0rem 1rem 0 1rem'
		}
	},
	removeSubHeadingOne: {
		fontSize: '1.375rem',
		color: '#404040',
		lineHeight: '1.9rem',
		fontFamily: 'AssistantRegular',
		"@media(max-width:767px)": {
			display: 'block',
			padding: '0rem 1rem 0 1rem'
		}
	},
	removeSubHeadingTwo: {
		fontSize: '1.375rem',
		color: '#404040',
		lineHeight: '1.9rem',
		fontFamily: 'AssistantRegular',
		display: 'none',
		"@media(max-width:767px)": {
			display: 'none'
		}
	}
});