import { createSlice } from "@reduxjs/toolkit";

interface ProgressType {
    userdata: {}
}

const initialState:ProgressType ={
    userdata: {}
}

const UserDetailsSlice = createSlice({
    name: 'userDetailsReducer',
    initialState,
    reducers: {
        userDetails: (state:any,action)=>{
            state.userdata = action.payload;
        },
        
    }
});

export const {userDetails} = UserDetailsSlice.actions;
export default UserDetailsSlice.reducer;