import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Icon_Check_Circle from '../../assets/icons/Icon_Check_Circle.svg';
import Icon_Cross from '../../assets/icons/Icon_Wrong.svg';
import { makeStyles } from '@mui/styles';
import FileUploadStepper from './FileUploadStepper';
import { DRUPALSERVER } from '../../common/drupal-api/drupal-api-urls';
import DrupalService from '../../common/drupal-api/drupal-api-service';
import CommonService from '../../common/api/common-api-service';
import { UPLOAD_SERVER } from '../../common/upload-api/api-urls';

const drupalApi = new DrupalService();
const defaultDrupalData = [
    {
        "uploading": "Uploading your documents...",
        "pleasewait": "Please wait while we upload your files to our server.",
        "description": "You have successfully uploaded the files. We will take some time to review the files before it shows up on the case details.",
        "backBtn": "Back to case details",
        "title": "Scanning your files for virus...",
        "pleaseWaitScan": "Please wait while we scan your files for viruses.",
        "info": "We will shortly let you know if your files have passed the virus scan and are fit to be uploaded.",
        "uploadSuccess": "Documents uploaded successfully",
        "uploadDes": "Your uploaded documents will now be visible on the case details under case documents after we successfully review them."
    }
];
const useStyles = makeStyles({
    trackLink: {
        font: '1.25rem AssistantSemiBold',
        '&:hover': {
            textDecoration: 'underline'
        },
        '&:focus': {
            textDecoration: 'underline'
        }
    },
    dispRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    dispBox: {
        border: '1px solid #404040',
        marginTop: '2rem',
        display: 'flex',
        background: '#FFFFFF',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '9.125rem 23.75rem'
    },
    donelogo: {
        color: 'green',
        height: '5%',
        width: '5%',
        paddingTop: '3.5rem',
        marginLeft: '4%',
    },
    dispCase: {
        fontSize: '1.3rem',
        fontWeight: '600',
        marginTop: '6.5%',
        marginLeft: '2rem',
    },
    viewAllCase: {
        fontSize: '1.1rem',
        marginTop: '-2%',
        float: 'left',
        marginLeft: '4.2%',
    },
    rmvUnderline: {
        textDecoration: 'none',
    },
    topTitle: {
        font: '1.75rem AssistantSemiBold',
        // marginBlock: 0,
        marginBottom: '0.5rem'
    },
    smallTitle: {
        font: '1.375rem AssistantRegular',
        marginBlock: 0
    },
    backCta: {
        padding: '0.688rem 1.5rem',
        border: '1px solid #D22D64',
        color: '#D22D64',
        font: '1.25rem AssistantSemiBold',
        width: 'fit-content',
        borderRadius: '30px',
        marginTop: '0.5rem',
        backgroundColor: '#fff',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#FAE5EC'
        },
        gridArea: 'backCta',
        '@media (max-width: 480px)': {
            width: '100%'
        }
    },
    subDividerLine: {
        backgroundColor: '#70707030',
        height: '1px',
        border: 0
    }
})

const commonApi = new CommonService('upload');
const UploadSuccessfull = () => {

    const classes = useStyles();
    const { state } = useLocation();
    const [drupalData, setDrupalData] = useState<any>(defaultDrupalData);
    const [response, setResponse] = useState<any>({});
    const [flag, setFlag] = useState<boolean>(false);
    const stateValue: any = state;
    const [uploadFail, setUploadFail] = useState<boolean>(false);

    const navigate = useNavigate();
    const detailsURL = `/view-cases-details/${stateValue.incId}`;

    useEffect(() => {
        window.scrollTo(0,0);
        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getLoadAndSuccessPage, 'get')
            .then((res) => {
                if (res.length > 0) {
                    setDrupalData(res);
                } else {
                    setDrupalData(defaultDrupalData);
                }
            })
            .catch((_e: any) => {
                setDrupalData(defaultDrupalData);
            });
        if (stateValue?.flag === true){
            setUploadFail(true);
        } else{
            let requestPayload = {
                caseId: stateValue?.incId,
                packageId: stateValue?.packageId,
                comments: stateValue.packageComment,
                isBusinessResponse: stateValue?.businessResponse === 'Business response' ? true : false,
                files: stateValue?.files,
                pnx: stateValue?.pnx
            }
            commonApi.httpConnection(UPLOAD_SERVER.SERVICES.getUploadCompleted, 'post', requestPayload)
            .then((res) => {
                setResponse(res);
                setFlag(true);
            })
            .catch((_e: any) => {
                console.error(_e);
            });
        }
            // eslint-disable-next-line
        }, []);

    if(uploadFail || (flag && (!response?.success || response?.status!=='OK'))){
        return (
            <div id='main-content'>
            <FileUploadStepper step={5} pnx={stateValue.about} />
            <hr className={classes.subDividerLine} />
            <div>
                <p className={classes.topTitle} role="alert">{drupalData[0].uploadFail}</p>
                <p className={classes.smallTitle}>{drupalData[0].failSubText}</p>
                <div className={classes.dispBox}>
                    <img src={Icon_Cross} alt="" />
                    <p style={{ font: '1.375rem AssistantRegular', wordBreak: 'break-word', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: drupalData[0].uploadFailDescription }}></p>
                    <button className={classes.backCta} onClick={() => { navigate(detailsURL) }}>{drupalData[0].backBtn}</button>
                </div>
            </div>
        </div>
        );
    }

    return (
        <div id='main-content'>
            <FileUploadStepper step={5} pnx={stateValue.about} />
            <hr className={classes.subDividerLine} />
            <div>
                <p className={classes.topTitle} role="alert">{drupalData[0].uploadSuccess}</p>
                <p className={classes.smallTitle}>{drupalData[0].uploadDes}</p>
                <div className={classes.dispBox}>
                    <img src={Icon_Check_Circle} alt="" />
                    <p style={{ font: '1.375rem AssistantRegular', wordBreak: 'break-word', textAlign: 'center', marginBlockEnd: '0' }} dangerouslySetInnerHTML={{ __html: drupalData[0]?.description }}></p>
                    <p style={{ font: '1.375rem AssistantRegular', wordBreak: 'break-word', textAlign: 'center', marginBlockStart: '0' }}>You can track the status of the files submitted for upload from <span>
                        <Link to='/track-uploads' className={classes.trackLink}>Track Upload</Link></span></p>
                    <button className={classes.backCta} onClick={() => { navigate(detailsURL) }}>{drupalData[0].backBtn}</button>
                </div>
            </div>
        </div>
    )
}

export default UploadSuccessfull;