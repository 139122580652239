import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { usePagination, DOTS } from './usePagination';
import ChevronRight from '../../assets/icons/ChevronRight.svg';
import ChevronLeft from '../../assets/icons/ChevronLeft.svg';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });
  const classes = useStyles();
  const [prevPressed, setPrevPressed] = useState<boolean>(false);
  const [nextPressed, setnextPressed] = useState<boolean>(false);

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className={classes.pagination}>
      {currentPage === 1 ? (
        <img role='button' src={ChevronLeft} alt="" />
      ) : (
        <button
          role='button'
          aria-label="previous"
          onClick={() => {
            onPrevious()
            setPrevPressed(true)
          }}
          className={classes.nextprevBtn}
          //aria-pressed={prevPressed}
        >
          <img src={ChevronRight} className={classes.rotateImg} alt="previous" />
        </button>
      )}
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className={classes.dots}>&#8230;</li>
        }

        return (
          <button tabIndex={0} role='button' aria-pressed={pageNumber === currentPage ? 'true' : 'false'}
            key={pageNumber}
            className={pageNumber === currentPage ? classes.tagActive : classes.tagInActive}
            onClick={() => onPageChange(pageNumber)}
          >
            <li
              onClick={() => onPageChange(pageNumber)}
              className={classes.tag1}
            >
              {pageNumber}
            </li> </button>
        );
      })}
      {currentPage !== lastPage ? (
        <button
          role='button'
          aria-label='next'
          onClick={() => {
            onNext()
            setnextPressed(true);
          }}
          className={classes.nextprevBtn}
          //aria-pressed={nextPressed}
        >
          <img className={classes.cursor} src={ChevronRight} alt="next" />
        </button>
      ) : (
        <img src={ChevronLeft} role='button' className={classes.rotateImg} alt="" />
      )}
    </ul>
  );
};

export default Pagination;
const useStyles = makeStyles({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontFamily: 'AsisstantSemiBold',
    cursor: 'pointer',
    alignItems: 'center'
  },
  tagActive: {
    width: '2rem',
    height: '2rem',
    backgroundColor: '#C6C6C670',
    borderRadius: '5px',
    border: '1px solid #C6C6C6',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2rem 0.5rem'
  },
  tagInActive: {
    width: '2rem',
    height: '2rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2rem 0.5rem',
    border: 'none'
  },
  tag1: {
    fontSize: '1.25rem',
    fontFamily: 'AssistantSemiBold',
    color: '#271C6F',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '0.2rem'
  },
  rotateImg: {
    transform: 'rotate(180deg)',
    cursor: 'pointer'
  },
  cursor: {
    cursor: 'pointer'
  },
  nextprevBtn: {
    padding: 0,
    background: 'transparent',
    border: 'none'
  },
  dots: {
    listStyleType: "none",
    backgroundColor: 'transparent',
    cursor: 'default',
    color: '#271C6F',
    fontSize: '1.25rem'
  }

})
