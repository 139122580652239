import{makeStyles} from '@mui/styles';
import {useState, useEffect} from 'react';
import uploadWhite from '../../assets/icons/uploadCurrent.svg';
import fileScan from '../../assets/icons/scanCurrent.svg';
import addDocuments from '../../assets/icons/selectFilesCurrent.svg';
import documentDetails from '../../assets/icons/addContactInfoCurrent.svg';
import uploadWhiteDisabled from '../../assets/icons/uploadDisabled.svg';
import fileScanDisabled from '../../assets/icons/scanDisabled.svg';
import documentDetailsDisabled from '../../assets/icons/addContactInfoDisabled.svg';
import completedStep from '../../assets/icons/CompletedStep.svg';
import currentDot from '../../assets/icons/currentDot.svg';
import disabledDot from '../../assets/icons/disabledDot.svg';
import { DRUPALSERVER } from '../../common/drupal-api/drupal-api-urls';
import DrupalService from '../../common/drupal-api/drupal-api-service';

const useStyles = makeStyles ({
    completedResponsive: {
        width: '1.75rem'
    },
    horizontalLine: {
        borderRight: '2px solid pink',
        background: 'pink',
        width: '55%'
    },
    scrollStep: {
        '@media (max-width: 605px)': {
            display: 'none'
        }
    },
    pageTitle:{
        font: '2.25rem AssistantSemiBold',
        color: '#271C6F',
        '@media (max-width: 655px)': {
            font: '1.5rem AssistantSemiBold'
        }
    },
    numberHide: {
        '@media (max-width: 805px)': {
            display: 'none'
        }
    },
    stepNumber: {
        font: '1.25rem AssistantSemiBold',
        color: '#767676',
        marginBlockEnd: '0',
        '@media (min-width: 605px)': {
            display: 'none'
        }
    },
    titleDescription: {
        marginBlock: '0',
        font: '1.375rem AssistantRegular',
        color: '#404040',
        '@media (max-width: 655px)': {
            font: '1.25rem AssistantRegular'
        }
    },
    reference: {
        marginBlockStart: '2rem',
        marginBlockEnd: '2.5rem',
        font: '1.625rem AssistantSemiBold',
        color: '#000000',
        '@media (max-width: 655px)': {
            font: '1.375rem AssistantSemiBold'
        }
    },
    refNumber: {
        color: '#271C6F',
        '@media (max-width: 640px)': {
            display: 'block'
        }
    },
    dotContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (min-width: 605px)': {
            display: 'none'
        }
    },
    line: {
        flex: '1',
        height: '3px',
        backgroundColor: '#567B23'
    },
    lineDisabled: {
        flex: '1',
        height: '3px',
        backgroundColor: '#C6C6C6'
    },
    stepDot: {
        width: '0.75rem',
        height: '0.75rem',
        backgroundColor: '#D22D64',
        borderRadius: '50%'
    },
    dotDisable: {
        width: '0.75rem',
        height: '0.75rem',
        backgroundColor: '#D22D64',
        borderRadius: '50%',
        opacity: '35%'
    },
    stepContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '2.5rem',
        overflow: 'hidden',
        alignItems: 'baseline'
    },
    
    step: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 805px)': {
            flexDirection: 'row',
            justifyContent: 'flex-start'
        }
    },
    iconBg: {
        '@media (max-width: 805px)': {
            display: 'none'
        }
    },
    stepText: {
        font: '1.75rem AssistantSemiBold',
        color: '#404040',
        marginBlock: '0',
        textAlign: 'center',
        '@media (max-width: 805px)': {
            font: '1.375rem AssistantSemiBold',
            width: 'auto'
        }
    },
    stepperDesign: {
        display: 'flex', alignItems: 'center', padding: '0px 70px'
    },
    stepperTexts: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2rem'
    }

})

const DotOrNot = (step: number, value: number) => {

    if(step === value) {
        return currentDot;
    }
    else if (step > value) {
        return completedStep;
    }
    else {
        return disabledDot;
    }

}

const CompletedClassName = (step: number, value: number) => {
    const classes = useStyles();
    if (step > value) {
        return classes.completedResponsive;
    }
    else {
        return ''
    }
}

const defaultDrupalData = [
    {
        "addDocuments":"1. Add documents",
        "addDetails":"2. Add document details",
        "fileScan":"3. File scan for virus",
        "upload":"4. Submit files for upload",
        "title":"Upload documents for",
        "desc":"You can upload the documents to support your case or alternatively, choose to scan and upload documents using mobile or tablet.",
        "caseRef":"Case Reference number:",
        "steps":"Step *step* of 3"
    }
]

const FileUploadStepper = ({step, pnx}: any) => {
    const [drupalData, setdrupalData] = useState<any>(defaultDrupalData);
    const classes = useStyles();
    const iconArray = [addDocuments, documentDetails, fileScan, uploadWhite];
    const hideStep = false;

    useEffect(() => {
        const drupalApi = new DrupalService();
        drupalApi.httpConnection(DRUPALSERVER.SERVICES.getFileStepper, 'get')
            .then((res: any) => {
                if(res && res.length>0){
                    setdrupalData(res);
                }else{
                    setdrupalData(defaultDrupalData);
                }
            })
            .catch((_e: any) => {
                setdrupalData(defaultDrupalData);
            });
    }, []);

    const decideImage=(idx:number)=>{
        switch(idx){
            case 1:
                if(step===1) return iconArray[0];
                return completedStep;
            case 2:
                if(step===2) return iconArray[1];
                else if(step>2) return completedStep;
                return documentDetailsDisabled;
            case 3:
                if(step===3) return iconArray[2];
                else if(step>3) return completedStep;
                return fileScanDisabled;
            case 4:
                if(step===4) return iconArray[3];
                else if(step>4) return completedStep;
                return uploadWhiteDisabled;
        }
    }


    return (
        <>
        <header tabIndex={0}>
            <h1 className = {classes.pageTitle} >{drupalData[0].title} {pnx}</h1>
        </header>
        <div className = {classes.dotContainer}>
            <img className = {CompletedClassName(step, 1)} alt = '' src = {DotOrNot(step, 1)} />
            <div className={step >= 2 ? classes.line: classes.lineDisabled}></div>
            <img className = {CompletedClassName(step, 2)} alt = '' src = {DotOrNot(step, 2)} />
            <div className={step >= 3 ? classes.line: classes.lineDisabled}></div>
            {hideStep && <> <img className = {CompletedClassName(step, 3)} alt = '' src = {DotOrNot(step, 3)} />
            <div className={step >= 4 ? classes.line: classes.lineDisabled}></div> </> }
            <img className = {CompletedClassName(step, 4)} alt = '' src = {DotOrNot(step, 4)} />
        </div>
        <div>
            <p className = {classes.stepNumber}>{drupalData[0].steps.replace("*step*",step)}</p>  
        </div>
        <div className = {classes.scrollStep}>
            <div className = {classes.stepperDesign} >
                <img src={decideImage(1)} alt=''/>
                <div className={step >= 2 ? classes.line: classes.lineDisabled}></div>
                <img src={decideImage(2)} alt=''/>
                {hideStep && <> <div className={step >= 3 ? classes.line: classes.lineDisabled}></div>
                <img src={decideImage(3)} alt=''/> </>}
                <div className={step >= 4 ? classes.line: classes.lineDisabled}></div>
                <img src={decideImage(4)} alt=''/>
            </div>
            <ol className = {classes.stepperTexts} >
                <li {...(step===1 && {"role":"alert", "aria-current":"step", "aria-label":"Step 1 of 3 Add documents"})} className = {classes.stepText}><span className = {classes.numberHide}></span>{drupalData[0].addDocuments.replace('1.','')}</li>
                <li {...(step===2 && {"role":"alert","aria-label":"Step 2 of 3 Add document details", "aria-current":"step"})} className = {classes.stepText}><span className = {classes.numberHide}></span>{drupalData[0].addDetails.replace('2.','')}</li>
                {hideStep &&  <li {...(step===3 && {"aria-current":"step"})} className = {classes.stepText}><span className = {classes.numberHide}></span>{drupalData[0].fileScan.replace('3.','')}</li> }
                <li {...(step===5 && {"role":"alert","aria-label":"Step 3 of 3 Submit files for upload", "aria-current":"step"})} className = {classes.stepText}><span className = {classes.numberHide}></span>{drupalData[0]?.upload?.replace('4.','')}</li>
            </ol>
        </div>
       
        </>
    )
}

export default FileUploadStepper

