import { makeStyles } from '@mui/styles';
import DialogContent from '@mui/material/DialogContent';
import React, { useEffect, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DRUPALSERVER } from '../common/drupal-api/drupal-api-urls';
import DrupalService from '../common/drupal-api/drupal-api-service';
import json from './static-privacy-policy.json';
import {BootstrapDialog,InfoDialogTitle } from './collapsible-table/Table/PopupTemplate';
interface PrivacyPolicyProps {
    open: boolean;
    handleClose: () => void;
}

const useStyle = makeStyles({
    dialogTitle: {
        margin: 0,
        fontSize: '1.75rem',
        fontFamily: 'AssistantSemiBold'
    },
    caseRefContainer: {
        fontFamily: 'AssistantSemiBold',
        padding: '0 1.25rem',
        backgroundColor: '#fff',
        border: "1px solid #707070",
        borderRadius: "12px",
        fontSize:'1.25rem',
        color: "#404040",
        "& a": {
            color: "#0070c0",
            textDecoration: 'underline',
        }
    },
    paper: { minWidth: '50rem' },
    dialogHeader: {
        display: 'flex',
        justifyContent: 'center',
        color: '#D22D64'
    },
    textAreaAtyle: {
        fontSize: '1rem',
        fontFamily: 'AssistantRegular',
        color: '#404040',
        borderColor: '#c6c6c6'
    },
});
const PrivacyPolicyInfoDialog = ({ open, handleClose }: PrivacyPolicyProps) => {
    const classes = useStyle();
    const [drupalData, setDrupalData] = useState(json);

    useEffect(() => {
        const drupalApi = new DrupalService();
        const getDrupalData = () => {
            drupalApi.httpConnection(DRUPALSERVER.SERVICES.getPrivacyPolicyContent, 'get')
                .then((res: any) => {
                    setDrupalData(res && res.length > 0 && res[0] !=='<' ? res[0] : json);
                })
                .catch((e: any) => {
                    setDrupalData(json);
                });
        }
        getDrupalData();
        }, [])
        return (
            <div>
                <BootstrapDialog
                    role='dialog'
                    onClose={handleClose}
                    aria-labelledby='privacy-policy-dialog-title'
                    open={open}
                    classes={{ paper: classes.paper }}
                    data-testid='PrivacyPolicyDetailsDialog'
                >
                    <InfoDialogTitle id='privacy-policy-dialog-title' onClose={handleClose}>
                        <div className={classes.dialogHeader}  role="presentation">
                            <h3 className={classes.dialogTitle}>{drupalData.Privacy_policy_content_title}</h3>
                        </div>
                    </InfoDialogTitle>
                    <DialogContent dividers>
                        <div className={classes.caseRefContainer} dangerouslySetInnerHTML={{__html:drupalData.body}}>
                        </div>
                    </DialogContent>
                    <DialogActions>

                        <Button variant='contained' disableRipple tabIndex={0} className='column-apply-btn' onClick={handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        );
    }

    export default PrivacyPolicyInfoDialog
