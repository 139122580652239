export const DRUPALSERVER = {
    BASE_URL:'/content/',
    SERVICES: {
        getHeader:'header-footer-menus-logo-api',
        getFooter:'global-footer-blocks-api',
        getHeaderMain:'header-logo-api',
        getHeaderMenu:'header-menu/',
        getReasonUpdateCase: 'reasons-update-case-api',
        getMaintenance:'dashboard-maintenance-notification',
        getInitiateOnboardingTableHeader:'onboarding/table-headers',
        getViewCaseTableHeader:'table-headers/viewcaselist',
        getViewCasePageInfo:'viewcaselist/texts',
        getFeatureFlags: 'features',
        getnonAdminPage:'nonAdminPage',
        getnonAdminTableHeader:'table-headers/nonadmin-respondent',
        getnonAdminRespondent:'filter/nonadmin-respondent',
        getaddnewNonAdmin:'addnew-nonadmin',
        getViewCaseDetails: 'viewcasecard',
        getTabsDetails: 'viewcasetabs',
        getCaseOverview: 'case-overview',
        getCaseOutcomes : 'case-outcomes',
        getDashboardStaticData: 'dashboardStaticTexts',
        getRespondentAdminFilter:'respondentAdminFilter',
        getRespondentAdminPageHeader:'respondentAdminPage',
        getViewCaseFilterDrupalData:'viewCaseFilter',
        getPreviewPageData : 'respondentAdminPreviewPage',
        getRespondentInvitationSend:'respondentInvitationSend',
        getUpdateCase: 'update-case',
        getPrivacyPolicyContent:'privacy-policy-content',
        getPreviewNonAdmin: 'respondentNonAdminPreviewPage',
        getUpdateMobileNumber : 'addUpdateNumber',
        getYourProfile: 'yourProfile',
        getManageProfile: 'manageProfile',
        getManageUser : 'manage-user-lockunlock',
        getRespondentNonAdminSendInvitation:'respondentNonAdminSendInvitation',
        getCookies:'cookies',
        getBreadcrumbs:'breadcrumbs',
        getUploadMainPage: 'uploadmainpage',
        getUploadPreviewPage: 'uploadpreviewpage',
        getFileTypes: 'filetypes',
        getFileScanPage: 'filescanpage',
        getLoadAndSuccessPage: 'loadingAndSuccess',
        getTrackUploadPage: 'trackuploadpage',
        getCancelPopup: 'cancelPopup',
        getDownloadPage: 'downloadDocuments',
        getDownloadFilter: 'downloadFilter',
        getFileStepper: 'filestepper',
        serverMessages:'serverMessages',
        getUploadErrorMsgs: 'uploadMessages',
        getDashboardError: 'dashboardMessages',
        getNonAdminError: 'nonAdminMessages',
        getUpdateError: 'updatesectionMessages',
        getComplainantRep: 'complainantRepresentative',
        getOrganisationParties: 'organisationCaseParties',
        getIndividualParties: 'individualCaseParties',
        getCaseWorkerParties: 'caseWorkerRepresentative',
        getConfirmDisable: 'userconfirmDeletePopup',
        getMailJet:'mailjet',
        getEmailValidation: 'OnboardEmails',
        getSessionManagement: 'session_management_information',
        getSessionTimer: 'session_timer',
        getDeleteAccountSteps:'deleteAccountSteps',
        getUpdateEmailSteps:'userProfileSteps',
        getNotificationPage:'notifications',
        getCompatabilityMessages:'compatibility_messages',
        getNotificationTimer:'notificationTimer'
    }
}