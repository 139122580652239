
import { toast } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';

export const drupalForErrors = 
	{
		"azureconnectivity": "Connection Failed with Azure,Please Contact Admin",
		"phoenixconnectivity": "Connectivity issue with Phoenix,Please Contact Admin",
		"organisationnotfound": "Organisation not exist in phoenix.",
		"cryptovalidationerror": "Passed string is not valid.",
		"messagenotreadable": "Malformed JSON request.",
		"networkerror": "Something went wrong or Kindly check Internet connection!!",
		"field_message_7": "",
    "errorOccurred":"Error occurred",
    "organisationidisRequired":"Organisationid is required",
    "organisationNotRegistered":"Organisation is not registered in AD",
    "incidentidMandatoryField":"Incidentid is a mandatory field"
	}




const checkErrorCode =(response:any, drupalData:any)=>{
 
 if (response?.errorCode === "RESPONDENT_1002") {
    toast.error(drupalData?.messagenotreadable);
  }
  else if ( response?.errorCode === "RESPONDENT_AZURE_1000") {
    toast.error(drupalData?.azureconnectivity);
  } 
  else if ( response?.errorCode ==="RESPONDENT_PHOENIX_1001") {
    toast.error(drupalData?.phoenixconnectivity);
  } 
  else if ( response?.errorCode ==="RESPONDENT_CRYPTO_1003") {
    toast.error(drupalData?.cryptovalidationerror);
  } 
  else if ( response?.errorCode ==="RESPONDENT_CACHE_1005" ) {
    toast.error(drupalData?.azureconnectivity);
  } 
  else if ( response?.errorCode === "RESPONDENT_INVALID_AD_ORG_1000" ) {
    toast.error(drupalData?.organisationNotRegistered);
  }
  else if ( response?.errorCode === "RESPONDENT_ORGANISATION_NOT_EXIST_IN_PHOENIX_1007" ) {
    toast.error(drupalData?.organisationnotfound );
  }
  else if ( response?.errorCode === "RESPONDENT_1004" ) {
    toast.error(drupalData?.errorOccurred);
  }
  else if ( response?.errorCode === "RESPONDENT_MANDATORY_FIELD_1006" ) {
    toast.error(drupalData?.incidentidMandatoryField );
  }
  else if ( response?.errorCode === "RESPONDENT_ORGANISATIONID_IS_REQUIRED_1008" ) {
    toast.error(drupalData?.organisationidisRequired);
  }
}
export const errorComponentToast = (response: any,drupalData:any) => {
 
  if (process.env.REACT_APP_ENV === "PRD") {
    checkErrorCode(response,drupalData)
  } 
  else {
    checkErrorCode(response,drupalData)
    console.log(response?.errorMessage)
  }
}