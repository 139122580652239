

type Order = "asc" | "desc";
export default class Utils {

  respondents = [
];
  createRespondents(number: string) {
    return []
  }

  createDataRes(fullName: string,
    fos_capacity: string,
    fos_parentorganisationcapacity: string,
    _parentcustomerid_value: string,
    birthdate: string,
    _accountid_value: string,
    _parent_contactid_value: string,
    fos_fcaid: string,
    emailAddress1: string,
    invitedStatus: string,
    jobtitle: string,
    checked: string,
    visible: string) {
    return {
      fullName,
      fos_capacity,
      fos_parentorganisationcapacity,
      _parentcustomerid_value,
      birthdate,
      _accountid_value,
      _parent_contactid_value,
      fos_fcaid,
      emailAddress1,
      invitedStatus,
      jobtitle,
      checked,
      visible
    }
  }
  createData(
    name: string,
    fos_fcareference: string,
    legalentityname: string,
    fos_hierarchylevel: string,
    accountNumber: string,
    parentaccountid_value: string,
    accountcategorycode: string,
    businesstypecode: string,
    fos_approvalstatus: string,
    accountid: string,
    checked: string,
    visible: string,
  
    respondents: {
      fullName: string,
      fos_capacity: string,
      fos_parentorganisationcapacity: string,
      _parentcustomerid_value: string,
      birthdate: string,
      _accountid_value: string,
      _parent_contactid_value: string,
      fos_fcaid: string,
      emailAddress1: string,
      invitedStatus: string,
      jobtitle: string,
      checked: string,
      visible: string
    }[]
  ) {
    return {
      name,
      fos_fcareference,
      fos_hierarchylevel,
      legalentityname,
      accountNumber,
      accountid,
      parentaccountid_value,
      accountcategorycode,
      businesstypecode,
      fos_approvalstatus,
      checked,
      visible,
      respondents
    };
  }
  createHeadCell(
    name: string,
    fos_fcareference: string,
    legalentityname: string,
    accountid: string,
    checked: string,
    visible: string,
  ) {
    return {
      name,
      fos_fcareference,
      legalentityname,
      accountid,
      checked,
      visible
    };
  }
  descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
    
  }


  getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
      a: { [key in Key]: number | string },
      b: { [key in Key]: number | string }
    ) => number {
  
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  
  stableSort<T>(
    array: T[],
    comparator: (a: T, b: T) => number
  ) {
   
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
  
    return stabilizedThis.map((el) => el[0]);
  }
}

export const filterCount = (tableData: any) => {
  let totalCountList: any = {};
  tableData.map((org: any) => {
    org?.respondents?.map((respon: any) => (
      totalCountList[respon.invitedStatus] = totalCountList[respon.invitedStatus] !== undefined ? totalCountList[respon.invitedStatus] + 1 : 1
    ))
    return tableData;
  })
  return totalCountList
}

export const defaultFilterValues = [
  { flag: false, title: "Pending Acceptance", key: 0 },
  { flag: false, title: "Accepted", key: 1 },
  { flag: false, title: "Not Onboarded", key: 2 },
];



export const staticDrupalForMainPage = [
  {
    "pageTitle": "Onboard respondent admins",
    "pageDescription": "Total {count} respondent business and {count} respondent admins available for onboarding",
    "tableHeading": "Select respondent admins from the businesses listed below, to start onboarding with:",
    "textFieldPlaceholder": "Search for a respondent, business, FCA Phoenix ID",
    "filterBtnLabel": "Filters",
    "proceedBtnLabel": "Proceed to onboarding",
    "resetBtnLabel": "Reset",
    "respondentAdmin": "Respondent admin",
    "businessEmail": "Business email ID",
    "status": "Status",
    "expandAll": "Expand all",
    "collapsAll": "Collapse all",
    "clearBtn": "Clear all",
    "applyBtn": "Apply",
    "successMsg":"You have successfully added respondent business.",
    "addNewBusinessHeading":"Add new respondent business",
    "addNewBusiness":"Add new respondent business",
    "addBusiness":"Add business",
    "cancel":"Cancel",
    "inputLabel":"Enter phenix ID/GUID",
    "search":"Search",
    "phenixID":"Phenix ID",
    "organisationError": "Please enter a valid phoenix ID/ GUID ID",
    "organisationExistError":" This Organisation already exist in Digital Portal",
    "domainName":"Enter domain name",
    "tooltipInfo":"Please enter all the allowed domain name. If there are more than one domain enter in comma seprated format. In case of leaving this field empty will allow all the domains.",
    "domainPlaceholder":"ABC International Bank",
    "checkedCount" :"10",
    "offboardbusinessTitle": "Offoard Respondent Business",
    "offboardbtn" : "Offboard Business",
    "offboardsuccessmsg" : "You have successfully removed the Respondent Business",
    "offboardHeading" : "Offboard Organisation",
    "offboardpopupHeading" :  "Are you sure you wanna continue?",
    "offboardpopupContent" : "You are trying to offboard Respondent organisation which will offboard all its child entities.",
    "proceedOffboard" : "Proceed to Offboard",
    "continueOffboard":"Continue Offboarding",
    "close" :  "Close",
    "field_offboardbusinesstitle": "Offboard Business",
    "checkLimitMessage": "You have selected {count} user for onboard. You cannot onboard more than {count} user at once. Please proceed with onboarding",
  }
]

export const staticOffboard = [
  {
    "offboardbusinessTitle": "Offoard Respondent Business",
    "offboardbtn" : "Offboard Business",
    "offboardSuccessMsg" : "You have successfully removed the Respondent Business",
    "offboardHeading" : "Offboard Organisation",
    "close" :  "Close",
    "proceedOffboard" : "Proceed to Offboard",
    "textFieldPlaceholder": "Search for a respondent, business, FCA/ Phoenix ID",
    "inputLabel": "Enter phoenix ID/GUID/Business name",
    "cancel": "Cancel",
    "search": "Search"
  }
]

export const headeCells = [
  {
    keyIndex: "headeCell0",
    label: "Business group/team name",
    value: "name",
    numeric: false,
  },
  {
    keyIndex: "headeCell1",
    label: "FCA ID",
    value: "fos_fcareference",
    numeric: true,
  },
  {
    keyIndex: "headeCell2",
    label: "Legal entity name",
    value: "legalentityname",
    numeric: false,
  },
  {
    keyIndex: "headeCell3",
    label: "Phoenix ID",
    value: "accountid",
    numeric: true,
  },
];

export const splitText = (updatedPageDescription: any) => {
  if ((typeof (updatedPageDescription) !== 'string' && typeof (updatedPageDescription) !== 'undefined'))
    return true
}

export const filterAdminData = (checkedStatusFilter: any, data1: any) => {
  let result = checkedStatusFilter.filter(
    (checkFlag: any) => checkFlag.flag === true
  );
  const statusData: any[] = [];
  let filterAppliedTitle: string[] = [];
  for (let data of result) {
    filterAppliedTitle.push(data.title);
  }

  const filterData = checkFilterData(data1, filterAppliedTitle);
  
  filterData.forEach((val) => {
    if (val.visible === "true") {
      statusData.push(val);
    }
  });
  return {statusData,  filterData};
}


const checkFilterData = (data1:any, filterAppliedTitle:any,) => {
  let c= 0;
  const dataFiltered: any[] = [];
  data1.forEach((rowsDataLocal: any, _index: number) => {
    let localRespondents = rowsDataLocal.respondents;
    if (localRespondents && localRespondents.length > 0) {
      rowsDataLocal.respondents.forEach((res: any, ind: number) => {
        if (filterAppliedTitle.includes(res.invitedStatus))
          rowsDataLocal.respondents[ind].visible = "true";
        else {
          c++;
          rowsDataLocal.respondents[ind].visible = "false";
        }
        
        if (ind === rowsDataLocal.respondents.length - 1) {
          if (c === rowsDataLocal.respondents.length) {
            c = 0;
            rowsDataLocal.visible = "false";
            dataFiltered.push(rowsDataLocal);
          } else {
            c = 0;
            rowsDataLocal.visible = "true";
            dataFiltered.push(rowsDataLocal);
          }
        }
      });
    } else {
      rowsDataLocal.visible = "false";
      dataFiltered.push(rowsDataLocal);
    }
    
  });
  return dataFiltered;
}

export const previewPageData = [
  {
    "pageTitle": "Financial Ombudsman Portal -Preview: Respondent admins invitations",
    "pageDescription": "Total {count} respondent admins from {count} business respondents selected for onboarding",
    "pageInfo": "To make changes in the selected respondent organisations/admins, use Modify option to edit the list of admins",
    "phoenixId": "Phoenix ID",
    "legalEntity": "Legal entity name",
    "fcaRef": "FCA ref ID",
    "selectRespondent": "Selected respondent admin",
    "businessEmail": "Business email ID",
    "proceedBtnLabel": "Proceed to onboarding",
    "resetBtnLabel": "Reset",
    "cancelHeader": "Are you sure you want to leave ?",
    "cancelText": "All your selections of respondent admins for onboarding will be lost, if you cancel without completing the respondent onboarding process.",
    "clearBtn": "Discard selection",
    "applyBtn": "Continue onboarding",
    "email": 'Email ID is invalid',
    "userExist" :'User is alredy a member of business entity',
    "failure":'Something went wrong',
    "modifyBtn": "Modify selections"
  }
]

export const staticDrupalData = [
  {
    "pageTitle": "Onboarding invitation sent.",
    "pageDescription": "You have sent the onboarding invitations to the selected business respondents. Check back later on the status of the invites.",
    "pageInfo": "Onboarding invitations sent in this session:",
    "phoenixId": "Respondents from {count} out of {count} organisation(s).",
    "legalEntity": "Legal entity name",
    "proceedBtnLabel": "Back to onboarding screen"
  }
]

export const staticCookiesData = [
  {
    "cookiesHeader": "Our use of cookies",
    "subHeader": "We use cookies to help us improve our portal. This will set a cookie on your device to remember your preferences.",
    "cookiesLink": "https:www.financial-ombudsman.org.uk/cookie-preferences",
    "subHeaderText": "Read more about how we use cookies on our ",
    "policyPage": "cookie policy page",
    "manageButton": "Manage preferences",
    "proceedButton": "Accept and proceed",
    "popupHeading": "Cookie preferences",
    "popupSubHeading": "You can manage and change your cookies preferences at any time. Please check your cookie preferences below and turn on any cookies you\u2019re happy with.",
    "popupSubHeadingText": "Our website uses three types of cookies. Necessary cookies are on by default and are needed to make our website work. These can be turned off in your browser settings, but blocking these cookies may mean you can\u2019t use all the services or features on our sites. Functional and analytics cookies are optional, but having these cookies switched off can affect the way that you experience our website and turning these cookies on can help us to improve our website.",
    "necessaryCookies": "Necessary cookies",
    "necessaryCookiesText": "These cookies are necessary to make our website work correctly.They enable you to access",
    "functionalCookies": "Functional cookies",
    "functionalCookiesText": "These cookies support the performance and function of our website to provide a more personalised online experience.",
    "analyticalCookies": "Analytical cookies",
    "analyticalCookiesText": "These cookies allow us to collect and run analytics and testing tools, which help us understand if we are meeting our users\u2019 needs.",
    "acceptButton": "Accept all",
    "saveButton": "Save and proceed"
  }
]

export const staticDrupalForBreadcrumbs = [
  {
    "mainRouteAdmin": "Initiate onboarding for respondent admins",
    "mainRouteNonAdmin": "Initiate onboarding for non-admin respondents",
    "manageUser": "Manage user",
    "viewCase": "View case",
    "viewCaseDetails": "View case details",
    "childRoutePreviewAdmin": "Preview respondents",
    "childRoutePreviewNonAdmin": "Preview non-admin respondents",
    "home": "Home",
    "dashboard": "Your dashboard",
    "profile": "Profile",
    "logout": "Logout",
    "uploadDocument": "Upload documents",
    "trackUpload": "Track upload file status",
    "caseDetail": "Case details",
    "manageProfile": "Manage user profile",
  }
]

