import React,{ useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import FOSLOGO from '../../assets/icons/FOS_logo_landscape_RGB.svg';
import Icon_Chevron_Down from '../../assets/icons/Icon_Chevron_Down.svg';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import './navbar.css';
import { useStyles, setHeaderRole } from './NavbarStyle';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import Logout from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import { useMsal } from '@azure/msal-react';
import { DRUPALSERVER } from '../../common/drupal-api/drupal-api-urls';
import DrupalService from '../../common/drupal-api/drupal-api-service';
import Icon_Profile from '../../assets/icons/Icon_Profile.svg';
import DownIcon from '../../assets/icons/Icon_Cevron_Down.svg';
import NotificationIcon from '../../assets/icons/Notification-icon-header.svg'
import SkipLink from '../../common/component-lib/SkipLink';
import { useDispatch } from "react-redux";
import {Dispatch} from "redux";
import { clearFiles } from '../../stores/uploadFiles/UploadSlice';
import { clearPercentages } from '../../stores/uploadFiles/UploadProgressSlice';
import { SERVER } from '../../common/api/api-urls';
import CommonService from '../../common/api/common-api-service';
import { errorComponentToast, drupalForErrors } from '../../common/ErrorComponent/ErrorComponent';
import { clearExtractedZip } from '../../stores/uploadFiles/UploadZipSlice';
import { updateBlobCount } from '../../stores/uploadFiles/UploadStatusSlice';
import { userDetails } from '../../stores/userDetails/UserDetailsSlice';
import { featureFlag } from '../../stores/featureflag/FeatureFlagSlice';
import IconCross from "../../assets/icons/Icon_Cross.svg";
import NotificationPage from './NotificationPage';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment'

const useStateCallbackModule = require('use-state-callback');
const { useStateCallback } = useStateCallbackModule;

const defaultDrupalDataNotification =[
{
    "notification_all": "See All Notifications",
    "marksAsRead": "Mark as Read",
    "deleteNotification": "Delete",
    "downloadnotification": "the file should be available for download till the mid-night of the same day. up to 24 hrs from the time it is first available for download",
    "notification_title": "Notification",
    "notification_viewall": "View all",
    "notification_selectall": "Select all",
    "notification_fileready": "Your files are ready to be downloaded",
    "notification_read": "Read",
    "notification_unread": "Unread",
    "notification_readMore": "Read more...",
    "notification_delete_selected": "Delete Selected",
    "notification_new": "New notification",
    "Notification_available": "We are getting your files ready for download. You can download them from Available for download in Case details",
    "notification_selected": "Marks selected as read.",
    "caseExport": "Case list exported file is ready for download, please navigate to viewcaselist menu and click on available for download to download the file",
    "caseDownload":"Case files are available for download for PNX",
    "caseOnboarding":"Account onboarded",
    "caseOffboarding":"Account offboarded",
    "title":"New Notification",
     "description":"We are getting your files ready for download. You can download them from Available for download in Case details.",
     "close_button":'Close',
    "caseExportDescription": "Please click on Available for Download link on View Case List screen.",
    "caseDownloadDescription":"Please click on Available for Download link on View Case Details screen.",
    "caseOnboardingDescription":"Please view onboarded organisation in intiate onboarding screen",
    "caseOffboardingDescription":"Organisation is no longer available in initiate onboarding screen",
    "caseExportFail": "Case list exported file is failed for download",
"caseDownloadFail":"Case files are failed for download for PNX",
"caseOnboardingFail":"Respondent Organisation Name onboarding failed",
"caseOffboardingFail":"Respondent Organisation Name offboarding failed",
"caseExportDescriptionFail": "Sorry for the inconvenience caused.",
"caseDownloadDescriptionFail":"Sorry for the inconvenience caused.",
"caseOnboardingDescriptionFail":"Sorry for the inconvenience caused.",
"caseOffboardingDescriptionFail":"Sorry for the inconvenience caused."

}
]

const commonApi = new CommonService();

export default function Navbar(prop: any) {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const classes = useStyles();
    const dispatch:Dispatch<any> = useDispatch();
    // eslint-disable-next-line
    const [_drupalDataLogo, setDrupalDataLogo] = useState<any | null>(FOSLOGO);
    const { accounts, instance } = useMsal();
    const [userName, setUserName] = useState('User');
    const [roleName, setRoleName] = useState('Administrator');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [roleArray, setRoleArray] = useStateCallback([]);
    const [drupalData, setDrupalData] = useState<string[]>([]);
    const [drupalDataMenu, setDrupalDataMenu] = useState<string[]>([]);
    const [roleHeader, setRoleHeader] = useState(0);
    const drupalApi = new DrupalService();
    const [errorFromDrupal] = useState(drupalForErrors);
    const [drupalDataNotification,setDrupalDataNotification] = useState<any>(defaultDrupalDataNotification)
    const [latestNotifications,setLatestNotifications] = useState<any>([])
    const [selectedNotification,setSelectedNotification] = useState<any>([])
    const [notificationCount,setNotificationCount] = useState<number>(0)
    const [notificationTimer,setNotificationTimer] = useState<number>(5)
    const fisrtElementRef = useRef(null);
    const lastElementRef = useRef(null);
    const notificationButtonRef = useRef(null);
    const closeButtonRef = useRef(null);
    const dialogRef = useRef(null);
    const [anchorElNotif, setAnchorElNotif] = React.useState<null | HTMLElement>(null);
    let openNotif = Boolean(anchorElNotif);
    
    const [showNotification,setShowNotification] = useState<boolean>(true)

    const capitalizeFirstLetter = (str:string) =>{
		return str.charAt(0).toUpperCase() + str.slice(1);
	}
    const fetchNotificationCount = () =>{
        commonApi
        .httpConnection(SERVER.SERVICES.getUserNotificationCount, 'get')
        .then((res) => {
            if(res){
                setNotificationCount(res?.notificationCount)
            }
        })
        .catch((e) => {
            console.warn(e);
        });
    }

    useEffect(() =>{
        if(roleArray?.length===1 && roleArray.includes('OmbudsmanAdmin')){
            setShowNotification(false)
        }
    },[roleArray])

    useEffect(()=>{
        fetchNotificationCount();
        const timerInMilliSeconds = (notificationTimer*60)*1000 
        const intervalId = setInterval(fetchNotificationCount,timerInMilliSeconds)
        return ()=>clearInterval(intervalId)
        },[])


    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        const checkRoleArray = roleArray.includes('RespondentNonAdmin') ||
            roleArray.includes('CaseWorker') ||
            roleArray.includes('Supervisor');

        const checkRoleArray1 = !(checkRoleArray) && roleArray.includes('RespondentAdmin');
        const checkRoleArray2 = !(checkRoleArray) &&
            !roleArray.includes('RespondentAdmin') &&
            roleArray.includes('OmbudsmanAdmin')

        commonApi
        .httpConnection(SERVER.SERVICES.getUserProfileDetails, 'get')
        .then((res) => {
            dispatch(userDetails(res))
            if(res){
                if(res.firstname && res.lastname){
                    let name = capitalizeFirstLetter(res.firstname)+" "+capitalizeFirstLetter(res.lastname);
                    setUserName(name);
                } else if(res.firstname){
                    setUserName(capitalizeFirstLetter(res.firstname));
                } else if(res.lastname){
                    setUserName(capitalizeFirstLetter(res.lastname));
                } else{
                    setUserName("User");
                }
            }
        })
        .catch((e) => {
            errorComponentToast(e.data, errorFromDrupal[0])
        });
        if (
            accounts &&
            accounts.length > 0 &&
            accounts[0].idTokenClaims &&
            accounts[0].idTokenClaims.roles &&
            accounts[0].idTokenClaims.roles.length > 0
        ) {
            let str = accounts[0].idTokenClaims.roles[0];
            let headerRole = accounts[0].idTokenClaims.roles;
            const setRole = setHeaderRole(headerRole)
            setRoleName(setRole)
            setRoleName(str.replace(/([A-Z])/g, ' $1').trim());
            setRoleArray([...headerRole]);
         

            if (checkRoleArray) {
                setRoleHeader(1);
            } else if (
                checkRoleArray1
            ) {
                setRoleHeader(2);
            } else if (
                checkRoleArray2
            ) {
                setRoleHeader(3);
            } else {
                setRoleHeader(1);
            }
        } else {
            setRoleName('Respondent Non Admin');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        staticDrupal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleArray]);
    useEffect(() => {
        getHeaderDrupalData();
        getHeaderDrupalMenuData();
        getFeatureFlagData();
        getNotificationDrupalData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const getFeatureFlagData = () => {
        drupalApi
      .httpConnection(DRUPALSERVER.SERVICES.getFeatureFlags, "get")
      .then((res: any) => {
        dispatch(featureFlag(res));
      })
      .catch((e:any)=>{
      })
    }


    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();
    const handlelogout = (event: { preventDefault: () => void }) => {
        event.preventDefault();
        localStorage.setItem('tabsOpen','0');
        commonApi.httpConnection(SERVER.SERVICES.logoutRequest, 'get')
        .then(res=>{
            const account = instance.getAllAccounts()[0];
            const valueLogout = instance.getAccountByHomeId(account.homeAccountId);
            sessionStorage.clear();
            localStorage.removeItem('ACT');
            localStorage.setItem('tabsOpen','0');
            localStorage.removeItem('tabsOpen');
            instance.logout({ account: valueLogout });
            navigate('/logout');
        })
        

    };
    function getHeaderDrupalData() {
        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getHeaderMain, 'get')
            .then((res: any) => {
                setDrupalDataLogo(res[0] ? res[0].field_header_logo : FOSLOGO);
            })
            .catch(() => {
                setDrupalDataLogo(FOSLOGO);
            });
    }

    function getNotificationTimerDrupalData(){
        drupalApi
          .httpConnection(DRUPALSERVER.SERVICES.getNotificationTimer, 'get')
          .then((res) => {
              if (res.length > 0) {
                setNotificationTimer(parseInt(res[0]?.timer_in_minutes));
              } else {
                setNotificationTimer(5);
              }
          })
          .catch((_e: any) => {
            setNotificationTimer(5);
          });
    }

    function getNotificationDrupalData(){
        drupalApi
          .httpConnection(DRUPALSERVER.SERVICES.getNotificationPage, 'get')
          .then((res) => {
              if (res.length > 0) {
                setDrupalDataNotification(res);
              } else {
                setDrupalDataNotification(defaultDrupalDataNotification);
              }
          })
          .catch((_e: any) => {
            setDrupalDataNotification(defaultDrupalDataNotification);
          });
    }

    const newHeaderLabel = (dataDrupal: any) => {

        let drupalList: any = [];
        let headMenu: any = [];
        dataDrupal.forEach((_obj: any, i: number) => {
            if (roleArray.includes(dataDrupal[i].role)) {
                drupalList.push(dataDrupal[i].header_menu.split(','));
            } else if (
                accounts &&
                accounts.length > 0 &&
                accounts[0].idTokenClaims &&
                accounts[0].idTokenClaims.roles &&
                accounts[0].idTokenClaims.roles.length > 0
            ) {
                let headerRole = accounts[0].idTokenClaims.roles;
                if (headerRole.includes(dataDrupal[i].role)) {
                    drupalList.push(dataDrupal[i].header_menu.split(','));
                }
            }
            headMenu.push(dataDrupal[i].header_menu.split(','));
        });
        const headerLabel = drupalList
            .flat(1)
            .filter((x: any, i: any, a: any) => a.indexOf(x) === i);
        const headerMenu = headMenu.flat(1).filter((x: any, i: any, a: any) => a.indexOf(x) === i);
        return [headerLabel, headerMenu]
    };

    const staticDrupal = () => {
        const dataDrupal = [
            { role: 'CaseWorker', header_menu: 'Dashboard, View case list, Track upload' },
            { role: 'Supervisor', header_menu: 'Dashboard, View case list, Track upload' },
            {
                role: 'RespondentAdmin',
                header_menu: 'Dashboard, Onboarding respodents, Manage users'
            },
            { role: 'RespondentNonAdmin', header_menu: 'Dashboard, View case list, Track upload' },
            {
                role: 'OmbudsmanAdmin',
                header_menu: 'Dashboard, Initiate onboarding, Manage users'
            }
        ];
        const headerLabel = newHeaderLabel(dataDrupal)
        setDrupalData(headerLabel[0]);
        setDrupalDataMenu(headerLabel[1]);
    };

    function getHeaderDrupalMenuData() {
        staticDrupal();
        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getHeaderMenu, 'get')
            .then((res: any) => {
                if (res && res.length > 0) {
                    const headerLabel = newHeaderLabel(res)
                    setDrupalData(headerLabel[0]);
                    setDrupalDataMenu(headerLabel[1]);

                } else {
                    staticDrupal();
                }
            })
            .catch(() => {
                staticDrupal();
            });
    }

    const listener = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            console.log('Enter key was pressed. Run your function.');
            setAnchorEl(event.currentTarget);
        }
    };

    type ABC = { isActive: any };
    const navLinkStyles = ({ isActive }: ABC) => {
        return {
            fontWeight: isActive ? 'bold' : 'normal',
            color: isActive ? '#D22D64' : '#2B2A6D',
            //textDecoration: isActive ? "underline" : "none",
            fontSize: '1.375rem',
            fontFamily: 'AssistantSemiBold',
            paddingBottom: isActive ? '8px' : '0px',
            borderBottomStyle: isActive && 'solid',
            borderBottomWidth: isActive ? '3.1px' : '0px',
            width: isActive && 'inherit'
        };
    };
    const navLinkStyles1 = ({ isActive }: ABC) => {
        return {
            fontWeight: 'bold',
            color: '#D22D64',
            fontSize: '1.375rem',
            fontFamily: 'AssistantSemiBold',
            paddingBottom: '8px',
            borderBottomStyle: 'solid',
            borderBottomWidth: '3.1px',
            width: 'inherit'
        };
    };


    const { pathname } = useLocation();
    const includedPath = pathname.includes('/ombudsman-dashboard') || pathname.includes('/signin');

    useEffect(()=>{
        if(!(pathname.includes('/view-cases-upload') || pathname.includes('/view-cases-upload-preview'))){
            dispatch(clearFiles());
            dispatch(clearExtractedZip())
            dispatch(clearPercentages());
            dispatch(updateBlobCount(0));
        }
    },[pathname]);
    const notificationHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNotif(event.currentTarget);
    setTimeout(() =>{fisrtElementRef.current.focus()},0);
    commonApi
      .httpConnection(SERVER.SERVICES.getLatestUserNotifications, 'get')
      .then((res) => {
          if(res?.length>0){
              let formedMsg = ""
              let drupalMsg = ""
              res.map((eachItem,index) =>{
                  switch(eachItem.requesting_activity_name){
                      case "caseExport":
                          if(res[index].notification_status_id==2 ||res[index].notification_status_id==7 ){ 
                        formedMsg=drupalDataNotification[0]?.caseExport;
                        }else if(res[index].notification_status_id==5 || res[index].notification_status_id==8){
                            formedMsg=drupalDataNotification[0]?.caseExportFail
                        }else{
                            formedMsg=drupalDataNotification[0]?.caseExport;
                        }
                        res[index].formedMsg = formedMsg
                        break
                        case "caseDownload":
                        if(res[index].notification_status_id==2 || res[index].notification_status_id==7 ){ 
                        drupalMsg = drupalDataNotification[0]?.caseDownload
                        } else if(res[index].notification_status_id==5 || res[index].notification_status_id==8){
                            drupalMsg = drupalDataNotification[0]?.caseDownloadFail
                        }else{
                            drupalMsg = drupalDataNotification[0]?.caseDownload
                        }
                        drupalMsg = drupalMsg.replace("PNX",res[index].message)
                        formedMsg=drupalMsg;
                        res[index].formedMsg = formedMsg
                        break
                        case "caseOnboarding":
                            if(res[index].notification_status_id==2 || res[index].notification_status_id==7 ){ 
                                drupalMsg = drupalDataNotification[0]?.caseOnboarding
                            } else if(res[index].notification_status_id==5 || res[index].notification_status_id==8){
                                drupalMsg = drupalDataNotification[0]?.caseOnboardingFail
                            }else{
                                drupalMsg = drupalDataNotification[0]?.caseOnboarding
                            }
                            drupalMsg = drupalMsg.replace("Name",res[index].message)
                            formedMsg=drupalMsg;
                        res[index].formedMsg = formedMsg
                        break
                        case "caseOffboarding":
                            if(res[index].notification_status_id==2 || res[index].notification_status_id==7 ){ 
                             drupalMsg = drupalDataNotification[0]?.caseOffboarding
                            } else if(res[index].notification_status_id==5 || res[index].notification_status_id==8 ){
                                drupalMsg = drupalDataNotification[0]?.caseOffboardingFail
                            }else{
                                drupalMsg = drupalDataNotification[0]?.caseOffboarding
                            }
                            drupalMsg = drupalMsg.replace("Name",res[index].message)
                            formedMsg=drupalMsg;
                        res[index].formedMsg = formedMsg
                        break
                        default:
                            res[index].formedMsg= res[index].message
                  }
              })
            setLatestNotifications(res)
            }
        else{
            setLatestNotifications([])
        }
      })
      .catch((e) => {
        console.warn(e);
        setLatestNotifications([])
      });
  };

  const handleNotif =() =>{
    //window.open('/notification')
    //window.location.href='/notification'
    handleCloseNotif()
    navigate('/notification')
  }

    const PopupBody = styled('div')(({ theme }) => `
  overflow: hidden;
  width: 467px;
  max-height: 495px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #CFCFCF;
  border-radius: 8px;
  opacity: 1
    padding: 12px 16px;
    margin: 8px;
    z-index: 1;
  `,
    );

    const handleCheckboxChange=(event:React.ChangeEvent<HTMLInputElement>,item:any)=>{
        const checked = event.target.checked
        checked? setSelectedNotification(prevSelectedNotif =>{
            return  [...prevSelectedNotif,item]
        }): setSelectedNotification(prevSelectedNotif =>{
            const updatedList = prevSelectedNotif.filter(eachItem =>{
                return eachItem.notification_id!==item.notification_id
            })
            return updatedList
        })
    }

    const handleMarkAsRead=(item:any)=>{
        // const tempMsg = item.formedMsg;
        // delete item.formedMsg
        const payload = {
            "notification_id": item.notification_id,    
            "notification_status_id": item.notification_status_id,
            "notification_status_description": item.notification_status_description
        }

        commonApi
      .httpConnection(SERVER.SERVICES.updateSelectedNotificationAsRead, 'post',payload)
      .then((res) => {
          if(res?.result){
              setLatestNotifications((prevNotif) =>{
                //   const updatedNotifications = prevNotif.map(eachObj => {
                //       if(eachObj.notification_id==item.notification_id){
                //           return {...eachObj,notification_status_id:"3",formedMsg:tempMsg}
                //       }
                //       return eachObj
                //   })
                const updatedNotifications = prevNotif.filter(eachObj => {
                    return (eachObj.notification_id!=item.notification_id)     
                })
                  return updatedNotifications
              })
              setSelectedNotification([])
            }
      })
      .catch((e) => {
        console.warn(e);
      });

    }
    const handleMarkSelectedAsRead=()=>{
        //let tempList = []
        const payload =selectedNotification.map(eachItem =>{
            // tempList.push({notification_id:eachItem.notification_id,formedMsg:eachItem.formedMsg})
            // delete eachItem.formedMsg
            return {
                "notification_id": eachItem.notification_id,    
                "notification_status_id": eachItem.notification_status_id,
                "notification_status_description": eachItem.notification_status_description
            }
        })
        commonApi
      .httpConnection(SERVER.SERVICES.updateLatestUserNotificationsAsRead, 'post',payload)
      .then((res) => {
          if(res?.result){
            setLatestNotifications((prevNotif) =>{
                // const updatedNotifications = prevNotif.map(eachObj => {
                //     if(selectedNotification.some(obj => obj.notification_id==eachObj.notification_id)){
                //         const obj = tempList.find(obj => obj.notification_id ==eachObj.notification_id)
                //         return {...eachObj,notification_status_id:"3",formedMsg:obj?.formedMsg,formedDesc:obj?.formedDesc}
                //     }
                //     return eachObj
                // })
                const updatedNotifications = prevNotif.filter(eachObj => {
                     return !selectedNotification.some(obj => obj.notification_id==eachObj.notification_id)
                })
                return updatedNotifications
            })
            setSelectedNotification([])
            }
      })
      .catch((e) => {
        setSelectedNotification([])
        console.warn(e);
      });
        
    }
    const handleSelectAllChange=(event:React.ChangeEvent<HTMLInputElement>)=>{
        const checked = event.target.checked;
        const updatedItems = latestNotifications.map(item=>(item))
        checked?setSelectedNotification(updatedItems):setSelectedNotification([]);
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Escape'){
            handleCloseNotif();
        }else if(event.key === 'Tab'){
            if(event.shiftKey){
                if((document.activeElement === closeButtonRef.current) ){
                    event.preventDefault();
                    fisrtElementRef.current.focus();
                }else if(document.activeElement === fisrtElementRef.current){
                    event.preventDefault();
                    lastElementRef.current.focus();
                }
            }else{
                if(document.activeElement === lastElementRef.current){
                    event.preventDefault();
                    fisrtElementRef.current.focus();
                }
            }
        }
    };
    

    const handleNotifExpand = (e: any) => {
        if (e.key === 'Enter') {
            setAnchorElNotif(null);
            setTimeout(() =>{notificationButtonRef.current.focus()},100);
        }
    }
    const handleCloseNotif = () => {
        setAnchorElNotif(null);
        notificationButtonRef.current.focus();
      };
    
      const isChecked = () =>{
          if(latestNotifications?.length>0){
           return latestNotifications.every(latestNotif => selectedNotification.some(selectedNotif =>selectedNotif.notification_id==latestNotif.notification_id))
          }
          return false
        }

    const boldPNX=(name:string)=>{
        const indexPNX = name.indexOf('PNX');
        if(indexPNX !== -1){
            const beforePNX = name.slice(0, indexPNX)
            const boldPart = name.slice(indexPNX, indexPNX + 15);
            return(
                <>
                {beforePNX}<strong style={{display:'inline'}}>{boldPart}</strong>{name.slice(indexPNX + 15)}
                </>
            )

        } else{
            return name;
        }
    }
    return (
        <>
        <nav className={classes.navigation}>
            <SkipLink skipTo={'#firstNavLink'} className="skipButton skip-link">
                <button type="button">Skip to navigation links</button>
            </SkipLink>
            <SkipLink skipTo={'#main-content'} className="skipButton skip-link">
                <button type="button" >Skip to main content</button>
            </SkipLink>
            <SkipLink skipTo={'#footer'} className="skipButton skip-link">
                <button type="button">Skip to footer</button>
            </SkipLink>
            <div className='logo'>
                <img
                    tabIndex={0}
                    className={classes.logoClass}
                    src={FOSLOGO}
                    alt='Financial Ombudsman Logo'
                    height='50'
                    onClick={() => navigate('/ombudsman-dashboard')}
                    onKeyDown={(e)=>{
                        if(e.code==='Enter' || e.code==='Space'){
                            navigate('/ombudsman-dashboard');
                        }
                    }}
                />
            </div>
            <div
                className={classes.hamburger}
                tabIndex={0}
                onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                }}
            >
                Menu
                <img src={Icon_Chevron_Down} alt='' />
            </div>
            <div className={isNavExpanded ? 'navigationMenu expanded' : 'navigationMenu'}>
                <ul>
                    <li>
                        {roleHeader === 1 && (
                            <NavLink
                                id='firstNavLink'
                                aria-current={'page'}
                                aria-label='dashboard'
                                to='/ombudsman-dashboard'
                                onClick={() => {setIsNavExpanded(false);prop.selected('/ombudsman-dashboard')}}
                                style={includedPath ? navLinkStyles1 : navLinkStyles
                                }
                                className={classes.menuItems}
                            >
                                {drupalDataMenu[0]}
                            </NavLink>
                        )}
                        {roleHeader === 2 && (
                            <NavLink
                                aria-current={'page'}
                                aria-label='dashboard'
                                to='/ombudsman-dashboard'
                                onClick={() =>  {setIsNavExpanded(false);prop.selected('/ombudsman-dashboard')}}
                                style={includedPath ? navLinkStyles1 : navLinkStyles}
                                className={classes.menuItems}
                            >
                                {drupalDataMenu[0]}
                            </NavLink>
                        )}
                        {roleHeader === 3 && (
                            <NavLink
                                aria-current={'page'}
                                aria-label='dashboard'
                                to='/ombudsman-dashboard'
                                onClick={() =>  {setIsNavExpanded(false);prop.selected('/ombudsman-dashboard')}}
                                style={includedPath ? navLinkStyles1 : navLinkStyles}
                                className={classes.menuItems}
                            >
                                {drupalDataMenu[0]}
                            </NavLink>
                        )}
                    </li>
                    {drupalData.includes(drupalDataMenu[5]) && (
                        <li>
                            <NavLink
                                aria-current={'page'}
                                aria-label='initiate onboarding'
                                to='/initiate-onboarding'
                                onClick={() =>  {setIsNavExpanded(false);prop.selected('/initiate-onboarding')}}
                                style={navLinkStyles}
                                className={classes.menuItems}
                            >
                                {drupalDataMenu[5]}

                            </NavLink>
                        </li>
                    )}
                    {drupalData.includes(drupalDataMenu[3]) && (
                        <li>
                            <NavLink
                                aria-current={'page'}
                                aria-label='onboard users tab'
                                to='/non-admin-respondent-table'
                                onClick={() =>  {setIsNavExpanded(false);prop.selected('/non-admin-respondent-table')}}
                                style={navLinkStyles}
                                className={classes.menuItems}
                            >
                                {drupalDataMenu[3]}

                            </NavLink>
                        </li>
                    )}
                    {drupalData.includes(drupalDataMenu[4]) && (
                        <li>
                            <NavLink
                                aria-current={'page'}
                                aria-label='manage user'
                                to='/manage-user'
                                onClick={() =>  {setIsNavExpanded(false);prop.selected('/manage-user')}}
                                style={navLinkStyles}
                                className={classes.menuItems}
                            >
                                {drupalDataMenu[4]}

                            </NavLink>
                        </li>
                    )}
                    {drupalData.includes(drupalDataMenu[1]) && (
                        <li>
                                <NavLink
                                    aria-current={'page'}
                                    aria-label='view case list'
                                    to='/view-cases'
                                    onClick={() =>  {setIsNavExpanded(false);prop.selected('/view-cases')}}
                                    style={navLinkStyles}
                                    className={classes.menuItems}
                                >
                                    {drupalDataMenu[1]}

                                </NavLink>
                           
                        </li>
                    )}
                    {drupalData.includes(drupalDataMenu[2]) && (
                        <li>
                                <NavLink
                                    aria-current={'page'}
                                    aria-label='track uploads'
                                    to='/track-uploads'
                                    onClick={() =>  {setIsNavExpanded(false);prop.selected('/track-uploads')}}
                                    style={navLinkStyles}
                                    className={classes.menuItems}
                                >
                                   {drupalDataMenu[2]}

                                </NavLink>
                        </li>
                    )}
                       
                  

                    
                </ul>
            </div>
            { showNotification &&  <>
                            <div  className={classes.notificationIcon}>
                                <button ref={notificationButtonRef} aria-label="Notifications" aria-haspopup="dialog"  onClick={notificationHandleClick} className={classes.infoClass} 
                                onKeyDown = {(event) => {                                    
                                    if(event.key === 'Enter'){
                                        notificationHandleClick(event);
                                    }  
                                }}
                                id="notificationButton">
                                <img src={NotificationIcon} alt="" id="notificationIcon"/>
                                </button>
                                {notificationCount>0 && <span className={classes.badge}>{notificationCount}</span>}
                            </div>
                            <BasePopup ref={dialogRef} className={classes.notificationPopup}
                                open={openNotif} anchor={anchorElNotif} role="dialog">
                                <PopupBody onKeyDown={handleKeyDown}  className={classes.notificationBody}>
                                    <div className={classes.headingIcon}>
                                        <div className={classes.selectAllButton}>
                                        <input className={classes.chkBox} id={drupalDataNotification[0].notification_selectall}  onChange={handleSelectAllChange} checked={isChecked()}  ref={fisrtElementRef}   type="checkbox" />
                                        <label for={drupalDataNotification[0].notification_selectall}>{drupalDataNotification[0].notification_selectall}</label>
                                        </div>
                                            <p className={classes.notificationHeading}>{drupalDataNotification[0].notification_title}</p>
                                            <img role="button"
                                                aria-label={'close'} title={'close'} tabIndex={0} src={IconCross}
                                                ref={closeButtonRef}
                                                onClick={handleCloseNotif} 
                                                onKeyDown={handleNotifExpand}  alt="" 
                                                
                                                />
                                    </div>
                                    <FormGroup className={classes.notificationContainer}>
                                        {latestNotifications.map((item)=>{
                                            if(item.notification_status_id!="3" && item.notification_status_id!="7" && item.notification_status_id!="8"){
                                            return(
                                            <>
                                            <div 
                                            style={{
                                                display: 'flex',
                                                flexDirection:'column',
                                                alignItems:'flex-start',
                                                borderBottom:'1px solid #CECECE',
                                                backgroundColor: item.notification_status_id=="3"||item.notification_status_id=="7" ||item.notification_status_id=="8" ? '#FFFFFF': '#F0F8FF',
                                            }}>
                                                <FormControlLabel
                                                key={item.notification_id}
                                                className={classes.notificationText}
                                                control={<Checkbox checked={selectedNotification.some(eachItem => eachItem.notification_id==item.notification_id)} onChange={(event)=> handleCheckboxChange(event,item)} />}
                                                label={item.formedMsg} />
                                                <div style={{display:'flex', justifyContent:"space-around", width:"100%"} }>
                                                    <p>{moment.utc(item.created_on).local().fromNow()}</p>
                                                        <Button sx={{textAlign: 'left',
                                                        font: 'normal normal 600 1.125rem AssistantRegular',
                                                        letterSpacing: 0,
                                                        textTransform: 'none',
                                                        color: '#271C6F'}} onClick={()=> handleMarkAsRead(item)}
                                                        disabled={item.notification_status_id=="3" ||item.notification_status_id=="7" }>{drupalDataNotification[0].marksAsRead}</Button>
                                                </div>
                                                              
                                            </div>
                                            </>
                                            )
                                        }}
                                        )}
                                    </FormGroup>
                                    
                                    <div className={classes.headingIcon}>
                                        <Button sx={{textTransform:'none'}} className={classes.viewAllButton} onClick ={handleNotif}>{drupalDataNotification[0].notification_viewall}</Button>
                                        <Button ref={lastElementRef} sx={{textTransform:'none'}} className={classes.markAllButton} onClick={()=>handleMarkSelectedAsRead()} aria-disabled={latestNotifications?.length==0}>{drupalDataNotification[0].marksAsRead}</Button>
                                    </div>
                                </PopupBody>
                            </BasePopup>
                        </>
                        } 
                  

                    <Box    onClick={handleClick}  className={classes.persona}>
                        <Tooltip title='Account settings' describeChild>
                            <IconButton
                                disableRipple
                                tabIndex={0}
                                sx={{
                                    p: 0,
                                    '&:focus-visible': {
                                        boxShadow: '0 0 0 2px black',
                                        borderRadius: '4px'
                                    }
                                }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup='true'
                                aria-expanded={open ? true : false}
                            >
                                <img
                                    src={Icon_Profile}
                                    alt=''
                                    style={{
                                        width: '3rem',
                                        height: '3rem',
                                        cursor: 'default'
                                    }}
                                />
                                <span className={classes.avatarName}  onKeyDown={listener}>
                                    <span data-hj-suppress className={classes.text}>{userName}</span>
                                    <img
                                        src={DownIcon}
                                        alt=''
                                        className={classes.userDownIcon}
                                    />
                                    <br />
                                    <span className={classes.userRole}>{roleName}</span>
                                </span>
                            </IconButton>
                        </Tooltip>
                    </Box>
            <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    tabIndex={0}
                    aria-selected={pathname.includes('/manage-user-profile')}
                    onClick={() => {
                        navigate('/manage-user-profile');
                    }}
                >
                    <Avatar /> {prop.profile}
                </MenuItem>

                <Divider />
                <MenuItem tabIndex={0} aria-current={'page'} onClick={handlelogout}>
                    <ListItemIcon>
                        <Logout fontSize='small' />
                    </ListItemIcon>
                    <Button disableRipple name='Logout' className={classes.logoutClass} >
                    {prop.logout}
                    </Button>
                </MenuItem>
            </Menu>
        </nav>
        </>
    );
}
