import { createSlice } from "@reduxjs/toolkit";

interface ZipType {
    extractedZipFiles: []
  
}

const initialState:ZipType ={
    extractedZipFiles: []
}

const UploadZipSlice = createSlice({
    name: 'uploadZipSlice',
    initialState,
    reducers: {
        addExtractedZip: (state:any, action:any) => {
            state.extractedZipFiles.push(action.payload);
        },
        removeExtractedZip: (state:any, action) =>{
            let temp = state.extractedZipFiles.filter((file)=>file.fileName!==action.payload)
            state.extractedZipFiles = temp;
        },
        clearExtractedZip: (state)=>{
            state.extractedZipFiles = []
        }


      
    }
});

export const { addExtractedZip, removeExtractedZip, clearExtractedZip} = UploadZipSlice.actions;
export default UploadZipSlice.reducer;