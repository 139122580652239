import { createSlice } from "@reduxjs/toolkit";

interface StatusType {
    uploadWaitStatus: boolean,
    blobCount: number
}

const initialState:StatusType ={
    uploadWaitStatus: false,
    blobCount: 0
}

const UploadStatusSlice = createSlice({
    name: 'uploadStatusSlice',
    initialState,
    reducers: {
        updateWaitStatus: (state:any,action)=>{
            state.uploadWaitStatus = action.payload;
        },
        updateBlobCount: (state:any,action)=>{
            if(action.payload===0) state.blobCount = 0;
            else {
                state.blobCount = state.blobCount + action.payload;
            }
        }
    }
});

export const {updateWaitStatus, updateBlobCount} = UploadStatusSlice.actions;
export default UploadStatusSlice.reducer;