import {useState, useEffect} from 'react'
import {makeStyles} from '@mui/styles';
import expand from '../../assets/icons/Expand_Icon_All.svg';
import download from '../../assets/icons/download_Pdf.svg';
import check from '../../assets/icons/Check.svg';
import cancel from '../../assets/icons/Cancel.svg';
import inprogress from '../../assets/icons/inprogress.svg'
import moment from 'moment';
import { DRUPALSERVER } from '../../common/drupal-api/drupal-api-urls';
import DrupalService from '../../common/drupal-api/drupal-api-service';


const DefaultDrupalData = [
    {
        "title":"Track upload file status",
        "description":"You can see and work on all the cases which are tagged to your organization here.",
        "caseNo":"Case Reference No.",
        "dateTime":"Date \u0026amp; time of submission",
        "status":"Upload status",
        "collapse":"Collapse all",
        "uploadfiles":"files",
        "virusInProgress":"Virus scan in progress",
        "virusComplete":"Virus scan completed",
        "inProgress":"In progress",
        "docName":"Document Name",
        "scanStatus":"Virus scan status",
        "uploadclean":"Passed",
        "infected":"Infected",
        "uploadPending":"In progress",
        "uploadCompleted":"Uploaded",
        "uploadFailed":"Failed",
        "proceedBtn":"Proceed to upload clean files",
        "uploadAll":"Upload all files again",
        "uploadNA":"Not applicable"
    }
];

interface TrackUploadIndividual {
    fileStatus : any;
    allExpanded: any;
}

const drupalApi = new DrupalService();
const IndividualUploadStatus = ({fileStatus, allExpanded} : TrackUploadIndividual) => {
    let momentObj = moment(fileStatus?.created);
    let momentString = momentObj.format("DD MMMM, YYYY HH:mm:ss");
    const classes = useStyles();
    const [Expanded, setExpanded] = useState<boolean>(false);
    const [drupalData, setDrupalData] = useState<any>(DefaultDrupalData);
    const expandUpload = () => {
        setExpanded(!Expanded);
    }
    const collapsFunctionImg = Expanded ? (
        <img
          alt="collapse upload details"
          src={expand}
          className={classes.collapseBtn}
        />
      ) : (
        <img
          alt="expand upload details"
          src={expand}
        />
      )

    useEffect(() => {
        setExpanded(allExpanded)
     }, [allExpanded])

     useEffect(() => {
        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getTrackUploadPage, 'get')
            .then((res) => {
                if (res.length > 0) {
                    setDrupalData(res);
                } else {
                    setDrupalData(DefaultDrupalData);
                }
            })
            .catch((_e: any) => {
                setDrupalData(DefaultDrupalData);
            });
    }, []);
    let clean = 0 
    let infected = 0
    let inProgress = 0
    let uploadDone = 0
    let uploadFail = 0
    let virusScan = 0
    fileStatus.files.map((file:any)=>{
        if (file.status?.toLowerCase() === 'passed' || file.status?.toLowerCase() === 'clean'){
            clean+=1
        }
        else if(file.status?.toLowerCase() === 'failed' || file.status?.toLowerCase() === 'infected'){
            infected +=1
        }
        else if (file?.status === null || fileStatus.status?.toLowerCase() === 'pending' || fileStatus.status?.toLowerCase() ==='upload pending' || fileStatus.status?.toLowerCase() === 'virus scan in progress')
         {
            inProgress +=1
        }
    })
    fileStatus?.files?.map((file:any)=>{
        if (file?.isUploaded === null || fileStatus?.status === 'Pending for virus scan'){
            virusScan +=1
        }
        else if 
        (((file?.isUploaded === false || file?.isUploaded === 'false') && fileStatus?.status === 'Failed' && file?.status === 'Passed') 
         || ((file?.isUploaded === false || file?.isUploaded === 'false') && fileStatus?.status === 'Uploaded' && file.status !== 'Failed')
        ){
            uploadFail +=1
        }
        else if (file?.isUploaded === true || file?.isUploaded === 'true'){
            uploadDone +=1
        }

    })

    const decideStatus=(file:any)=>{
        if (file?.status === null || fileStatus?.status === 'Pending for virus scan'){
            return <p style={{ fontSize: '1.25rem', color: '#4040440', marginLeft: '3.5rem',marginBlock: '2.5rem' }}>-</p>
        }
        else if (['failed', 'infected'].includes(file?.status?.toLowerCase())){
            return <p className={classes.fileStatusNotApplicable}>{drupalData[0]?.uploadNA}</p>
        }
        else{ 
            if (file?.isUploaded === null || fileStatus?.status.includes('Submitted for uploading')){
                return <><img src={inprogress} alt="" /> <p className={classes.fileStatusInprogress}>{drupalData[0]?.uploadPending}</p></>
            }
            else if (file?.isUploaded === true || file?.isUploaded === 'true' || file?.status === 'sucess'){
                return <><img src={check} alt="" /><p className={classes.fileStatus}>{drupalData[0]?.uploadCompleted}</p></>
            }
            else if (file?.isUploaded === false || file?.isUploaded === 'false'){
                return  <><img src={cancel} alt="" /><p className={classes.fileStatusInfected}>{drupalData[0]?.uploadFailed}</p></>
            }
        }
    }

    return (
        <>
            <div className={classes.caseContainer} role="row" tabIndex={0}>
                <div style={{ gridArea: 'UploadInfo' }} role="cell" tabIndex={0}>
                    <p className={classes.pnx}>{fileStatus?.pnx}</p>
                    <p className={classes.filesInfo}>{fileStatus?.files.length} {drupalData[0].uploadfiles}</p>
                </div>
                <p className={classes.fileTime} role="cell" tabIndex={0}>{momentString}</p>
                <div className={classes.scanContainer} role="cell" tabIndex={0}>
                    <div className={classes.scanDetails}>
                        {(clean > 0) && <><div className={classes.passNo}>{clean}</div>
                            <p className={classes.pass}>{drupalData[0].uploadclean}</p> </>}
                        {infected > 0 && <><div className={classes.failNo}>{infected}</div>
                            <p className={classes.fail}>{drupalData[0].infected}</p></>}
                    </div>
                   {fileStatus?.status === 'Pending for virus scan' && <p className={classes.filesInfo}>{fileStatus?.status.charAt(0)?.toUpperCase() + fileStatus?.status.slice(1)}</p>}
                </div>
                <div style={{gridArea: 'UploadStatus', display:'flex', alignItems:'center', textWrap:'nowrap'}}>
                {(virusScan >0) && <p style={{ fontSize: '1.25rem', color: '#4040440', marginLeft: '3.5rem' }}>-</p>}
                {(uploadDone > 0) && <><div className={classes.passNo}>{uploadDone}</div>
                <p className={classes.pass}>{drupalData[0].uploadCompleted}</p>
                </>}
                {(uploadFail > 0) &&<><div className={classes.failNo}>{uploadFail}</div>
                <p className={classes.fail}>{drupalData[0].uploadFailed}</p>
                </>}
                {(uploadDone === 0 || uploadFail === 0) && (fileStatus?.status.includes('Submitted for uploading'))&&
                <p className={classes.filesInfo}>{fileStatus?.status.charAt(0)?.toUpperCase() + fileStatus?.status.slice(1)}</p>
                }
                </div>

                <button aria-label={fileStatus.pnx + " uploaded on " + momentString} className={classes.expandBtnStyling} onClick={expandUpload} aria-expanded={Expanded}>
                    {collapsFunctionImg}
                </button>
            </div>
            {Expanded && <div className={classes.secondTable}>
                <div>
                    <p className={classes.docTitle} role="columnheader" tabIndex={0}>{drupalData[0].docName}</p>
                    {
                        fileStatus?.files?.map((file: any) => {
                            return (
                                <div className={classes.fileContainer} key={file.documentId} role="cell" tabIndex={0}>
                                    <img style={{ width: '40px' }} src={download} alt="" />
                                    <p className={classes.fileName}>{file?.fileName}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start'}}>
                    <div>
                        <p className={classes.docTitle} role="columnheader" tabIndex={0}>{drupalData[0].scanStatus}</p>
                        {
                            fileStatus?.files?.map((file: any) => {
                                return (
                                    <div className={classes.fileContainer} key={file.documentId} role="cell" tabIndex={0}>
                                        {file?.status?.toLowerCase() === 'passed' && <><img src={check} alt="" />
                                            <p className={classes.fileStatus}>{file?.status.charAt(0)?.toUpperCase() + file?.status.slice(1)}</p></>
                                        }
                                        {(file?.status?.toLowerCase() === 'failed' || file?.status?.toLowerCase() === 'infected') && <><img src={cancel} alt="" />
                                            <p className={classes.fileStatusInfected}>{file?.status.charAt(0)?.toUpperCase() + file?.status?.slice(1)}</p></>
                                        }
                                        {inProgress > 0 && <><img src={inprogress} alt="" />
                                            <p className={classes.fileStatusInprogress}>{file?.status ? file?.status.charAt(0)?.toUpperCase() + file?.status.slice(1) : DefaultDrupalData[0].inProgress}</p></>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    </div>
                    <div>
                        <p className={classes.docTitle} role="columnheader" tabIndex={0}>{drupalData[0].status}</p>
                        {
                            fileStatus?.files?.map((file: any) => {
                                return (
                                    <div className={classes.fileContainer} key={file.documentId} role="cell" tabIndex={0}>
                                        {decideStatus(file)}
                                    </div>
                                )
                            })
                        }
                    </div>

            </div>}
            {Expanded && <div className={classes.secondTableSmallScreen}>
                <div>
                    {
                        fileStatus?.files?.map((file: any, index: number) => {
                            return (
                                <div key={file.documentId}>
                                    <p className={classes.docTitle}>{drupalData[0].docName}</p>
                                    <div className={classes.fileContainer}>
                                        <img style={{ width: '40px' }} src={download} alt="" />
                                        <p className={classes.fileName}>{file?.fileName}</p>
                                    </div>
                                    <p className={classes.docTitle}>{drupalData[0].scanStatus}</p>
                                    <div className={classes.statusContainer}>
                                        <img src={check} alt="" />
                                        <p className={classes.fileStatus}>{file?.status}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            }
        </>
    )

}

export default IndividualUploadStatus

const useStyles = makeStyles({
    caseContainer: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateAreas: `'UploadInfo UploadTime scanStatus UploadStatus expandBtn' `,
        gridTemplateColumns: '1fr 1fr 1fr 1.25fr 0fr',
        justifyContent: 'start',
        padding: '0.5rem 1.5rem',
        backgroundColor: '#F0F8FF',
        border: '1px solid #EAEAEA',
        borderBottom: 'none',
        '@media (max-width: 925px)': {
            gridTemplateColumns: '1fr 0fr',
            gridTemplateAreas: `'UploadInfo expandBtn' 'UploadTime UploadTime' 'scanStatus scanStatus' 'UploadStatus UploadStatus'`,
            rowGap: '0.9rem'
        }
    },
    pnx: {
        font: '1.375rem AssistantSemiBold',
        color: '#271C6F',
        marginBlock: '0'
    },
    filesInfo: {
        font: '1.25rem AssistantRegular',
        color: '#404040',
        marginBlock: '0',
        marginRight: '1.5rem',
        gridArea: 'UploadTime',
    },
    fileTime: {
        font: '1.25rem AssistantRegular',
        color: '#404040',
        marginBlock: '0',
        marginRight: '1.5rem',
        marginLeft: '0.75rem',
        gridArea: 'UploadTime'
    },
    scanDetails: {
        display: 'flex',
        '@media (max-width: 925px)': {
            marginTop: '0.5rem',
            marginBottom: '1.188rem'
        }
    },
    scanContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '0.5rem',
        gridArea: 'scanStatus',
        '@media (max-width: 925px)': {
            display: 'block'
        }
    },
    pass: {
        font: '1.125rem AssistantSemiBold',
        color: '#567B23',
        marginBlock: '0',
        marginRight: '1.5rem'
    },
    passNo: {
        width: '1.75rem',
        height: '1.75rem',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        marginRight: '0.5rem',
        backgroundColor: '#567B23',
    },
    inProgressNo: {
            width: '1.75rem',
            height: '1.75rem',
            backgroundColor: '#B74401',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',
            marginRight: '0.5rem',
            marginLeft: '0.15rem'

    },
    inProgress: {
        font: '1.125rem AssistantSemiBold',
        color: '#C14701',
        marginBlock: '0',
        marginRight: '1rem'

    },
    fail: {
        font: '1.125rem AssistantSemiBold',
        color: '#962047',
        marginBlock: '0',
        marginRight: '1rem'
    },
    failNo: {
        width: '1.75rem',
        height: '1.75rem',
        backgroundColor: '#962047',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        marginRight: '0.5rem',
    },
    secondTable: {
        padding: '1.5rem 1.25rem 0 1.25rem',
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1.3fr',
        border: '1px solid #EAEAEA',
        borderTop: 'none',
        backgroundColor: '#FFFFFF',
        '@media (max-width: 670px)': {
            display: 'none'
        }
    },
    secondTableSmallScreen: {
        padding: '1.5rem 1.25rem 2rem 1.25rem',
        border: '1px solid #EAEAEA',
        borderTop: 'none',
        '@media (min-width: 670px)': {
            display: 'none'
        }
    },
    docTitle: {
        font: '1.25rem AssistanRegular',
        color: '#767676',
        marginBlock: '0',
        '@media (max-width: 670px)': {
            font: '1.125rem AssistanRegular',
        }
        
    },
    fileContainer: {
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 670px)': {
            borderBottom: 'none',
            marginBottom: '1.125rem',
            marginTop: '0.5rem'
        }
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '2px solid #F7F7F7',
        '@media (max-width: 670px)': {
            marginTop: '0.625rem',
            paddingBottom: '1.125rem',
            marginBottom: '1.125rem'
        }
    },
    fileName: {
        font: '1.375rem AssistantRegular',
        color: '#404040',
        marginLeft: '1rem',
        marginBlock: '2.5rem',
        '@media (max-width: 670px)': {
            font: '1.25rem AssistanRegular',
            marginLeft: '0.75rem',
            marginBlock: '0'
        }
    },
    fileUpload: {
        font: '1.25rem AssistantRegular',
        color: '#567B23',
        marginLeft: '0.5rem',
        marginBlock: '2.55rem',
        '@media (max-width: 670px)': {
            font: '1.25rem AssistanRegular',
            marginLeft: '0.75rem',
            marginBlock: '0'
        }
    },
    fileStatus: {
        display:'block',
        font: '1.25rem AssistantRegular',
        color: '#567B23',
        marginLeft: '0.5rem',
        marginBlock: '2.55rem',
        '@media (max-width: 670px)': {
            font: '1.25rem AssistanRegular',
            marginLeft: '0.75rem',
            marginBlock: '0'
        }
    },
    fileStatusFail: {
        font: '1.25rem AssistantRegular',
        color: '#FF0000',
        marginLeft: '0.5rem',
        marginBlock: '2.55rem',
        '@media (max-width: 670px)': {
            font: '1.25rem AssistanRegular',
            marginLeft: '0.75rem',
            marginBlock: '0'
        }
    },
    fileStatusInfected: {
        font: '1.25rem AssistantRegular',
        color: '#FF0000',
        marginLeft: '0.5rem',
        marginBlock: '2.55rem',
        '@media (max-width: 670px)': {
            font: '1.25rem AssistanRegular',
            marginLeft: '0.75rem',
            marginBlock: '0'
        }
    },
    fileStatusInprogress: {
        font: '1.25rem AssistantRegular',
        color: '#C14701',
        marginLeft: '0.5rem',
        marginBlock: '2.55rem',
        '@media (max-width: 670px)': {
            font: '1.25rem AssistanRegular',
            marginLeft: '0.75rem',
            marginBlock: '0'
        }
    },
    fileStatusNotApplicable:{
        font:'1.25rem AsssistantRegular',
        color:'#404040',
        marginLeft: '0.5rem',
        marginBlock: '2.55rem',
        '@media (max-width: 670px)': {
            font: '1.25rem AssistanRegular',
            marginLeft: '0.75rem',
            marginBlock: '0'
        }

    },
    expandBtnStyling: {
        border: 'none',
        background: 'none',
        padding: '0',
        gridArea: 'expandBtn'
    },
    collapseBtn: {
        transform: 'rotate(180deg)'
    }
})