import { makeStyles } from '@mui/styles';

const usestyles = makeStyles({
    bouncingLoader: {
        display: "flex",
        justifyContent: "center",
        "& div": {
            width: "1rem",
            height: "1rem",
            margin: "3px 6px",
            borderRadius: "50%",
            backgroundColor: "#2273C3",
            animation: '$bouncing-loader 2s infinite ease-in'
        },
        '& div:nth-child(2)': {
        animationDelay: '0.4s',
        },
        '& div:nth-child(3)': {
            animationDelay: '0.8s',
        },
        '& div:nth-child(4)': {
            animationDelay: '1.2s',
        },
        '& div:nth-child(5)': {
            animationDelay: '1.6s',
        },
    },
    '@keyframes bouncing-loader': {
         '0%': {
             opacity: 0
         },
         '50%': {
            opacity: 1
         },
         '100%': {
             opacity: 0
         }
      },
      
    loaderText: {
        fontFamily: 'AssistantRegular',
        color: '#404040',
        marginBlock: '0',
        marginTop:'0.5625rem',
        fontSize: '1.25rem',
        textAlign: 'center'
    },
    loaderContainer: {
        padding: '5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
})

export default function Loader() {
    const classes = usestyles();
    return(
        <div className = {classes.loaderContainer} role="alert" aria-label="Please wait while we upload your files to our server.">
            <div className={classes.bouncingLoader}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <p className = {classes.loaderText}>Please wait while we upload your files to our server.</p>
      </div>
      
    )
}