import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { makeStyles } from "@mui/styles";
import {Snackbar, Alert } from '@mui/material';
import Dropzone, { Accept } from "react-dropzone";
import Icon_Upload from '../../assets/icons/Icon_Uplaod.svg';
import { Dispatch } from 'redux';
import { useDispatch, useSelector} from 'react-redux';
import CommonService from '../../common/api/common-api-service';
import { UPLOAD_SERVER } from '../../common/upload-api/api-urls';
import { DRUPALSERVER } from '../../common/drupal-api/drupal-api-urls';
import DrupalService from '../../common/drupal-api/drupal-api-service';
import {addFile} from '../../stores/uploadFiles/UploadSlice';
import {updatePercentage} from '../../stores/uploadFiles/UploadProgressSlice';
import { updateBlobCount, updateWaitStatus } from '../../stores/uploadFiles/UploadStatusSlice';
const { v4: uuidv4 } = require('uuid');

const drupalApi = new DrupalService();
const commonApi = new CommonService('upload');

const defaultDrupalData = [
    { 
        "needHelp": "Need help with uploading documents?", 
        "packageStatus": "Package upload status", 
        "sizeLimit": "100 MB", 
        "dragAndDrop": "Drag and drop to upload files here", 
        "or": "Or", 
        "browseFilesBtn": "Browse files", 
        "supportedType": "Document types supported: .PDF, .DOC, .DOCX, .CSV, .TXT, .XLSX, .XLS, .JPG, .JPEG, .PNG, .GIF, .MP3, .WMA, .AVI, .MP4, .ZIP", 
        "fileSize": "Maximum file size: 2 GB for each document", 
        "backBtn": "Back to your case details", 
        "proceedBtn": "Proceed", 
        "completedLabel": "completed" ,
        "encryptedInfo":"Kindly do not upload encrypted or password protected files.",
        "HelpHeading":"Information and Guidance",
        "HelpInfoOne":"Our website offers some information and guidance for what our investigators need to be best be enabled to work on case quickly and efficient.",
        "HelpInfoTwo":"You'll find helpful insights for templates, evidence sets and guidance on appropriate handling of sensitive business and customer data.",
        "HelpBtn":"See infomation on business files",
        "guidanceLink":"https://www.financial-ombudsman.org.uk/businesses/resolving-complaint/information-from-financial-businesses"
    }
];
const defaultDrupalErrs = [
    {
        "fileLimit":"*filename* is too large to upload. File size limit 2GB",
        "sameFile":"*filename* same file cannot be uploaded",
        "multipleExtension": "File name cannot contain multiple extensions",
        'networkError': 'There was a network error uploading the files; please try again later.'
    }
];
interface OptionProps {
    maxSize: number,
    packetSize: number,
    setpacketSize: React.Dispatch<React.SetStateAction<number>>,
    noOfFiles: number,
    allowedFiles: Accept,
    files: never[],
    setFiles: React.Dispatch<React.SetStateAction<never[]>>,
    removeFile: (filename: any) => void,
    setmainFiles: any,
    mainFiles: any,
    tempFiles: string[],
    setTempFiles: React.Dispatch<React.SetStateAction<string[]>>,
    setAbortControllerSharing:any,
    onScreenList: string
}
interface ObjProps {
    name?: any,
    url?: string | ArrayBuffer,
    size?: any,
    type?: any,
    path?: any,
    file?: any
}
const FileUpload: React.FC<OptionProps> = (props) => {
    const [drupalData, setDrupalData] = useState<any>(defaultDrupalData);
    const [drupalErrs, setDrupalErrs] = useState(defaultDrupalErrs);
    const [documentIds, setDocumentIds] = useState({});
    const dispatch: Dispatch<any> = useDispatch();
    useEffect(() => {
        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getUploadMainPage, 'get')
            .then((res) => {
                if (res.length > 0) {
                    setDrupalData(res);
                } else {
                    setDrupalData(defaultDrupalData);
                }
            })
            .catch((_e: any) => {
                setDrupalData(defaultDrupalData);
            });
        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getUploadErrorMsgs, 'get')
            .then((res) => {
                setDrupalErrs(res);
            })
            .catch((_e: any) => {
                setDrupalErrs(defaultDrupalErrs);
            });
            // eslint-disable-next-line
    }, []);
    const reduxPercentages:any = useSelector((store:any)=>store.uploadProgress.percentages);


    const classes = useStyles();
    let jsonType = ``;

    const fileSize = (props.maxSize) * (1000000);
    let typeOfFiles = ``;
    let errorComponents: string[] = [];
    let typeErrors: string[] = [];
    const [errMessages, setErrMessages] = useState<string[]>([]);
    const [rejectedFile, setRejectedFile] = useState<string[]>([]);
    const [visible, setVisible] = useState(false);
    const [openToaster, setOpenToaster] = useState(false);

    const checkPresent=(fileName:string)=>{
        for(let file of props.mainFiles){
            if(file.name===fileName){
                return true;
            }
        }
        for(let file of props.tempFiles){
            if(file===fileName){
                return true;
            }
        }
        return false;
    }
    const displayErrors=()=>{
        setVisible(true);
        props.setTempFiles([]);
    }

    const checkMultipleExtensions=(fileName:string)=>{
        const suppTypes = props.onScreenList.split(",");
        let indexes = new Set();
        for(let supType of suppTypes){
            if(fileName.includes(supType.toLowerCase().trim())){
                indexes.add(fileName.indexOf(supType.toLowerCase().trim()));
            } 
        }
        return indexes.size>1;
    }
    const handleDrop = (rejectedFiles: any) => {
        if(rejectedFiles[0]?.errors[0]?.code.includes("too-many-files")){
            setErrMessages(["More than 50 files cannot be uploaded in one go"])
            displayErrors();
        }else{
        rejectedFiles.forEach((rejectedFile: any) => {
            rejectedFile.errors.forEach((err: any) => {
                errorComponents.push(err.message);
                setErrMessages(errorComponents);
                displayErrors();
                typeErrors.push(rejectedFile.file.name);
                setRejectedFile(typeErrors);
            });
        })
    }
    }
    
    Object.values(props.allowedFiles).forEach(val=>jsonType = `${jsonType}${(JSON.stringify(val))}`)
    for (let i = 0; i < jsonType.length; i++) {
        let ch = jsonType.charAt(i);
        if (ch !== '[' && ch !== ']' && ch !== '"' && ch !== ',' && ch !== '.') { typeOfFiles = `${typeOfFiles}${ch}` }
        if (ch === '.') { typeOfFiles = `${typeOfFiles} .` }
    }

    const fileEncrypted = (file: any) => {
        return new Promise((resolve)=>{
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = function () {
                file.text().then((x: any) => {
                    if (x.includes("Encrypt")){
                        resolve(true);
                    }
                    else if (x.substring(x.lastIndexOf("<<"), x.lastIndexOf(">>")).includes("/Encrypt")){
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                }
                );
            }
        })
    }
    const helpGuidance = (link:string) => {
        window.open(link)
    }
    const handleCloseToaster = () => {
        setOpenToaster(false);
    };
    return (
        <>
            <div className={classes.uploadDocs}>
            <Snackbar
            anchorOrigin={{vertical: "top", horizontal:'center'}}
            className={classes.snackBar}
            open={openToaster}
            autoHideDuration={10000}
            onClose={handleCloseToaster}>
                <Alert
                    onClose={handleCloseToaster}
                    severity="error"
                    sx={{ fontSize: '1.25rem' }}>
                    <div dangerouslySetInnerHTML={{ __html: drupalErrs[0]?.networkError ? drupalErrs[0]?.networkError : defaultDrupalErrs[0].networkError }}></div>
                </Alert>
            </Snackbar>
                {visible && <div>
                    <ul className={classes.errContainer}>
                        {errMessages.map((errMessage) => (
                            <li role="alert" key={errMessage}>{errMessage}</li>
                        ))}
                    </ul>
                    <ul className={classes.errContainer}>
                        {rejectedFile.map((errMessage) => (
                            <li role="alert" key={errMessage}>{errMessage} is not allowed</li>
                        ))}
                    </ul>
                </div>}
                <Dropzone noClick onDrop={(acceptedFiles, rejectedFiles)=>{
                        setVisible(false);
                        setErrMessages([]);
                        for (const file of acceptedFiles){
                            let component: ObjProps = { file: {}, name: '', type: '', size: '', path: '', url: '' };
                            if((file.size/1048576)>2048){
                                setErrMessages((prev)=>[...prev, drupalErrs[0].fileLimit.replace("*filename*",file.name)]);
                                displayErrors();
                            }else if(checkPresent(file.name)){
                                setErrMessages((prev)=>[...prev, drupalErrs[0].sameFile.replace("*filename*",file.name)]);
                                displayErrors();
                            }else if(checkMultipleExtensions(file.name)){
                                setErrMessages((prev)=>[...prev, drupalErrs[0]?.multipleExtension]);
                                displayErrors();
                            }
                            else{
                                props.setTempFiles((prev)=>[...prev, file.name]);
                                fileEncrypted(file).then(async (res)=>{
                                if(res){
                                    setErrMessages((prev)=>[...prev, `${file.name} is encrypted. Not allowed`]);
                                    displayErrors();
                                }
                                else{
                                    dispatch(addFile(file));
                                    props.setmainFiles((currentFiles: any) => [...currentFiles, component])
                                    props.setpacketSize((currentSize:any) => {return currentSize+file.size/1048576});
                                    const getRandomString = () => {
                                        let result = '';
                                        result =  uuidv4()
                                        return result;
                                    }
                                    if(!sessionStorage.getItem('packageId')){
                                        sessionStorage.setItem('packageId',getRandomString());
                                    }
                                    const reqPayload = {
                                        packageId: sessionStorage.getItem('packageId')
                                    };
                                    const filesType = (name: string) => {
                                        let parts = name?.split(".");
                                        return (parts[parts.length - 1]);
                                      }
                                    await commonApi.httpConnection(UPLOAD_SERVER.SERVICES.getFileSasToken, 'post', reqPayload)
                                    .then(async (res) => {
                                        if (res?.status === 'OK') {
                                            let controller = new AbortController();
                                            props.setAbortControllerSharing(controller);
                                            let head = {
                                                headers: {
                                                  'Content-Type': 'multipart/form-data',
                                                //   'Content-Type': 'application/json',
                                                  'Content-Transferd-Encoding': 'base64',
                                                  'Connection': 'keep-alive',
                                                  'Content-Length': file.size,
                                                  'X-Ms-blob-type': 'BlockBlob',
                                                  'X-Ms-Blob-Content-Type': 'application/octet-stream',
                                                  'Filename': (res.data.url?.split('?sv=')[0]?.split('/digitalportalfiles/')[1]),
                                                  'X-Ms-Meta-Filename': (res.data.url?.split('?sv=')[0]?.split('/digitalportalfiles/')[1]),
                                                  'X-Ms-Meta-Filetype': filesType(file.name),
                                                  'Accept': '*/*',
                                                  'Accept-Encoding': 'gzip, deflate, br',
                                                },
                                                onUploadProgress: progressEvent => {
                                                    let percentComplete = progressEvent.loaded / progressEvent.total;
                                                    if(percentComplete===1 && !reduxPercentages[file.name]?.uploadDone){
                                                        dispatch(updateWaitStatus(true));
                                                    }
                                                    percentComplete = Math.min(percentComplete, 0.99);
                                                    dispatch(updatePercentage({name: file.name, percentage: percentComplete*100, documentId:'', uploadDone: false}))
                                                },
                                                signal: controller.signal
                                              };
                                            axios.put(res.data.url, file, head)
                                            .then((innerRes)=>{
                                                if(innerRes.status===201){
                                                    console.log('Received 201')
                                                    dispatch(updatePercentage({name: file.name, percentage: 100, documentId: res.data.documentId,uploadDone: true }));
                                                    dispatch(updateBlobCount(1));
                                                }else if(innerRes.status>=400){
                                                    console.log(innerRes.status+" "+'failed')
                                                    setOpenToaster(true);
                                                }
                                            })
                                            .catch((e)=>{
                                                setOpenToaster(true);
                                                console.log('Failed for some reason');
                                                if (axios.isCancel(e)) {
                                                    console.log('File removed', e.message);
                                                }
                                            })
                                        }
                                        else{
                                            setOpenToaster(true);
                                        }
                                    })
                                }}) } }
                        handleDrop(rejectedFiles);
                    }
                    } multiple maxFiles={props.noOfFiles} maxSize={fileSize} accept={props.allowedFiles}>
                    {({ getRootProps, getInputProps,open }) => (
                        <div className={classes.upCon}>
                        <div {...getRootProps({ className: classes.uploadContainer })}>
                            <div className={classes.cloudImg}><img src={Icon_Upload} alt="" /></div>
                            <input {...getInputProps()} />
                            <div className={classes.uploadAction}>
                                <p className={classes.texts}>{drupalData[0].dragAndDrop}</p>
                                <p className={classes.texts}>{drupalData[0].or}</p>
                                <p role={'button'} tabIndex={0}
                                onClick={()=>{
                                    
                                        console.log(props.noOfFiles);
                                        console.log(props.mainFiles);
                                         if(props.mainFiles?.length==props.noOfFiles||props.mainFiles?.length>=props.noOfFiles){
                                            setErrMessages(["More than 50 files cannot be uploaded in one go"]);
                                            setVisible(true);
                                        }
                                        else{
                                            open();
                                            setVisible(false);
                                            setErrMessages([]);
                                            setRejectedFile([]);
                                        }
                                    
                                        
                                }}
                                 onKeyDown={(e)=>{
                                    if(e.code==='Enter' || e.code==='Space'){
                                        e.preventDefault();
                                        
                                        console.log(props.noOfFiles);
                                        console.log(props.mainFiles);
                                         if(props.mainFiles?.length==props.noOfFiles||props.mainFiles?.length>=props.noOfFiles){
                                            setErrMessages(["More than 50 files cannot be uploaded in one go"]);
                                            setVisible(true);
                                        }else{
                                        open();
                                        setVisible(false);
                                        setErrMessages([]);
                                        setRejectedFile([]);
                                        }
                                    }
                                    
                                }} className={classes.uploadButton}>{drupalData[0]?.browseFilesBtn}</p>
                                <p className={classes.beloTexts}>{drupalData[0]?.supportedType} {props?.onScreenList}</p>
                                <p className={classes.beloTexts}>{drupalData[0]?.fileSize}</p>
                                <p className={classes.beloTexts}>Maximum files allowed for upload in one go : 50</p>
                                <p className={classes.beloTexts}>{drupalData[0]?.encryptedInfo}</p>
                            </div>
                            
                        </div>
                        <div className={classes.infoContainer}>
                            <h1 className={classes.infoHead}>{drupalData[0]?.HelpHeading}</h1>
                            <p className={classes.infoText}>{drupalData[0]?.HelpInfoOne}</p>
                            <p className={classes.infoText}>{drupalData[0]?.HelpInfoTwo}</p>
                            <button className={classes.helpBtn} onClick={() => helpGuidance(drupalData[0]?.guidanceLink)}>{drupalData[0]?.HelpBtn}</button>
                        </div>
                        </div>
                    )}
                </Dropzone>
            </div>
        </>
    )
}

export default FileUpload;

const useStyles = makeStyles({
    uploadAction: {
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center'
    },
    infoHead:{
        textDecoration:'underline',
        fontSize:'1.5rem',
        color:'black',
        marginTop:'0rem',
        fontFamily:'AssistantSemiBold'
    },
    infoText:{
        fontSize:'1.25rem',
        color:'#707070',
        textAlign:'left',
        fontFamily:'AssistantRegular'
    },
    helpBtn:{
        color: '#FFFFFF',
        backgroundColor: '#D22D64',
        padding: '0.688rem 1.5rem',
        borderRadius: '1.5rem',
        marginBottom:'0rem',
        cursor: 'pointer',
        fontSize:'1.125rem',
        border:'none',
        '&:hover':{
            backgroundColor: '#B8134A'
        }
    },
    cloudImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '1.5rem',
    },
    beloTexts: {
        color: '#767676',
        fontSize: '1.25rem',
        fontFamily: 'AssistantSemiBold',
        letterSpacing: 0,
        margin: 0,
        marginBottom: '0.5rem'
    },
    infoContainer:{
        textAlign:'center',
        margin:'auto',
        padding:'1.5rem 2rem',        
        border:'1px solid black',
        borderRadius:'1rem',
        width:'40%'     
    },
    texts: {
        color: '#767676',
        fontSize: '1.5rem',
        fontFamily: 'AssistantSemiBold',
        letterSpacing: 0,
        margin: 0,
        marginBottom: '0.5rem'
    },
    responsiveUpload: {
        '@media (min-width: 481px)': {
            display: 'none'
        }
    },
    accordion: {
        boxShadow: 'none',
        marginBottom: '2.5rem'
    },
    accordionTitle: {
        backgroundColor: '#F0EEFF',
        border: '1px solid #CFCFCF',
        font: '1.25rem AssistantSemiBold',
        color: '#271C6F',
    },
    accordionText: {
        border: '1px solid #CFCFCF'
    },
    uploadDocs: {
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width: 480px)': {
            display: 'none'
        }
    },
    uploadContainer: {
        width:'60%',
        paddingRight:'1.5rem'
    },
    upCon:{
        textAlign: 'left',
        padding: '40px 20px 40px 20px',
        borderRadius: '8px',
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23404040FF' stroke-width='1' stroke-dasharray='4%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        display:'flex',
        backgroundColor: '#FFFFFF',
        color: '#404040',
    },
    uploadButton: {
        color: '#FFFFFF',
        backgroundColor: '#D22D64',
        padding: '0.688rem 1.5rem',
        borderRadius: '1.5rem',
        cursor: 'pointer',
        marginBottom: '1.5rem',
        '&:hover':{
            backgroundColor: '#B8134A'
        }
    },
    dragDrop: {
        font: '1.625rem AssistantSemiBold',
        color: '#000',
        textAlign: 'center'
    },
    browseFiles: {
        color: '#271C6F',
        textDecoration: 'underline',
        cursor: 'pointer',
        textAlign: 'center'
    },
    documentRules: {
        font: '1.125rem AssistantRegular',
        color: '#1D1D1D',
        marginBlock: '0',
        textAlign: 'center'
    },
    orContainer: {
        backgroundColor: '#ECF6FF',
        border: '2px solid #96BADB',
        borderRadius: '50%',
        height: '3.75rem',
        width: '3.75rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '2.03rem 0',
        alignSelf: 'center'
    },
    or: {
        font: '1.75rem AssistantSemiBold',
        color: '#271C6F',
        marginBlock: '0'
    },
    qrHelp: {
        font: '1.125rem AssistantSemiBold',
        color: '#271C6F',
        marginBlock: '0',
        textDecoration: 'none',
        cursor: 'pointer'
    },

    upload: {
        margin: '20px',
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#c7c7c7',
        textAlign: 'center',
        '&:disabled': {
            backgroundColor: '#e0e0e0',
            color: '#7e7e7e'
        }
    },
    submitContainer: {
        textAlign: 'center'
    },
    info: {
        margin: '1%'
    },
    errContainer: {
        textAlign: 'center',
        color: '#E00000',
        font: '1.25rem AssistantRegular',
        listStyleType: 'none',
        padding:'0 1.2rem 0 1.2rem',
        '& li':{
            width:'100%',
            wordWrap:'break-word',
            wordBreak:'break-all'
        }
    },
    snackBar: {
        marginBlockStart: '10rem',
        border: "1px solid red",
        backgroundColor: "#EAFFCC",
        textAlign:"left",
        font: "normal normal normal 24px/35px Assistant",
        color:" #404040",
    },
});