import React, { useEffect } from 'react';
import {Button, Dialog, DialogActions,DialogContent, DialogContentText, DialogTitle}  from '@mui/material';
import caution from '../assets/icons/caution.svg';
import { makeStyles } from "@mui/styles";
import { useMsal } from "@azure/msal-react";
import {
    useNavigate
} from "react-router-dom";
import CommonService from '../common/api/common-api-service';
import { SERVER } from '../common/api/api-urls';


interface SessionDialogProps {
    open: boolean;
    session1: boolean;
    title: string;
    text: string;
    button1: string;
    button2: string;
}
const useStyles = makeStyles({
    iconDesSession: {
        marginLeft: "45%",
    },
    buttonClassSession: {
        boxShadow: 'none',
        marginBottom: '2.5rem',
        background: 'transparent',
    },
    dialogTitleSession: {
        margin: 0,
        fontSize: '1.75rem',
        fontFamily: 'AssistantSemiBold',
        textAlign: 'center'
    },
    proceedButtonSession: {
        marginRight: '3rem',
        fontSize: '1.1rem',
        background: '#D22D64 1% 1% no-repeat padding-box!important',
        border: '0.06rem solid #D22D64',
        borderRadius: '1.563rem',
        marginLeft: '2rem',
        fontFamily: 'AssistantSemiBold',
        paddingRight: '1.5rem',
        textTransform: 'none',
        color: '#fff',
        paddingLeft: '1.5rem',
        '&:focus': {
            border: '2px solid black'
        }
    },
    dialogDescriptionSession: {
        fontSize: '1rem',
        fontFamily: 'AssistantRegular',
        color: '#404040',
        textAlign: 'center'
    },
    resetButtonSession: {
        fontFamily: 'AssistantSemiBold',
        fontSize: '1.15rem',
        color: '#2B2A6D',
        '&:focus': {
            border: '2px solid black'
        },
        textTransform: 'none',
        marginRight: '3rem',
        '&:hover': {
            textDecorationLine: 'underline',
            textDecorationColor: '#2B2A6D'
        }
    }
})

const SessionDialog = ({ open, session1, title, text, button1, button2 }: SessionDialogProps) => {
    const [openSession, setOpenSession] = React.useState(open);
    const classes = useStyles();
    const navigate = useNavigate();
    const { instance } = useMsal();
    useEffect(() => {
        if (openSession && session1 && openSession === true && session1 === true) {
            setTimeout(() => {
                handleDiscardSession();
                setOpenSession(false);
            }, 10000)
        }
    }, [openSession])
    const handleDiscardSession = () => {
       
        const commonAPI = new CommonService();
        localStorage.setItem('tabsOpen','0');
        localStorage.removeItem('tabsOpen');
        commonAPI.httpConnection(SERVER.SERVICES.logoutRequest, 'get')
            .then(res => {
                setOpenSession(false);
                localStorage.setItem('tabsOpen','0');
                localStorage.removeItem('tabsOpen');
                const account = instance.getAllAccounts()[0];
                const valueLogout = instance.getAccountByHomeId(account.homeAccountId);
                sessionStorage.clear();
                localStorage.removeItem('ACT');
                instance.logout({ account: valueLogout });
                navigate('/logout');
            })
    };


    const handleContinueSession = () => {
        const commonAPI = new CommonService();

        commonAPI.httpConnection(SERVER.SERVICES.updateInactiveSession, 'get')
            .then(res => {
                setOpenSession(false);
                window.location.reload();
            })

    };

    return (
        <React.Fragment>
            <Dialog
                open={openSession}
                onClose={handleDiscardSession}
                aria-labelledby="session-dialog-title"
                aria-describedby="session-dialog-description"
            >
                <DialogTitle id="session-dialog-title" >
                    <img src={caution} alt="" className={classes.iconDesSession} />
                    <br/>
                    <p className={classes.dialogTitleSession}>{title}</p>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="session-dialog-description">
                        <p className={classes.dialogDescriptionSession}>{text}</p>
                    </DialogContentText>
                </DialogContent>
                {!session1 && <DialogActions className={classes.buttonClassSession}>
                    <Button  id="discard-session" disableRipple className={classes.resetButtonSession} onClick={handleDiscardSession}>{button1}</Button>
                    <Button id="continue-session" disableRipple className={classes.proceedButtonSession} onClick={handleContinueSession} autoFocus>
                        {button2}
                    </Button>
                </DialogActions>}
            </Dialog>
        </React.Fragment>
    );
    
}
export default SessionDialog
