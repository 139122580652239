import React, { useEffect, useState } from 'react';
import { Dialog } from "@mui/material";
import {makeStyles} from '@mui/styles';
import IconCross from "../../assets/icons/Icon_Cross.svg";
import IconWarning from "../../assets/icons/Icon_Warning.svg";
import { useNavigate } from 'react-router-dom';
import { DRUPALSERVER } from '../../common/drupal-api/drupal-api-urls';
import DrupalService from '../../common/drupal-api/drupal-api-service';
import { Dispatch } from 'redux';
import { useDispatch} from 'react-redux';
import {clearFiles} from '../../stores/uploadFiles/UploadSlice';
import { updateBlobCount } from '../../stores/uploadFiles/UploadStatusSlice';
import { clearExtractedZip } from '../../stores/uploadFiles/UploadZipSlice';

const drupalApi = new DrupalService();
const defaultDrupalData = [
    {
        "heading":"Cancel file upload?",
        "desc":"Are you sure you want to cancel the file upload? You will have to start the virus scan and upload process again if you cancel now.",
        "moreDesc":"Select Continue to continue the file upload.",
        "continueBtn":"Continue",
        "cancelBtn":"Cancel"
    }
];

const CancelPopup = (props: any) => {
    const classes = useStyles();
    const [drupalData, setDrupalData] = useState<any>(defaultDrupalData);
    const navigate = useNavigate();
    const uploadURL = '/view-cases-upload/'+props.incId;
    const dispatch: Dispatch<any> = useDispatch();
    useEffect(() => {
        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getCancelPopup, 'get')
            .then((res) => {
                if (res.length > 0) {
                    setDrupalData(res);
                } else {
                    setDrupalData(defaultDrupalData);
                }
            })
            .catch((_e: any) => {
                setDrupalData(defaultDrupalData);
            });
    }, []);
    return (
        <div>
            <Dialog
                aria-label={"Cancel file upload?"}
                role="dialog"
                aria-live="polite"
                open={props.open}
                onClose={props.onClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: { borderRadius: '0.75rem' }
                }}
            >
                <div className = {classes.popupContainer}>
                    <div className = {classes.closeBtnContainer}>
                        <img role="button" className={classes.closeBtn} aria-label={'close'} title={'close'} tabIndex={0} src={IconCross} onClick = {props.onClose} alt=""/>
                    </div>
                    <div className = {classes.contentContainer}>
                        <div>
                        <img src={IconWarning} alt="Warning"/>
                        </div>
                        <h1 className = {classes.popupHeading}>{drupalData[0].heading}</h1>
                        <p className = {classes.popupText}>{drupalData[0].desc}</p>
                        <p className = {classes.popupText}>{drupalData[0].moreDesc}</p>
                        <div>
                        <div className = {classes.buttons}>
                            <button className = {classes.backCta} onClick = {props.onClose}>{drupalData[0].continueBtn}</button>
                            <button className = {classes.proceedBtn} onClick = {() => {dispatch(clearFiles()); dispatch(updateBlobCount(0)); dispatch(clearExtractedZip()); navigate(uploadURL, {state:{'pnx':props.pnx}})}}>{drupalData[0].cancelBtn}</button>
                        </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        
        </div>
    )
}

export default CancelPopup

const useStyles = makeStyles({
    dialog:{
        borderRadius: '12px'
    },
    popupContainer: {
        padding: '1.5rem'
    },
    closeBtn: {
        cursor: 'pointer'
    },
    closeBtnContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '1rem',
        marginBottom: '1rem',
        backgroundColor: '#fff',
        '@media (max-width: 640px)': {
            display: 'block'
        }
    },
    backCta: {
        padding: '0.688rem 1.5rem',
        border: '1px solid #D22D64',
        color: '#D22D64',
        font: '1.25rem AssistantSemiBold',
        width: 'fit-content',
        borderRadius: '30px',
        marginRight: '1.5rem',
        backgroundColor: '#fff',
        cursor: 'pointer',
        '&:hover':{
            backgroundColor: '#FAE5EC'
        },
        gridArea: 'backCta',
        '@media (max-width: 368px)': {
            marginRight: '0',
            width: '100%'
        }
    },
    proceedBtn: {
        backgroundColor: '#D22D64',
        color: '#fff',
        font: '1.25rem AssistantSemiBold',
        padding: '0.688rem 1.5rem',
        borderRadius: '1.5rem',
        border: 'none',
        cursor: 'pointer',
        '&:disabled': {
            opacity: '50%',
            '&:hover':{
                cursor: 'default'
            }
        },
        '&:hover':{
            backgroundColor: '#B8134A'
        },
        '@media (max-width: 368px)': {
            marginTop: '1rem',
            width: '100%'
        }
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center'
    },
    popupHeading: {
        font: '1.625rem AssistantSemiBold',
        color: '#000000',
        marginBlock: '1.5rem'
    },
    popupText: {
        font: '1.375rem AssistantRegular',
        color: '#404040',
        lineHeight: '29px',
        marginBlockStart: '0',
        marginBlockEnd: '1.125rem',
        marginLeft: '1.5rem',
        marginRight: '1.5rem'
    }
})
