import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import IconCross from '../../../assets/icons/Icon_Cross.svg'

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export const InfoDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <div className='Info-title'>
            <Box sx={{ m: 0, p: 2 }} {...other}>
                {children}
            </Box>

            {onClose ? (
                <IconButton
                    onClick={onClose}
                    disableRipple
                    aria-label='close'
                    sx={{
                        right: 8,
                        top: 12,
                        position: 'absolute',
                        color: (theme) => theme.palette.grey[500],
                        '&:focus': {
                            borderRadius: '4px',
                            border: '2px solid black',
                        }
                    }}
                >
                    <img alt='closeIcon' src={IconCross}  />
                </IconButton>
            ) : null}
        </div>
    );
};

