import React, { useEffect } from 'react';
import FOSLOGO from '../assets/icons/FOS_logo_landscape_RGB.svg';
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
    LoginPage: {
        width: '100%',
        height: 'auto',
    },
    logo: {
        '& svg': {
            width: '6.25rem',
            height: 'auto',
        }
    },
    headerBox: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.25rem 5rem',
    },
    portalName: {
        color: 'black',
        fontWeight: 'bold',
        '& span': {
            fontWeight: 'normal',
        }
    },
    logoClass: {
        top: '28px',
        left: '304px',
        width: '180px',
        height: '85px'
    }
})
export default function UnAuthenticatedHeader() {
    const classes = useStyles();
    const [drupalData, setDrupalData] = React.useState<any | null>(FOSLOGO);

    useEffect(() => {
       setDrupalData(FOSLOGO)
    }, [])


    return (
        <header className={classes.headerBox}>
            <div className="logo">
                {/* <FOSLOGO/> */}
                <img className={classes.logoClass} src={drupalData} alt="Financial Ombudsman Logo" height="50" />
            </div>
        </header>
    );
}

