export const UPLOAD_SERVER = {
  BASE_URL: '/ombudsmanservice/v1/',
  SERVICES: {
    postRemoveFile: 'Upload/removefile',
    postMetaData: 'documentmanagement/upload/uploadmetadata',
    getFileSasToken: 'documentmanagement/upload/getfilesastoken',
    getUploadCompleted: 'documentmanagement/upload/uploadcompleted',
    getPackageStatus: 'documentmanagement/upload/getallpackagestatuses',
    getMetaData: 'documentmanagement/download/getlistoffilesrespondents',
    getEfileCounts: 'Download/GetEfileCounts',
    requestToDownloadFiles: 'documentmanagement/download/requestdownloadforfiles',
    getAvailableDownload: 'documentmanagement/download/getavailablefordownloadrequests',
    getDownloadToken: 'documentmanagement/download/getdownloadsastokens',
    postUpdate: 'casemanagement/updatecase',
    getSharedConfiguration: 'casemanagement/getsharedconfiguration',
    getZipFiles: 'Upload/GetZipContents'
  }
}