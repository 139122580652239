export const SERVER = {
  BASE_URL: '/ombudsmanservice/v1/',
  SERVICES: {
      getOrganisations: 'usermanagement/admin/organizations',
      sendInvitationRespondentAdmins: 'usermanagement/respondentadmin/invite',
      getRespondentAdminList: 'usermanagement/nonadmin/organisations',
      getViewCaseFilter: 'casemanagement/casefilter',
      getViewCaseList: 'casemanagement/caselist',
      getCaseDetailsById: 'casemanagement/casedetails/casedetailsbyid',
      getUpload: 'upload',
      getCasePartiesById: 'casemanagement/casedetails/caseparties',
      getCaseWorkerById: 'casemanagement/casedetails/caseworker',
      getCaseOutcomes: 'casemanagement/casedetails/caseoutcomes',
      postAddNonAdminRespondent: 'usermanagement/nonadminrespondents/add',
      sendInvitationRespondentNonAdmins: 'usermanagement/nonadmin/invite',
      getOpenCaseCount: 'casereporting/opencasecount',
      getCaseOwnerCount: 'casereporting/caseownercount',
      getMobileByEmail: 'caseworker/usercontact',
      postAddMobileNumber: 'caseworker/updateusercontact',
      getUserProfileDetails: 'usermanagement/caseworker/userprofile',
      getOrganisationId: 'usermanagement/user/organisations',
      getOutcomecount: 'caseworker/caseoutcomecount',
      getLatestBreakdown: 'casereporting/latestoutcomecount',
      getCaseCount: 'casereporting/casecount',
      postRecentCase: 'casereporting/recentcases',
      getCaseType: 'casereporting/casetypecount',
      getOmbudsmanAdminCount: 'usercountdashboard',
      getRespondentAdminCount: 'respondents/usercountdashboard',
      getIncidentID: 'casemanagement/casedetails/casepreference',
      postUserAction: 'usermanagement/respondentadmin/useraction',
      postOmbudsmanUserAction: 'usermanagement/ombudsmanadmin/useraction',
      postManageUser: 'usermanagement/respondentadmin/manageuser',
      postOmbudsmanManageUser: 'usermanagement/ombudsmanadmin/manageuser',
      postOrganisations: 'user/organisations',
      updatePrivacyPolicy: 'caseworker/userpolicy',
      getDownloadAllCases: 'casemanagement/caseexport',
      getFinaloutcomecount: 'casereporting/finaloutcomecount',
      individualId: 'respondents/individualid',
      newBusiness:'organisationname',
      createGroup:'nonadminrespondents/creategroup',
      zendeskForm:'communicationmanagemnt/getzendeskurlwebform',
      businessSearch:'organisationmanagement/ombudsmanadmin/onboardsearch',
      businessonboard:'organisationmanagement/ombudsmanadmin/onboard',
      loginRequest : 'sessionmanagement/signin',
      updateInactiveSession: 'sessionmanagement/refreshsession',
      ongoingActiveSession: 'sessionmanagement/verifytokenforresponse',
      logoutRequest: 'sessionmanagement/signout',
      getUserNotificationCount:'notification/getusernotificationcount',
      getAlluserNotification:'notification/getallusernotifications',
      getLatestUserNotifications:'notification/getlatestusernotifications',
      updateSelectedNotificationAsRead:'notification/updateselectednotificationasread',
      updateLatestUserNotificationsAsRead:'notification/updatelatestusernotificationsasread',
      deleteSelectedNotification:'notification/deleteselectednotification',
      updateNotification:'notification/updatefuncnotification',
      getCaseExportForDownload:'notification/caseexportfordownload',
      offBoardSearch:'organisationmanagement/ombudsmanadmin/offboardSearch',
      offBoardOrg:'organisationmanagement/ombudsmanadmin/offboardorganisation',
      addOrganisationManageUser : 'usermanagement/manageUser/addOrganisationSearchRespondent',
      addOrganisationOmbudsmanManageUser : 'usermanagement/manageUser/addOrganisationSearchOmbudsman',
      addOrganisationConfirmRespondent : 'usermanagement/manageUser/addOrganisationConfirmRespondent',
      addOrganisationConfirmOmbudsman : 'usermanagement/manageUser/addOrganisationConfirmOmbudsman',
      addOrganisationRespondent :'usermanagement/manageUser/addOrganisationRespondent',
      addOrganisationOmbudsman : 'usermanagement/manageUser/addOrganisationOmbudsman',
      removeOrgConfirmRespondent : 'usermanagement/manageUser/removeOrganisationConfirmRespondent',
      removeOrgConfirmOmbudsman : 'usermanagement/manageUser/removeOrganisationConfirmOmbudsman',
      removeOrganisationRespondent : 'usermanagement/manageUser/removeOrganisationRespondent',
      removeOrganisationOmbudsman : 'usermanagement/manageUser/removeOrganisationOmbudsman'
  }
}