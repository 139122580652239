import React, { useEffect ,useState} from "react";
import { Link } from 'react-router-dom';
import { DRUPALSERVER } from "../common/drupal-api/drupal-api-urls";
import DrupalService from "../common/drupal-api/drupal-api-service";
import { makeStyles } from "@mui/styles";
import { PopupCookies } from './PopupCookies';
import { staticCookiesData } from '../services/utils';
import CommonService from '../common/api/common-api-service';
import { SERVER } from '../common/api/api-urls';
import { errorComponentToast, drupalForErrors } from '../common/ErrorComponent/ErrorComponent';
import ShowFeatureFlag from '../common/featureFlags/ShowFeatureFlag';
import { useMsal } from '@azure/msal-react';
import { useSelector, shallowEqual } from 'react-redux';
import { rolesHelper, oidHelper } from "../common/helper/_serviceHelper";
import repissue from '../assets/icons/iconFlagMUP.svg'

const commonApi = new CommonService();
const drupalApi = new DrupalService();
const useStyles = makeStyles({
  footer: {
    height: "auto",
    backgroundColor: "#F5F5F5",
    background: "#EDEDED 0% 0% no-repeat padding-box",
    boxShadow: "inset 0rem 0.125rem 0.313rem #0000001A",
    opacity: "1",
  },
  foo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "5%",
    color: "#404040",
    fontFamily: "AssistantSemiBol",
    fontSize: "1.375rem",
    width: "100%",
    opacity: "1",
  },
  reportBtn1: {
    backgroundColor: "#f7f7f7",
    border: "0.063rem solid #c6c6c6",
    borderRadius: "1.5rem",
    height: "3rem",
    width: "12.875rem",
    color: "#0B48AC",
    fontFamily: "AssistantMedium",
    fontSize: "1.2rem",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    paddingTop: "0",
    paddingBottom: "0.313rem",
    cursor: "pointer",
  },
  footerLinks1: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "1.3rem",
    fontFamily: "AssistantSemiBold",
    color: "#271C6F",
    "& li": {
      listStyleType: "none",
      padding: "0 0 1rem 0",
      fontSize: "1.375rem",
      color: "#271C6F",
      fontFamily: "AssistantSemiBold",
    },
    "& li:hover": {
      textDecoration: "underline",
    },
    "& li a": {
      textDecoration: "none",
      fontSize: "1.375rem",
      fontFamily: "AssistantSemiBold",
      color: "#271C6F",
      "&:focus": {
        textDecoration: "underline",
      },
    },
    "& ul": {
      paddingLeft: "0",
      marginRight: "7.188rem",
      marginTop: "0",
      marginBottom: "0",
    },
  },
  footerRightText: {
    color: "#404040",
    fontFamily: "AssistantRegular",
    fontSize: "1.25rem",
  },
  footerSingIn: {
    paddingLeft: "5rem",
    paddingRight: "7rem",
    backgroundColor: "#EDEDED",
  },
  issueLogo: {
    marginRight: "0.75rem",
    paddingTop: "0.313rem",
  },
  footerTextLeft: {
    marginTop: "0rem",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#404040",
    fontFamily: "AssistantSemiBold",
  },
  textForBtn: {
    margin: "0",
    color: "#271C6F",
  },
  footerContainter: {
    padding: "3.5rem 0",
  },
  divContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1.563rem",
  },
  compatabilityContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "0rem",
    marginBottom: "1.5rem",
    paddingBottom: "1.5rem",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#404040",
    fontFamily: "AssistantSemiBold",
  },
  reportbtnContainer: {
    minWidth: "24.313rem",
  },
});


export default function Footer(props: {
  footerPrivacyPolicyFN: (value: boolean) => void;
}) {
  const classes = useStyles();
  const [drupalData, setDrupalData] = useState<string[][]>([]);
  const [footerData, setFooterData] = useState<string[]>([]);
  const [footerCookiePopupShow,setFooterCookiePopupShow] = useState(false);
  const [drupalCookiesData, setDrupalCookiesData] = useState(staticCookiesData);
  // eslint-disable-next-line
  const [_isBannerOpen, setBannerState] = useState(true);
  const [featureFlagReport, setFeatureFlagReport] = useState<boolean>(false);
  const [roles, setRoles] = useState<any>([]);
	const [userId, setUserId] = useState<string>('');
	const { accounts } = useMsal();
  const compatabilityMessages = {
    footermessage:"For best user experience, please use latest version of Chrome, Edge or Safari browser on your laptop or desktop only.",
    flashmessage:"For best user experience, please use latest version of Chrome, Edge or Safari browser on your laptop or desktop only.",
    dismissButton: "Dismiss"
  }
  const [compatabilityMsg, setCompatabilityMsg] = useState(compatabilityMessages);
  const featureFlagRedux:any = useSelector((store:any)=>store.featureFlagReducer.featureFlagData);
	const stateDigitalPortal: IDigitalPortal = useSelector(
		(state: DigitalPortalState) => state.stateDigitalPortal,
		shallowEqual
	  );
	useEffect(() => {
		
			setRoles(rolesHelper(accounts));
		
			setUserId(oidHelper(accounts));
		
	},[]);
  const handleCookies = () => {
    drupalForCookies();
    setFooterCookiePopupShow(true)
  };
  const drupalForCookies = () => {
    drupalApi
        .httpConnection(DRUPALSERVER.SERVICES.getCookies, 'get')
        .then((res) => {
            if (res.length > 0) {
              setDrupalCookiesData(res);
            } else {
              setDrupalCookiesData(staticCookiesData);
            }
        })
        .catch(() => {
          setDrupalCookiesData(staticCookiesData);
        }); 
};



useEffect(() => {
  let responseStatic = [
    [
      "Accessibility",
      "https://www.financial-ombudsman.org.uk/accessibility",
      "Privacy notice",
      "https://www.financial-ombudsman.org.uk/privacy-policy",
      "Data protection and SARs",
      "https://www.financial-ombudsman.org.uk/data-protection-sars",
      "Corporate information",
      "https://www.financial-ombudsman.org.uk/corporate",
      "Freedom of information",
      "https://www.financial-ombudsman.org.uk/freedom-of-information",
      "The modern slavery act",
      "https://www.financial-ombudsman.org.uk/modern-slavery-act",
      "Legal info",
      "https://www.financial-ombudsman.org.uk/legal-policy",
      "Procurement",
      "https://www.financial-ombudsman.org.uk/procurement",
      "Cymraeg",
      "https://www.financial-ombudsman.org.uk/cymraeg",
      "Cookies",
      "https://www.financial-ombudsman.org.uk/cookies",
      "Terms of use",
      `https://portal-${window?.REACT_APP_ENV}.financial-ombudsman.org.uk/foscontent/Terms-of-use.pdf`,
      "Privacy Policy",
      "https://",
    ],
  ];
  drupalApi.httpConnection(DRUPALSERVER.SERVICES.getHeader, "get")
    .then((res: any) => {
      if (res.length > 0) {
        const result = res[0].footer_menus.split(",");
        let response: any = [];
        response.push(result);
        setDrupalData(response);
      } else {
        setDrupalData(responseStatic);
      }
    })
    .catch(() => {
      setDrupalData(responseStatic);
    });
    drupalApi.httpConnection(DRUPALSERVER.SERVICES.getCompatabilityMessages, "get")
    .then((res: any) => {
      if (res.length > 0) {
        setCompatabilityMsg(res);
      } else {
        setCompatabilityMsg(compatabilityMessages);
      }
    })
    .catch(() => {
      setCompatabilityMsg(compatabilityMessages);
    });
  drupalApi.httpConnection(DRUPALSERVER.SERVICES.getFooter, "get")
  .then((res: any) => {
    let response: any = [];
    response.push(res[3].body, res[2].body, res[0].body, res[1].body);
    setFooterData(response);
  })
  .catch(() => {
    setFooterData([
      "© 2023 Financial Ombudsman Service",
      "Digital portal for financial\nombudsman Service",
      "Release PLT",
      "Report an issue",
    ]);
  });
  // eslint-disable-next-line
}, []);

useEffect(() => {
  if(featureFlagRedux.length > 0) {
    const zendeskFeature = ShowFeatureFlag('Zendesk', roles, stateDigitalPortal.orglist, userId,featureFlagRedux)
    setFeatureFlagReport(zendeskFeature);
  }else {
    setFeatureFlagReport(false);
  }
  
  // eslint-disable-next-line
}, [featureFlagRedux.length]); 
  
  const jwttoken = () => {
    
    commonApi
    .httpConnection(SERVER.SERVICES.zendeskForm, 'get' )
    .then((res) => {
      window.open(res?.url)
    })
    .catch((e) => {
      errorComponentToast(e.data, 'An error occured')
    });
  }

  let keyList = 0;
  return (
    <footer id='footer' className={classes.footerSingIn}>
      <div className={classes.footerContainter}>
        <div className={classes.divContainer}>
          <div>
            <h2 className={classes.footerTextLeft}>{footerData[0]}</h2>
          </div>
          <div>
            <span className={classes.footerRightText}>
              {" "} 
              {footerData[1]} <br />
              {footerData[2]}
              <span>VDP15.00</span>
              
            </span>
          </div>
        </div>
        <div className={classes.divContainer}>
          <div className={classes.footerLinks1}>
            {drupalData.map((data: any, index: number) => {
              return (
                <div key={keyList++}>
                  <ul key={`${"first"}${index}`}>
                    <li>
                      <Link to={data[1]} target="_blank">{data[0]} </Link>
                    </li>
                    <li>
                      <Link to={data[7]} target="_blank">{data[6]}</Link>
                    </li>
                    <li>
                      <Link to={data[13]} target="_blank">{data[12]} </Link>
                    </li>
                    <li>
                       {/* eslint-disable-next-line */}
                      <a href='#' onClick={()=>{window.open(data[21],"_blank")}}>{data[20]}</a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line */}
                      <a
                        href='#'
                        onClick={() => {
                          props.footerPrivacyPolicyFN(true);
                        }}
                      >
                        {data[22]}
                      </a>
                    </li>
                  </ul>
                </div>
              );
            })}
            {drupalData.map((data: any, index: number) => {
              return (
                <div key={keyList++}>
                  <ul key={`${"second"}${index}`}>
                    <li>
                      {/* eslint-disable-next-line */}
                      <a href='#' onClick={handleCookies}>{data[18]} </a>
                    </li>
                    <li>
                      <Link to={data[9]} target="_blank">{data[8]} </Link>
                    </li>
                    <li>
                      <Link to={data[15]} target="_blank">{data[14]} </Link>
                    </li>
                    <li>
                      <Link to={data[11]} target="_blank">{data[10]} </Link>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
          {featureFlagReport && 
          <div className={classes.reportbtnContainer}>
          <button
                         className={classes.reportBtn1}
                         onClick={jwttoken}
                         aria-label={'Report an issue opens in new tab'}
                     >
                         <img src={repissue} className={classes.issueLogo} alt='flag logo' />
                         <span
                             aria-label='Link for reporting an issue opening in a new tab.'
                             className={classes.textForBtn}
                         >
                             {footerData[3]}{' '}
                         </span>
                     </button> 
       </div>}        
                    
        </div>
      </div>
      <PopupCookies
                show={footerCookiePopupShow}
                setShow={setFooterCookiePopupShow}
                drupalDataCookies={drupalCookiesData}
                setBannerState={setBannerState}
            />
      {compatabilityMsg && <div className={classes.compatabilityContainer}>
        <label>{compatabilityMessages.flashmessage}</label>
      </div>}
    </footer>
  );
}
