import {useEffect, useState} from 'react'
import infoIcon from '../../assets/icons/Info2.svg'
import {makeStyles} from '@mui/styles';
import expand from '../../assets/icons/Expand_Icon_All.svg';
import IndividualUploadStatus from './IndividualUploadStatus';
import ChevronUp from '../../assets/icons/ChevronUp.svg';
import ChevronDown from '../../assets/icons/ChevronDown.svg';
import { DRUPALSERVER } from '../../common/drupal-api/drupal-api-urls';
import DrupalService from '../../common/drupal-api/drupal-api-service';
import CommonService from '../../common/api/common-api-service';
import { UPLOAD_SERVER } from '../../common/upload-api/api-urls';
import Pagination from './Pagination';


const defaultDrupalData = [
    {  
    "title":"Track upload file status",
    "description":"You can see and work on all the cases which are tagged to your organization here.",
    "caseNo":"Case Reference No.",
    "dateTime":"Date \u0026amp; time of submission",
    "status":"Upload status",
    "collapse":"Collapse all",
    "uploadfiles":"files",
    "virusInProgress":"Virus scan in progress",
    "virusComplete":"Virus scan completed",
    "inProgress":"In progress",
    "docName":"Document Name",
    "scanStatus":"Virus scan status",
    "uploadclean":"Passed",
    "infected":"Infected",
    "uploadPending":"In progress",
    "uploadCompleted":"Uploaded",
    "uploadFailed":"Failed",
    "proceedBtn":"Proceed to upload clean files",
    "uploadAll":"Upload all files again",
    "uploadNA":"Not applicable"
    }
];
const drupalApi = new DrupalService();
const commonApi = new CommonService('upload');

const TrackUploads = (prop:any) => {
    useEffect(() => {
        document.title = "Financial Ombudsman Portal - Track uploads"
    }, []);
    const classes = useStyles();
    const [allexpanded, setAllExpanded] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [drupalData, setDrupalData] = useState<any>(defaultDrupalData);
    const [fileStatus, setFileStatus] = useState<any>([]);

    useEffect(() => {
        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getTrackUploadPage, 'get')
            .then((res) => {
                if (res.length > 0) {
                    setDrupalData(res);
                  } else {
                    setDrupalData(defaultDrupalData);
                  }
            })
            .catch((_e: any) => {
                setDrupalData(defaultDrupalData);
            });
    }, []);

    useEffect(()=>{
        commonApi
        .httpConnection(`${UPLOAD_SERVER.SERVICES.getPackageStatus}`, 'get')
            .then((res) => {
                if (res?.status =='OK') {
                    setFileStatus(res?.data)
                }
            })
            .catch((_e: any) => {
                console.error(_e);
            });
    },[]);

    
  useEffect(() => {
    prop.selected("/track-uploads");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    
    const [sorted, setSorted] = useState<boolean>(false);

    function parseDMYhm(s: string) {
        let b = s.split(/\D/);
        return new Date(parseInt(b[0]), parseInt(b[1]), parseInt(b[2]),  parseInt(b[3]), parseInt(b[4])).valueOf();
    }

    const sortAscending = () => {
        setSorted(true);
        let sortedFiles =  [...fileStatus].sort((a: any, b: any) => {
            if (a.created && b.created) {
              return parseDMYhm(a.created) - parseDMYhm(b.created);
            } 
            else {
              return a;
            }})
        setFileStatus(sortedFiles)
    }

    const sortDescending = () => {
        setSorted(false);
        let sortedFiles =  [...fileStatus].sort((a: any, b: any) => {
            if (a.created && b.created) {
              return parseDMYhm(b.created) - parseDMYhm(a.created);
            } 
            else {
              return a;
            }})
        setFileStatus(sortedFiles)
    }

    let arr = [];
    for (let i = 0; i < fileStatus?.length; i++) {
        arr.push(<IndividualUploadStatus fileStatus={fileStatus[i]} allExpanded={allexpanded} />);
    }

    const handleExpandAll = () => {
        setAllExpanded(!allexpanded);
    }

    const indexOfLastPost = currentPage * 5;
    const indexOfFirstPost = indexOfLastPost - 5;
    const currentPosts = arr.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    
    return (
        <div>
            <h1 className = {classes.trackTitle}>{drupalData[0].title}</h1>
            <div className = {classes.subtitleContainer}>
                <img className = {classes.infoIcon} src={infoIcon} alt=""/>
                <p className = {classes.subtitle}>{drupalData[0].description}</p>
            </div>
            <div data-testid={'caseUploadTable'} className = {classes.tableContainer} role="table">
               <div role="rowgroup">
                <div className = {classes.headers} role="row">
                    <div className = {classes.heading} role="columnheader">{drupalData[0].caseNo}</div>
                    <div className = {classes.dateHeaderContainer}>
                    <p aria-sort={sorted? 'ascending':'descending'} className = {classes.heading}  role="columnheader">{drupalData[0].dateTime?.replace('&amp;','&')}</p>
                    <div className = {classes.sortButtonsContainer} > 
                        <button onKeyDown={(e)=>{
                            if(e.code==='Space' || e.code==='Enter'){
                                sortAscending();
                            }
                        }} onClick = {sortAscending} className = {classes.sortButton}><img role="button" aria-pressed={sorted} aria-label="Sort in ascending order" src={ChevronUp} alt=""/></button>
                        <button onKeyDown={(e)=>{
                            if(e.code==='Space' || e.code==='Enter'){
                                sortDescending();
                            }
                        }} onClick = {sortDescending} className = {classes.sortButton} ><img role="button" aria-pressed={!sorted} aria-label="Sort in descending order" src={ChevronDown} alt=""/></button>
                    </div>
                    </div>
                    
                    <div>
                        <p className = {classes.heading} role="columnheader">{drupalData[0].scanStatus}</p></div>
                        <div>
                        <p className = {classes.heading} role="columnheader">{drupalData[0].status}</p></div>
                        <div>
                        {allexpanded ? 
                            <div className={classes.dateHeaderContainer}>
                                <p className = {classes.heading} role="columnheader">{drupalData[0].collapse}</p>
                                <img aria-label="collapse-all" role="button" tabIndex={0} className = {classes.collapseIcon} src={expand} alt="" onClick = {handleExpandAll} 
                                onKeyDown={(e)=>{
                                    if(e.code==='Enter' || e.code==='Space'){
                                        handleExpandAll()
                                        }}}/>
                            </div> :  
                            <div className={classes.dateHeaderContainer}>
                                <p className = {classes.heading} role="columnheader">{drupalData[0].expands}</p>
                                <img aria-label="expand-all" role="button" tabIndex={0} className = {classes.expandIcon} src={expand} alt="" onClick = {handleExpandAll} 
                                onKeyDown={(e)=>{
                                    if(e.code==='Enter' || e.code==='Space'){
                                        handleExpandAll()
                                        }}}/>
                            </div>
                        }
                        </div>
                   
                </div>
                </div>
                {currentPosts}
            </div>
            <Pagination
                currentPage={currentPage}
                totalCount={arr.length}
                pageSize={5}
                onPageChange={paginate}
            />
            
        </div>
    )
}

export default TrackUploads

const useStyles = makeStyles({
    dateHeaderContainer: {display: 'flex', alignItems: 'center'},
    sortButtonsContainer: 
    {
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '1rem'
    },
    sortButton: {
        background: 'none',
        border: 'none'
    },
    expandBtnStyling: {
        border: 'none',
        background: 'none',
        padding: '0'
    },
    trackTitle: {
        font: '2.5rem AssistantSemiBold',
        color: '#292957',
        marginBlockStart: '0',
        marginBlockEnd: '1rem'
    },
    subtitleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2rem'
    },
    infoIcon: {
        width: '20px',
    },
    subtitle: {
        font: '1.375rem AssistantRegular',
        color: '#404040',
        marginLeft: '0.5rem',
        marginBlock: 0
    },
    tableContainer: {
        border:'1px solid #EAEAEA'
    },
    headers: {
        backgroundColor: 'rgb(198, 198, 198, 0.18)',
        padding: '1.25rem 1.5rem',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 0fr',
        justifyContent: 'start',
        alignItems: 'center',
        textWrap: 'nowrap',
        '@media (max-width: 925px)': {
            display: 'block'
        }
    },
    flexedHeaders: {
        display: 'flex', 
        justifyContent: 'space-between',
        '@media (max-width: 925px)': {
            display: 'block'
        }
    },
    expandIcon: {
        marginLeft: '0.75rem',
        '@media (max-width: 925px)': {
            marginLeft: '0'
        }
    },
    collapseIcon: {
        transform: 'rotate(180deg)',
        marginLeft: '0.75rem',
        '@media (max-width: 925px)': {
            marginLeft: '0'
        }
    },
    heading: {
        color: '#404040',
        font: '1.5rem AssistantSemiBold',
        marginBlock: '0',
        '@media (max-width: 925px)': {
            marginBottom: '0.8rem'
        }
    },
    casecountselect: {
        display: 'flex',
        alignItems: 'center'
    },
    showCasesTitle: {
        font: '1.25rem AssistantSemiBold',
        color: '#404040',
        marginBlock: '0',
        marginRight: '1rem'
    },
    selectCount: {
        font: '1.25rem AssistantRegular',
        color: '#404040',
        width: '5rem',
        height: '2.75rem',
        padding: '0.5625rem 1rem',
        border: '1px solid #CFCFCF',
    },
    verticalLine:{
        borderRight: '1px solid #DADADA',
        borderLeft: '1px solid #DADADA',
        height: '100%',
    },
    showing: {
        font: '1.25rem AssistantSemiBold',
        color: '#404040',
        padding: '0 4.75rem 0 3rem',
        '@media (max-width:934px)':{
            padding: '0 1.5313rem'
        }

    },
    indexes: {
        color: '#000'
    },
})