import * as React from 'react';
import { makeStyles } from "@mui/styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import infoIcon from '../../assets/icons/dashboardInfo.svg';
import { Checkbox, FormGroup } from '@mui/material';
import {useState,useEffect} from 'react'
import { DRUPALSERVER } from '../../common/drupal-api/drupal-api-urls';
import DrupalService from '../../common/drupal-api/drupal-api-service';
import CommonService from '../../common/api/common-api-service';
import { SERVER } from '../../common/api/api-urls';

import moment from 'moment';


const defaultDrupalData =[
  {
      "notification_all": "See All Notifications",
      "marksAsRead": "Mark as Read",
      "deleteNotification": "Delete",
      "downloadnotification": "the file should be available for download till the mid-night of the same day. up to 24 hrs from the time it is first available for download",
      "notification_title": "Notification",
      "notification_viewall": "View all",
      "notification_selectall": "Select all",
      "notification_fileready": "Your files are ready to be downloaded",
      "notification_read": "Read",
      "notification_unread": "Unread",
      "notification_readMore": "Read more...",
      "notification_delete_selected": "Delete Selected",
      "notification_new": "New notification",
      "Notification_available": "We are getting your files ready for download. You can download them from Available for download in Case details",
      "notification_selected": "Marks selected as read.",
      "title":"New Notification",
        "description":"We are getting your files ready for download. You can download them from Available for download in Case details.",
       "close_button":'Close',
      "caseExport": "Case list exported file is ready for download, please navigate to viewcaselist menu and click on available for download to download the file",
      "caseDownload":"Case files are available for download for PNX",
      "caseOnboarding":"Account onboarded",
      "caseOffboarding":"Account offboarded",
      "caseExportDescription": "Please click on Available for Download link on View Case List screen.",
"caseDownloadDescription":"Please click on Available for Download link on View Case Details screen.",
"caseOnboardingDescription":"Please view onboarded organisation in intiate onboarding screen",
"caseOffboardingDescription":"Organisation is no longer available in initiate onboarding screen",
"caseExportFail": "Case list exported file is failed for download",
"caseDownloadFail":"Case files are failed for download for PNX",
"caseOnboardingFail":"Respondent Organisation Name onboarding failed",
"caseOffboardingFail":"Respondent Organisation Name offboarding failed",
"caseExportDescriptionFail": "Sorry for the inconvenience caused.",
"caseDownloadDescriptionFail":"Sorry for the inconvenience caused.",
"caseOnboardingDescriptionFail":"Sorry for the inconvenience caused.",
"caseOffboardingDescriptionFail":"Sorry for the inconvenience caused.",
"notificationpagesubheading":"All notifications are listed below"
  }
  ]

const drupalApi = new DrupalService();
const commonApi = new CommonService();


const NotificationPage = () => {
    const classes = useStyles();
    const [filterRead,setFilterRead] = useState(false)
    const [checkedItems,setCheckedItems] = useState([])
    const [drupalData,setDrupalData] = useState<any>(defaultDrupalData)
    const [notificationsList,setNotificationsList] = useState<any>([])

const filteredNotifications = filterRead?notificationsList.filter((eachItem) =>eachItem.notification_status_id=="3"||eachItem.notification_status_id=="7" || eachItem.notification_status_id=="8"):notificationsList.filter((eachItem) =>eachItem.notification_status_id!="3" && eachItem.notification_status_id!="7" && eachItem.notification_status_id!="8" )
       
    useEffect(() => {
      drupalApi
          .httpConnection(DRUPALSERVER.SERVICES.getNotificationPage, 'get')
          .then((res) => {
            
              if (res.length > 0) {
                  setDrupalData(res);
              } else {
                  setDrupalData(defaultDrupalData);
              }
          })
          .catch((_e: any) => {
              setDrupalData(defaultDrupalData);
          });
          // eslint-disable-next-line

  }, []);
  useEffect (()=>{
    commonApi
    .httpConnection(SERVER.SERVICES.getAlluserNotification,'get')
    .then((res) => {
            if(res?.length>0){
              //
              let formedMsg = ""
              let formedDesc=""
              let drupalMsg = ""
              res.map((eachItem,index) =>{
                  switch(eachItem.requesting_activity_name){
                      case "caseExport":
                        if(res[index].notification_status_id==2 || res[index].notification_status_id==7){ 
                        formedMsg=drupalData[0]?.caseExport;
                        formedDesc=drupalData[0]?.caseExportDescription
                        }else if(res[index].notification_status_id==5 || res[index].notification_status_id==8){
                          formedMsg=drupalData[0]?.caseExportFail;
                          formedDesc=drupalData[0]?.caseExportDescriptionFail
                        }else{
                          formedMsg=drupalData[0]?.caseExport;
                          formedDesc=drupalData[0]?.caseExportDescription
                        }
                        res[index].formedMsg = formedMsg
                        res[index].formedDesc=formedDesc
                        break
                        case "caseDownload":
                          if(res[index].notification_status_id==2 || res[index].notification_status_id==7){ 
                            drupalMsg = drupalData[0]?.caseDownload
                            formedDesc=drupalData[0]?.caseDownloadDescription
                          }else if(res[index].notification_status_id==5 || res[index].notification_status_id==8){
                            drupalMsg = drupalData[0]?.caseDownloadFail
                            formedDesc=drupalData[0]?.caseDownloadDescriptionFail
                          }else{
                            drupalMsg = drupalData[0]?.caseDownload
                            formedDesc=drupalData[0]?.caseDownloadDescription
                          }
                        drupalMsg = drupalMsg.replace("PNX",res[index].message)
                        formedMsg=drupalMsg;
                        res[index].formedMsg = formedMsg
                        res[index].formedDesc=formedDesc
                        break
                        case "caseOnboarding":
                          if(res[index].notification_status_id==2 || res[index].notification_status_id==7){
                            drupalMsg = drupalData[0]?.caseOnboarding
                            formedDesc=drupalData[0]?.caseOnboardingDescription
                          }else if(res[index].notification_status_id==5 || res[index].notification_status_id==8){
                            drupalMsg = drupalData[0]?.caseOnboardingFail
                            formedDesc=drupalData[0]?.caseOnboardingDescriptionFail
                          }else{
                            drupalMsg = drupalData[0]?.caseOnboarding
                            formedDesc=drupalData[0]?.caseOnboardingDescription
                          }
                            drupalMsg = drupalMsg.replace("Name",res[index].message)
                            formedMsg=drupalMsg;
                            
                        res[index].formedMsg = formedMsg
                        res[index].formedDesc=formedDesc
                        break
                        case "caseOffboarding":
                          if(res[index].notification_status_id==2 || res[index].notification_status_id==7){
                             drupalMsg = drupalData[0]?.caseOffboarding
                             formedDesc=drupalData[0]?.caseOffboardingDescription
                          }else if(res[index].notification_status_id==5 || res[index].notification_status_id==8){
                            drupalMsg = drupalData[0]?.caseOffboardingFail
                             formedDesc=drupalData[0]?.caseOffboardingDescriptionFail
                          }else{
                            drupalMsg = drupalData[0]?.caseOffboarding
                             formedDesc=drupalData[0]?.caseOffboardingDescription
                          }
                            drupalMsg = drupalMsg.replace("Name",res[index].message)
                            formedMsg=drupalMsg;
                            
                        res[index].formedMsg = formedMsg
                        res[index].formedDesc=formedDesc
                        break
                        default:
                            res[index].formedMsg= res[index].message
                            res[index].formedDesc=formedDesc
                  }
              })
              //
              setNotificationsList(res)
            }
    })
    .catch((e) => {
      console.warn(e)
      });
    
  },[])

    const handleMarkAsRead = (item:any) =>{
      const tempFormedMsg = item.formedMsg
      const tempFormedDesc = item.formedDesc
      // delete item.formedMsg;
      // delete item.formedDesc;

      const payload = {
        "notification_id": item.notification_id,    
        "notification_status_id": item.notification_status_id,
        "notification_status_description": item.notification_status_description
    };

      commonApi
      .httpConnection(SERVER.SERVICES.updateSelectedNotificationAsRead,'post',payload)
      .then((res) => {
        if(res){
          setNotificationsList(prevNotifications =>{
            const updatedItems = prevNotifications.map(obj=>
              obj.notification_id == item.notification_id ? {...obj, notification_status_id: "3",formedMsg:tempFormedMsg,formedDesc:tempFormedDesc}:obj)
              return updatedItems
          })
        }
      })
      .catch((e) =>{
        console.warn(e)
      })
    }

    const handleMarkAsDelete = (item:any) =>{
      // delete item.formedMsg;
      // delete item.formedDesc;
      const payload = [{
        "notification_id": item.notification_id,  
        "notification_status_id": item.notification_status_id,
        "notification_status_description": item.notification_status_description  
      }];

      commonApi
    .httpConnection(SERVER.SERVICES.deleteSelectedNotification,'post',payload)
    .then((res) => {
            if(res?.result){
              setNotificationsList(prevNotificationItems => {
                const updatedList = prevNotificationItems.filter((eachItem) =>
                eachItem.notification_id !=item.notification_id)
                return updatedList
              })
            }
    })
    .catch((e) => {
      console.warn(e)
      });
    }

    const handleSelectAllChange = (event:React.ChangeEvent<HTMLInputElement>,currentTab) =>{
      const checked = event.target.checked;
      checked ? setCheckedItems(filteredNotifications.map(eachItem =>eachItem)):setCheckedItems([])
    }

    const handleReadNotificationFilter = () =>{
      setCheckedItems([])
      setFilterRead(true)
    }

    const handleUnReadNotificationFilter = () =>{
      setCheckedItems([])
      setFilterRead(false)
    }

    const handleCheckBoxChange =(event:React.ChangeEvent<HTMLInputElement>,item:any)=>{
      const checked = event.target.checked;
      checked? setCheckedItems(prevSelectedNotif =>{
        return  [...prevSelectedNotif,item]
    }): setCheckedItems(prevSelectedNotif =>{
        const updatedList = prevSelectedNotif.filter(eachItem =>{
            return eachItem.notification_id!=item.notification_id
        })
        return updatedList
    })
    }

    const handleMarkSelectedAsRead = () =>{
      let tempList = []
      const payload = checkedItems.map(eachItem =>{
        tempList.push({notification_id:eachItem.notification_id,formedMsg:eachItem.formedMsg,formedDesc:eachItem.formedDesc})
        // delete eachItem.formedMsg
        // delete eachItem.formedDesc
        return {
          "notification_id": eachItem.notification_id,    
          "notification_status_id": eachItem.notification_status_id,
          "notification_status_description": eachItem.notification_status_description
      }
      })
      commonApi
      .httpConnection(SERVER.SERVICES.updateLatestUserNotificationsAsRead, 'post',payload)
      .then((res) => {
          if(res?.result){
            setNotificationsList((prevNotif) =>{
              const updatedNotifications = prevNotif.map(eachObj => {
                  if(checkedItems.some(obj => obj.notification_id==eachObj.notification_id)){
                      const obj = tempList.find(obj => obj.notification_id ==eachObj.notification_id)
                      return {...eachObj,notification_status_id:"3",formedMsg:obj?.formedMsg,formedDesc:obj?.formedDesc}
                  }
                  return eachObj
              })
              return updatedNotifications
          })
          setCheckedItems([])
          }
      })
      .catch((e) => {
      setCheckedItems([])
        console.warn(e);
      });
    }

    const handleDeleteSelected =() =>{
      //let tempList = []
      const payload = checkedItems.map(eachItem =>{
        //tempList.push({notification_id:eachItem.notification_id,formedMsg:eachItem.formedMsg,formedDesc:eachItem.formedDesc})
        // delete eachItem.formedMsg
        // delete eachItem.formedDesc
        return {
          "notification_id": eachItem.notification_id,  
          "notification_status_id": eachItem.notification_status_id,
          "notification_status_description": eachItem.notification_status_description  
        }
      })
      commonApi
      .httpConnection(SERVER.SERVICES.deleteSelectedNotification,'post',payload)
      .then((res) => {
              if(res?.result){
                setNotificationsList(prevNotificationItems => {
                  const updatedList = prevNotificationItems.filter((eachItem) =>
                  !checkedItems.some(obj =>obj.notification_id == eachItem.notification_id))
                  return updatedList
                })
                setCheckedItems([])
              }
      }
      )
      .catch((e) => {
        setCheckedItems([])
        console.warn(e)
        });
    }

    const isChecked = () =>{
      if(filteredNotifications?.length>0){
        return filteredNotifications.every(notif => checkedItems.some(checkedNotif =>checkedNotif.notification_id===notif.notification_id))
      }
      return false
    }

    const boldPNX=(name:string)=>{
      //console.log("enter name",name)
      const indexPNX = name.indexOf('PNX');
      if(indexPNX !== -1){
          const beforePNX = name.slice(0, indexPNX)
          const boldPart = name.slice(indexPNX, indexPNX + 15);
          return(
              <>
              {beforePNX}<strong style={{display:'inline'}}>{boldPart}</strong>{name.slice(indexPNX + 15)}
              </>
          )

      } else{
          return name;
      }
  }
    
    return (
      <>
        <div>
            <h1 className={classes.heading}>{drupalData[0].notification_title}</h1>
            <div className={classes.pageDescriptionContainer}>
            <img src={infoIcon} alt="" />
            <p className={classes.pageDescription}>
            {drupalData[0].notificationpagesubheading}
            </p>
        </div>
        </div>
        <div className={classes.notificationButtonContainer}>
          <div className={classes.notificationButtonContainercol}>
            <FormControlLabel 
            sx={{textAlign: 'left',
            font: 'normal normal 600 1.125rem AssistantRegular',
            letterSpacing: 0,
            textTransform: 'none',
            color: '#271C6F'}}
            control={<Checkbox checked={isChecked()} 
            onChange={(event) =>handleSelectAllChange(event,filterRead)} />} label={drupalData[0].notification_selectall} />
            
            <button onClick={handleUnReadNotificationFilter} className={filterRead?classes.inactive:classes.active} aria-pressed={!filterRead}> {drupalData[0].notification_unread}</button>
            <button onClick={handleReadNotificationFilter}  className={filterRead?classes.active:classes.inactive} aria-pressed={filterRead}>{drupalData[0].notification_read}</button>
          </div>
          <div className={classes.notificationButtonContainercol}>
            {!filterRead && <button onClick={handleMarkSelectedAsRead}
              className={classes.markAsReadButton} 
             >
               {drupalData[0].notification_selected}</button>}
            <button
              className={classes.markAsDeleteButton}
            onClick={handleDeleteSelected} >{drupalData[0].notification_delete_selected}</button>
          </div>
        </div>
        <div className={classes.notificationsContainer}>
          <FormGroup>
          {filteredNotifications.map(eachNotification =><div className={filterRead?classes.notificationReadText :classes.notificationText}>
            <FormControlLabel
              sx={{color:"#404040"}}
              key={eachNotification.notification_id}
              control={<Checkbox checked={checkedItems.some(item => item.notification_id==eachNotification.notification_id)} onChange={(event) =>handleCheckBoxChange(event,eachNotification)} />}
              label={eachNotification.formedMsg}
            />
            <p className={classes.notificationDetails}>{ eachNotification.formedDesc}  <span className={classes.readmoreLink}>{drupalData[0].notification_readMore}</span></p>
            <div className={classes.notificationRow3}>
              <p className={classes.duration}>{moment.utc(eachNotification.created_on).local().fromNow()}</p>
              <div className={classes.buttonContainer}>
            {!filterRead && <button
              className={classes.markAsReadButton} 
              onClick={() =>handleMarkAsRead(eachNotification)}
              >{drupalData[0].marksAsRead}
              </button>}
              {!filterRead && <hr className={classes.verticalLine}></hr>}
              <button 
              onClick={() =>handleMarkAsDelete(eachNotification)}
              className={classes.markAsDeleteButton}
              >{drupalData[0].deleteNotification}
              </button>
            </div>

            </div>
            
             
          </div>)}
          </FormGroup>
        </div>
        </>
    )
}
const useStyles=makeStyles({
    heading:{
        font: '36px AssistantRegular',
        letterSpacing: 0,
        color: '#271C6F',
        opacity: 1
    },
      pageDescriptionContainer: {
        display: 'flex',
        paddingBottom: '1.25rem',
        borderBottom: '0.188rem solid #E8E8E8',
        marginBottom: '1.875rem',
      },
      pageDescription: {
        font: '1.25rem AssistantRegular',
        color: '#404040',
        marginLeft: '0.5rem',
        marginBlock: '0',
        fontWeight: 'normal'
      },
      notificationReadText:{
        maxWidth: '100%',
        display:'flex',
        flexDirection:'column',
        padding: '1.5rem',
        margin: 0,
        font: '1.125rem AssistantRegular',
        letterSpacing: '0px',
        backgroundColor:'#FFFFFF',
        borderBottom:'1px solid #CECECE',
        color: '#000000'
      },
    notificationText:{
        maxWidth: '100%',
        // display: '-webkit-box',
        // WebkitBoxOrient: 'vertical',
        // WebkitLineClamp: 2,
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        display:'flex',
        flexDirection:'column',
        padding: '1.5rem',
        margin: 0,
        font: '1.125rem AssistantRegular',
        letterSpacing: '0px',
        backgroundColor:'#F0F8FF',
        borderBottom:'1px solid #CECECE',
        color: '#000000'
      },
      notificationButtonContainer:{
        display:'flex',
        justifyContent:'space-between',
        gap:'10px',
        //marginBottom:'10px',
        paddingLeft:'1.5rem',
      },
      notificationButtonContainercol:{
        display:'flex',
        gap:'5px',
        alignItems:'center'
      },
      notificationDetails:{
        marginLeft:"25px",
        marginTop:'0px',
        color:'#404040'
      },
      buttonContainer:{
        alignSelf:"flex-end",
        display:'flex',
        marginTop:'0px',
        alignItems:'center',
      },
      verticalLine:{
        width:'1px',
        height:'1.2rem',
        border:'none',
        opacity:'1',
        backgroundColor:'#C6C6C6'
      },
      active:{
        color:'#D22D64',
        textDecoration:'underline',
        textDecorationThickness:'2px',
        textUnderlineOffset:'4px',
        font: 'normal normal 600 1.125rem  Assistant',
        opacity:'1',
        backgroundColor:'transparent',
        borderStyle:'none',
      },
      inactive:{
        textDecoration:'none',
        font: 'normal normal 600 1.125rem Assistant',
        letterSpacing:'0px',
        color: '#404040',
        opacity: '1',
        backgroundColor:'transparent',
        borderStyle:'none',
      },
      readmoreLink:{
        color:'#271C6F'
      },
      notificationRow3:{
        display:"flex",
        justifyContent:'space-between',
        marginLeft:'25px',
        marginTop:'0px',
        alignItems:'center'
      },
      notificationsContainer:{
      },
      duration:{
        color:'#000000'
      },
      markAsDeleteButton:{
              font: 'normal normal 600 1.125rem AssistantRegular',
              color:'#E00000',
              opacity:'1',
              border:'none',
              background:'transparent',
              cursor:'pointer',
              '&:focus':{
                border:'2px solid black'
              }
            },
      markAsReadButton:{
        textAlign: 'left',
             font: 'normal normal 600 1.125rem AssistantRegular',
             color: '#271C6F',
             border:'none',
              background:'transparent',
              cursor:'pointer',
             '&:focus':{
              border:'2px solid black'},
      }
      
})

export default NotificationPage
