import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from 'redux';
import {useNavigate, useLocation} from "react-router-dom";
import FileUpload from '../../common/component-lib/FileUpload';
import FileUploadStepper from './FileUploadStepper';
import { makeStyles } from '@mui/styles';
import needHelpIcon from '../../assets/icons/need_help_icon.svg';
import info from '../../assets/icons/WhiteInfo.svg';
import UploadProgressBar from '../../common/component-lib/UploadProgressBar';
import { DRUPALSERVER } from '../../common/drupal-api/drupal-api-urls';
import DrupalService from '../../common/drupal-api/drupal-api-service';
import {removeFile} from '../../stores/uploadFiles/UploadSlice';
import CommonService from '../../common/api/common-api-service';
import { UPLOAD_SERVER } from '../../common/upload-api/api-urls';
import { SERVER as OLDSERVER } from '../../common/api/api-urls';
import { updatePercentage } from '../../stores/uploadFiles/UploadProgressSlice';
import { updateBlobCount } from '../../stores/uploadFiles/UploadStatusSlice';

const drupalApi = new DrupalService();
const commonApi = new CommonService('upload');
const oldCommonApi = new CommonService();
const defaultDrupalData = [
    { 
        "needHelp": "Need help with uploading documents?", 
        "packageStatus": "Package upload status", 
        "sizeLimit": "100 MB", 
        "dragAndDrop": "Drag and drop to upload files here", 
        "or": "Or", 
        "browseFilesBtn": "Browse files", 
        "supportedType": "Document types supported: .PDF, .DOC, .DOCX, .CSV, .TXT, .XLSX, .XLS, .JPG, .JPEG, .PNG, .GIF, .MP3, .WMA, .AVI, .MP4, .ZIP", 
        "fileSize": "Maximum file size: 3 MBs for each document", 
        "backBtn": "Back to your case details", 
        "proceedBtn": "Proceed", 
        "completedLabel": "completed",
        "waitMsg": 'It is taking bit longer to upload your files. Please wait till all the files are uploaded' 
    }
];
export default function ViewCaseUploadDoc() {
    useEffect(() => {
        document.title = "Financial Ombudsman Portal - Upload documents"
    }, []);
    const classes = useStyles();
    const [files, setFiles] = useState<any>([]);
    const [uploadedFiles, setUploadedFiles] = useState<any>([]);
    const [packetSize, setPacketSize] = useState<number>(0);
    const [drupalData, setDrupalData] = useState<any>(defaultDrupalData);
    const [allowedFileTypes, setAllowedFileTypes] = useState<any>({
        "text/pdf": [".PDF",".DOC",".DOCX",".CSV",".TXT", ".XLSX", ".XLS"],
        "image/png": [".PNG", ".JPG", ".JPEG", ".GIF"],
        // "application/x-zip-compressed": [".ZIP", ".RAR"],
        "audio/mpeg": [".MPEG", ".WMA",".AVI", ".MP4", ".MP3", '.WAV'],
        "text/html":[".HTML",".HTM"],
        "message/rfc822" : [".EML",".MSG"],
                "application/vnd.ms-outlook": [".EML",".MSG"],
                "application/vnd.oasis.opendocument.text": [".ODT"],
                "image/tiff":[".TIF"],
                "application/rtf" : [".RTF"]
    });
    const [onScreenList, setOnScreenList] = useState(".PDF, .DOC, .DOCX, .CSV, .TXT, .XLSX, .XLS, .PNG, .JPG, .JPEG, .GIF, .MPEG, .WMA, .AVI, .MP4, .MP3, .WAV, .HTML, .HTM, .EML, .MSG, .ODT, .TIF, .RTF");
    let navigate = useNavigate();
    let uploaded = false;
    const { pathname, state } = useLocation();
    const stateValue: any = state;
    const incId = pathname.split('/');
    const dispatch: Dispatch<any> = useDispatch();
    const [caseDetails, setCaseDetails] = useState<any>([]);
    const [abortControllerObj, setAbortControllerObj] = useState(null);
    const [tempFiles, setTempFiles] = useState<string[]>([]);
    const setAbortControllerSharing = (abortController: any) =>{
        setAbortControllerObj(abortController);
    }
    const reduxFiles:any = useSelector((store:any)=>store.uploadFiles.files);

    useEffect(() => {
        window.scrollTo(0,0);
        getViewCaseDetails();
        drupalApi.httpConnection(DRUPALSERVER.SERVICES.getFileTypes, 'get')
        .then((res)=>{
            // res=[{"text-pdf": [".PDF",".DOC",".DOCX",".CSV",".TXT", ".XLSX", ".XLS"],
            // "image-png": [".PNG", ".JPG", ".JPEG", ".GIF"],
            // "application-x-zip-compressed": [".ZIP", ".RAR"],
            // "audio-mpeg": [".MPEG", ".WMA",".AVI", ".MP4", ".MP3", '.WAV'],
            // "text-html":[".HTML",".HTM"],
            // "message-rfc822" : [".EML",".MSG"],
            // "application-vnd.ms-outlook": [".EML",".MSG"],
            // "application-vnd.oasis.opendocument.text": [".ODT"],
            // "image-tiff":[".TIF"],
            // "application-rtf" : [".RTF"]}]
            let temp = {"text/pdf": [], "image/png": [], "audio/mpeg": [],"text/html":[],"message/rfc822":[],"application/vnd.ms-outlook":[],"application/vnd.oasis.opendocument.text":[],"image/tiff":[],"application/rtf":[]};
            temp["text/pdf"] = res[0]["text-pdf"].replaceAll(" ","").split(",");
            temp["image/png"] = res[0]["image-png"].replaceAll(" ", "").split(",");
            // temp["application/x-zip-compressed"] = res?.[0]?.["application-x-zip-compressed"]?.replaceAll(" ", "")?.split(",");
            temp["audio/mpeg"] = res[0]["audio-mpeg"].replaceAll(" ","").split(",");
            temp["text/html"] = res[0]["text-html"].replaceAll(" ","").split(",");
            temp["message/rfc822"] = res[0]["message-rfc822"].replaceAll(" ","").split(",");
            temp["application/vnd.ms-outlook"] = res[0]["application-vnd.ms-outlook"].replaceAll(" ","").split(",");
            temp["application/vnd.oasis.opendocument.text"] = res[0]["application-vnd.oasis.opendocument.text"].replaceAll(" ","").split(",");
            temp["image/tiff"] = res[0]["image-tiff"].replaceAll(" ","").split(",");
            temp["application/rtf"] = res[0]["application-rtf"].replaceAll(" ","").split(",");

            setAllowedFileTypes(temp);
            setOnScreenList(res[0]["text-pdf"]+", "+res[0]["image-png"]+", "+res[0]["audio-mpeg"]+", "+res[0]["text/html"]+", "+res[0]["message/rfc822"]+", "+res[0]["application/vnd.ms-outlook"]+", "+res[0]["application/vnd.oasis.opendocument.text"]+", "+res[0]["image/tiff"]+", "+res[0]["application/rtf"]);
        })
        .catch(()=>{
            setAllowedFileTypes({
                "text/pdf": [".PDF",".DOC",".DOCX",".CSV",".TXT", ".XLSX", ".XLS"],
                "image/png": [".PNG", ".JPG", ".JPEG", ".GIF"],
                // "application/x-zip-compressed": [".ZIP", ".RAR"],
                "audio/mpeg": [".MPEG", ".WMA",".AVI", ".MP4", ".MP3", '.WAV'],
                "text/html":[".HTML",".HTM"],
                "message/rfc822" : [".EML",".MSG"],
                "application/vnd.ms-outlook": [".EML",".MSG"],
                "application/vnd.oasis.opendocument.text": [".ODT"],
                "image/tiff":[".TIF"],
                "application/rtf" : [".RTF"]
            });
        });

        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getUploadMainPage, 'get')
            .then((res) => {
                if (res.length > 0) {
                    setDrupalData(res);
                } else {
                    setDrupalData(defaultDrupalData);
                }
            })
            .catch((_e: any) => {
                setDrupalData(defaultDrupalData);
            });
    }, []);
    function getViewCaseDetails() {
        const request = {
            'incidentid': incId[incId.length-1]
        }
        oldCommonApi.httpConnection(`${OLDSERVER.SERVICES.getCaseDetailsById}`, 'post', request)
            .then((res) => {
                setCaseDetails(res.casedetails.ticketnumber);
            })
            .catch((err) => {
                console.warn(err);
            });
    }

    
    uploaded=reduxFiles?.length > 0;

    const reduxPercentages:any = useSelector((store:any)=>store.uploadProgress.percentages);
    const reduxWaitStatus:boolean = useSelector((store:any)=>store.uploadStatusSlice.uploadWaitStatus)
    const deleteFile = (fileName: any) => {
        let temp = tempFiles.filter((tp:string)=>tp!==fileName);
        setTempFiles(temp);
        dispatch(removeFile({fileName:fileName,abortController:abortControllerObj}));
        dispatch(updatePercentage({name:fileName, percentage:0, documentId: '', uploadDone: false})); 
        if(reduxPercentages[fileName]?.percentage===100) dispatch(updateBlobCount(-1));
        const payloadForDelete = {
            "filePath" : sessionStorage.getItem('packageId') + "/" + fileName
        } 
        commonApi.httpConnection(UPLOAD_SERVER.SERVICES.postRemoveFile, 'post', payloadForDelete )
        .then(async (res) => {
            console.log(res)
        })
    }
    return (
        <div id='main-content' data-testid='parentDiv'>
            <FileUploadStepper step={1} pnx={caseDetails} />
            <hr className={classes.subDividerLine} />
            <div className = {reduxFiles.length !== 0 ? classes.fileUpload : classes.noFilesUploaded}>
                <div className={classes.linkHolder}>
                    <img className={classes.helpImage} src={needHelpIcon} alt='' />
                    <p className={classes.needHelpLink}><a href="#">{drupalData[0].needHelp}</a></p>
                </div>
                {(reduxWaitStatus)?<div style={{display:'flex',justifyContent: 'left', gap:'4px', backgroundColor: '#0F5FAE', textAlign: 'left', border: '1px solid #CEE1F1', padding: '1rem', font: '16px AssistantSemibold', color: '#FFFFFF', margin: '0 0 1rem 3rem'}}>
                    <img src={info} alt="" />
                <span>
                    {drupalData[0].waitMsg ? drupalData[0].waitMsg: 'It is taking bit longer to upload your files. Please wait till all the files are uploaded'}
                    </span>
                </div>:<></>}
                <div className={classes.fileUploadContainer}>
                    <FileUpload
                        noOfFiles={50}
                        packetSize={packetSize}
                        setpacketSize={setPacketSize}
                        maxSize={+drupalData[0].maxSize} 
                        allowedFiles = {allowedFileTypes}
                        files={files} setFiles={setFiles} 
                        removeFile={removeFile} 
                        setmainFiles = {setUploadedFiles} 
                        mainFiles = {reduxFiles}
                        setAbortControllerSharing = {setAbortControllerSharing}
                        tempFiles = {tempFiles}
                        setTempFiles = {setTempFiles}
                        onScreenList = {onScreenList}
                        />
                        { !uploaded && 
                            <div className = {classes.buttons}>
                                <button className = {classes.backCta} onClick = {() => navigate(`/view-cases-details/${incId[incId.length-1]}`)}>{drupalData[0].backBtn}</button>
                                <button disabled className = {classes.proceedBtn} onClick = {() => {navigate(`/view-cases-upload-preview`,{state:{'files':files,'pnx':stateValue?.pnx,'about':caseDetails,'incId':incId[incId.length-1]}})}}>{drupalData[0].proceedBtn}</button>
                            </div>
                        }
                </div>
                {uploaded && <div data-testid='progressBar' className = {classes.progressContainer}>
                    <UploadProgressBar files = {reduxFiles} deleteFile = {deleteFile} pnx={stateValue?.pnx} about={caseDetails} percentage={reduxPercentages}/> 
                </div>}
            </div>

        </div>
    )
}

const useStyles = makeStyles({
    linkHolder: {
        display: 'flex',
        alignItems: 'center'
    },
    helpImage: {
        height: '1.25rem',
        width: '1.25rem',
        marginTop: '2px'
    },
    proceedBtn: {
        backgroundColor: '#F7F7F7',
        color: '#767676B3',
        font: '1.25rem AssistantSemiBold',
        padding: '0.688rem 1.5rem',
        borderRadius: '1.5rem',
        border: '1px solid #767676B3',
        marginTop: '2.063rem',
        cursor: 'pointer',
        '@media (max-width: 480px)': {
            width: '100%'
        }
    },
    buttons: {
        float: 'right',
        marginTop: '2.063rem',
        '@media (max-width: 1000px)': {
            alignSelf: 'flex-start'
        }
    },
    subDividerLine: {
        backgroundColor: '#D4D4D4',
        height: '1px',
        border: 0
    },
    noFilesUploaded: {
        display: 'grid',
        marginTop: '0.5rem',
        gridTemplateAreas: `'helpLink uploadLimit'
        'fileUploadContainer fileUploadContainer'
        'backCta backCta'
        'helpGrid helpGrid'`,
        '@media (max-width: 1000px)': {
            display: 'block'
        }
    },
    fileUpload: {
        padding: '1rem 0rem',
        display: 'grid',
        marginTop: '0.5rem',
        gridTemplateColumns: '1.5fr 1fr',
        gridTemplateAreas: `'helpLink uploadCount uploadLimit'
                            'fileUploadContainer progressContainer progressContainer'
                            'backCta backCta backCta'
                            'helpGrid helpGrid helpGrid'` ,
        '@media (max-width: 1000px)': {
            display: 'block'
        }
    },
    topRow: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    helpLink: {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        gridArea: 'helpLink'
    },
    fileUploadContainer: {
        gridArea: 'fileUploadContainer'
    },
    progressContainer: {
        gridArea: 'progressContainer',
        marginLeft: '3.125rem',
        '@media (max-width: 1000px)': {
            marginLeft: '0'
        }
    },
    needHelpLink: {
        marginLeft: '0.3rem',
        color: '#271C6F',
        cursor: 'pointer',
        font: '1.25rem AssistantSemiBold',
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:focus-within': {
            textDecoration: 'underline'
        }
    },
    helpIcon: {
        color: '#D22D64'
    },
    uploadCount: {
        font: '1.375rem AssistantSemiBold',
        color: '#000',
        gridArea: 'uploadCount',
        marginLeft: '3.125rem',
        '@media (max-width: 1000px)': {
            marginLeft: '0',
            marginBlock: 0
        }
    },
    fileCount: {
        font: '1.375rem AssistantBold',
        color: '#000',
    },
    uploadLimit: {
        color: '#858585',
        font: '1.125rem AssistantRegular',
        marginBottom: 0,
        gridArea: 'uploadLimit',
        justifyContent: 'end',
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 1000px)': {
            justifyContent: 'flex-start'
        }
    },
    backCta: {
        padding: '0.688rem 1.5rem',
        border: '1px solid #D22D64',
        color: '#D22D64',
        font: '1.25rem AssistantSemiBold',
        width: 'fit-content',
        borderRadius: '30px',
        marginTop: '2rem',
        marginRight: '1.5rem',
        backgroundColor: '#fff',
        cursor: 'pointer',
        gridArea: 'backCta',
        '@media (max-width: 480px)': {
            width: '100%'
        },
        '&:hover': {
            backgroundColor: '#FAE5EC'
        }
    },
    helpGrid: {
        marginTop: '5.625rem',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '3.25rem',
        gridArea: 'helpGrid',
        '@media (max-width: 900px)': {
            display: 'block'
        }
    }
})