import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles({
    logoutTextbox: {
        fontFamily: 'AssistantSemiBold',
        fontSize: '1.375rem',
        color: '#404040',
        textAlign: 'center'
    },
    proceedButton: {
        fontFamily: 'AssistantSemiBold',
        fontSize: '1.1rem',
        background: '#D22D64 1% 1% no-repeat padding-box!important',
        border: '0.06rem solid #D22D64',
        borderRadius: '1.563rem',
        color: '#fff',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        textTransform: 'none',
        '&:focus': {
            border: '2px solid black'
        }
    },
})
export default function Logout() {
    const classes = useStyles();
    const navigate = useNavigate();
    useEffect(()=>{
        localStorage.setItem('tabsOpen','0');
    },[])
    return (
        <div className={classes.logoutTextbox}>
            <h1>You are logged out successfully!</h1>
            <br/>
            <br/>
            <Button
                disableRipple
                onClick={()=>{navigate('/'); window.location.reload()}}
                className={classes.proceedButton}
            >
                Login again
            </Button>
        </div>

    );
}

