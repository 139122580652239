import * as actionTypes from "./actionTypes"
import {  drupalForErrors } from "../common/ErrorComponent/ErrorComponent";

const initialState: ServerMesageState = {
    ServerMesagePortal:
        {
            azureconnectivity: "",
            phoenixconnectivity: "",
            organisationnotfound: "",
            cryptovalidationerror: "",
            messagenotreadable: "",
            networkerror: "",
            field_message_7: ""
        },
}

const serverMessageReducer = (
    state: ServerMesageState = initialState,
    action: ServerMesageAction
  ): ServerMesageState => {
    switch (action.type) {
      case actionTypes.SERVER_MESSAGE:
        const newArticle: ServerMessage = {
            azureconnectivity: action.ServerMesagePortal.azureconnectivity !=='' ?action.ServerMesagePortal.azureconnectivity:drupalForErrors.azureconnectivity,
            phoenixconnectivity: action.ServerMesagePortal.azureconnectivity !=='' ?action.ServerMesagePortal.phoenixconnectivity:drupalForErrors.phoenixconnectivity,
            organisationnotfound: action.ServerMesagePortal.azureconnectivity !=='' ? action.ServerMesagePortal.organisationnotfound:drupalForErrors.organisationnotfound,
            cryptovalidationerror: action.ServerMesagePortal.azureconnectivity !==''?action.ServerMesagePortal.cryptovalidationerror:drupalForErrors.cryptovalidationerror,
            messagenotreadable: action.ServerMesagePortal.azureconnectivity !==''?action.ServerMesagePortal.messagenotreadable:drupalForErrors.messagenotreadable,
            networkerror: action.ServerMesagePortal.azureconnectivity !==''?action.ServerMesagePortal.networkerror:drupalForErrors.networkerror,
            field_message_7: action.ServerMesagePortal.azureconnectivity !==''?action.ServerMesagePortal.field_message_7:drupalForErrors.field_message_7
        }
        return {
          ...state,
          ServerMesagePortal: newArticle,
        }
      
    }
    return state
  }
  
  export default serverMessageReducer