export const rolesHelper = (accounts) => {
    if (
        accounts &&
        accounts.length > 0 &&
        accounts[0].idTokenClaims &&
        accounts[0].idTokenClaims.roles
    ) {
        return (accounts[0].idTokenClaims.roles);
    } else {
        return;
    }
}
export const oidHelper = (accounts) => {
    if (
        accounts &&
        accounts.length > 0 &&
        accounts[0].idTokenClaims &&
        accounts[0].idTokenClaims.oid
    ) {
        return (accounts[0].idTokenClaims.oid);
    } else { return }
}

