/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import Cookies from 'js-cookie';
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  useIsAuthenticated,
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react';
import { zendeskMsalConfig, loginRequest } from './common/auth/authConfig';
import PrivacyPolicyInfoDialog from './components/PrivacyPolicyDialog';
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
  InteractionStatus,
  IPublicClientApplication
} from '@azure/msal-browser';
import ViewCaseUploadDoc from './components/complaint-table/ViewCaseUploadDoc';
import ViewCaseUploadDocPreview from './components/complaint-table/ViewCaseUploadDocPreview';
import UploadSuccessfull from './components/complaint-table/UploadSuccessfull';
import { SERVER } from './common/api/api-urls';
import CommonService from './common/api/common-api-service';
import { Dispatch } from 'redux';
import { addOrgList, serverMessage } from './stores/actionCreators';
import AccountBlocked from './components/dashboard/AccountBlocked';
import BasicBreadcrumbs from './components/BreadCumb';
import { DRUPALSERVER } from './common/drupal-api/drupal-api-urls';
import DrupalService from './common/drupal-api/drupal-api-service';
import { staticDrupalForBreadcrumbs } from './services/utils';
import { errorComponentToast, drupalForErrors } from "./common/ErrorComponent/ErrorComponent";
import './App.css';
import './common/css/global.css';
import './common/css/ConfirmDialog.css';
import './common/css/fileUpload.css';
import './common/css/Radiogroups.css';
import '../src/styles/dragDropCheckbox.css';
import '../src/styles/error.css';
import '../src/styles/headerFooter.css';
import '../src/styles/InvitationSent.css';
import '../src/styles/popupCookies.css';
import '../src/styles/PreviewPage.css';
import '../src/styles/updateStyles.css';
import { useDispatch, useSelector } from 'react-redux';
// Lazy importes started
import Navbar from './components/new-header/Navbar';
import Footer from './components/Footer';
import CookiesBannar from './components/Cookies';
import FileScan from './common/component-lib/FileScan';
import UnAuthenticatedHeader from './components/UnAuthencticatedHeader';
import Logout from './components/Logout';
import TrackUploads from './components/complaint-table/TrackUploads';
import { IdleTimer } from './IdleTimer';
import ZendeskSSO from './components/ZendeskSSO';
import jwtDecode from "jwt-decode";
import NotificationPage from './components/new-header/NotificationPage';
import SessionDialog from './components/ConcurrentSessionPopUp';
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory';
// lazy routes start
const OnboardBussinesDashboard = lazy(
  () => import('./modules/OmbudsmanDashboard')
);
const ViewCaseTable = lazy(
  () => import('./components/complaint-table/ViewCaseTable')
);
const ViewCaseDetails = lazy(
  () => import('./components/complaint-table/ViewCaseDetails')
);
const ManageProfile = lazy(
  () => import('./components/manage-user/ManageProfile')
);
const NonAdminRespondentPreviewPage = lazy(
  () => import('./pages/NonAdminRespondentPreviewPage')
);
const NonAdminRespondentInvitationSent = lazy(
  () => import('./pages/NonAdminRespondentInvitationSent')
);
const NonAdminRespondentPage = lazy(
  () => import('./components/custom-table/NonAdminTable/NonAdminRespondentPage')
);

const Table = lazy(() => import('./components/collapsible-table/Table/Table'));
const PreviewPage = lazy(() => import('./pages/PreviewPage'));
const InvitationSent = lazy(() => import('./pages/InvitationSent'));

const ManageUser = lazy(() => import('./components/manage-user/ManageUser'));

const Accessibility = lazy(
  () => import('./components/static-pages-footer/Accessibility')
);
const CorporateInformation = lazy(
  () => import('./components/static-pages-footer/CorporateInformation')
);
const LegalInfo = lazy(
  () => import('./components/static-pages-footer/LegalInfo')
);
const ModernSlaveryAct = lazy(
  () => import('./components/static-pages-footer/ModernSlaveryAct')
);
const FreedomInfo = lazy(
  () => import('./components/static-pages-footer/FreedomInfo')
);
const Procurement = lazy(
  () => import('./components/static-pages-footer/Procurement')
);
// lazy routes end

const drupalApi = new DrupalService();
const useStateCallbackModule = require('use-state-callback');
const { useStateCallback } = useStateCallbackModule;
type AppProps = {
  msalInstance: IPublicClientApplication;
};

//Added Accessibility exe

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
 * only render their children if a user is authenticated or unauthenticated, respectively.
 */
const MainContent = () => {
  const { instance } = useMsal();
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch: Dispatch<any> = useDispatch();
  const { inProgress } = useMsal();
  const [actStrState, setActStrState] = useState('');
  const [idtStrState, setIdtStrState] = useState('');
  const [pageURL, setPageURL] = useStateCallback([]);
  const [mainPageURL, setMainPageURL] = useStateCallback([]);
  const [url, setURL] = useState<string | any>('');
  const [graphData, setGraphData] = useState<any>(null);
  const [roleArray, setRoleArray] = useState<string[]>([]);
  const [errorFromDrupal, setErrorFromDrupal] = useState(drupalForErrors);
  const [drupalForBreadcrumb, setDrupalForBreadcrumb] = useState<any>(
    staticDrupalForBreadcrumbs
  );
  const [fetched, setFetched] = useState<boolean>(false);
  const [orgsFetched, setOrgsFetched] = useState<boolean>(false);
  const userDetails: any = useSelector((store: any) => store.userDetailsReducer.userdata);
  const addOrgListFunc = React.useCallback(
    (stateDigitalPortal: IDigitalPortal) =>
      dispatch(addOrgList(stateDigitalPortal)),
    [dispatch]
  );

  const serverMessageFunc = React.useCallback(
    (ServerMesagePortal: ServerMessage) =>
      dispatch(serverMessage(ServerMesagePortal)),
    [dispatch]
  );
  // const [browsedPath,setBrowsedPath] = useState("/");
  // setBrowsedPath(window.location.href);

  const getScope = () => {
    let scopeStr: string;
    switch (window.REACT_APP_ENV) {
      case "STG": scopeStr = "api://8f6a5ca8-e83e-4a05-aa59-f41406e8d76b/respondent/apiaccess"; break;
      case "PRD": scopeStr = "api://aca0386d-e412-40d2-b032-a33e5b65a8af/respondent/apiaccess"; break;
      case "SIT": scopeStr = "api://e1134553-5bba-4c5d-a85c-990bbacaab2f/respondent/apiaccess"; break;
      case "DEV": scopeStr = "api://fe569e86-4dae-4f4b-8fa7-9fc94b4ae292/respondent/apiaccess"; break;
      case "PRF": scopeStr = "api://86f5ddff-9ab0-47a9-a12e-2ee7f22dd23e/respondent/apiaccess"; break;
      case "TRN": scopeStr = "api://08ef3ceb-926d-4f78-bf86-14a8fd1b4bd8/respondent/apiaccess"; break;
      default: scopeStr = "api://fe569e86-4dae-4f4b-8fa7-9fc94b4ae292/respondent/apiaccess"; break;
    }
    return scopeStr;
  }
  useEffect(() => {
    if (localStorage.getItem('ACT')){
      getverifytokenforresponse(true);
      setTimeout(() => {
        loadZendesk();
      },1000)
    }
  }, [window.location.href])
  useEffect(() => {

    // define increment counter part
    const tabsOpen = localStorage.getItem('tabsOpen')
    if (!tabsOpen  || parseInt(tabsOpen) === 0) {
      localStorage.setItem('tabsOpen', 1)
    } else {
      localStorage.setItem('tabsOpen', parseInt(tabsOpen) + parseInt(1))
    }

    // define decrement counter part
    window.onunload = function (e) {
      const newTabCount = localStorage.getItem('tabsOpen')
      if (newTabCount !== null) {
        localStorage.setItem('tabsOpen', newTabCount - 1)
      }
    }
  }, [])
  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      /* eslint-disable no-eval */
      setTimeout(() => {
        if (accounts.length === 0 && !window.location.href.includes('logout')) {
          console.log(loginRequest);

          instance.loginRedirect(loginRequest);
        }
      }, 500);
      /* eslint-disable no-eval */
    }
    const userProfile = () => {
      const commonApi = new CommonService();
      if (
        accounts &&
        accounts.length > 0 &&
        accounts[0].idTokenClaims &&
        (accounts[0].idTokenClaims.email ||
          accounts[0].idTokenClaims?.upn ||
          accounts[0].idTokenClaims?.preferred_username)
      ) {
        if (userDetails && userDetails.hasOwnProperty('officeLocation')) {
          setGraphData(userDetails)
        }
      }
    };
    if (isAuthenticated) {
      const account = instance.getAllAccounts()[0];
      let accessTokenRequest: any;
      if (window.REACT_APP_ENV == "SIT") {
        accessTokenRequest = {
          scopes: [
            getScope(),
            'api://e1134553-5bba-4c5d-a85c-990bbacaab2f/respondent/sit',
            'User.Read'
          ],
          account: account
        };
      }
      if (window.REACT_APP_ENV == "TRN") {
        accessTokenRequest = {
          scopes: [
            getScope(),
            'api://08ef3ceb-926d-4f78-bf86-14a8fd1b4bd8/respondent/trn',
            'User.Read'
          ],
          account: account
        };
      }
      else if (window.REACT_APP_ENV == "DEV") {


        accessTokenRequest = {
          scopes: [
            getScope(),
            'api://fe569e86-4dae-4f4b-8fa7-9fc94b4ae292/respondent/dev',
            'User.Read'
          ],
          account: account
        };
      } else if (window.REACT_APP_ENV == "STG") {


        accessTokenRequest = {
          scopes: [
            getScope(),
            'api://8f6a5ca8-e83e-4a05-aa59-f41406e8d76b/respondent/stg',
            'User.Read'
          ],
          account: account
        };
      }
      else if (window.REACT_APP_ENV == "PRD") {


        accessTokenRequest = {
          scopes: [
            getScope(),
            'api://aca0386d-e412-40d2-b032-a33e5b65a8af/respondent/prd',
            'User.Read'
          ],
          account: account
        };
      }
      else if (window.REACT_APP_ENV == "PRF") {


        accessTokenRequest = {
          scopes: [
            getScope(),
            'api://86f5ddff-9ab0-47a9-a12e-2ee7f22dd23e/respondent/prf',
            'User.Read'
          ],
          account: account
        };
      }
      const decodedACT = localStorage.getItem('ACT') ? jwtDecode(localStorage.getItem('ACT')) : null;
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(function (accessTokenResponse) {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          let idToken = accessTokenResponse.idToken;
          let coRelationId: string = accounts[0]?.idTokenClaims?.auth_time?.toString() as string
          if (!localStorage.getItem('ACT')
            || !decodedACT
            || decodedACT?.exp < Math.floor((new Date()).getTime() / 1000)
            || decodedACT?.scp !== jwtDecode(accessToken)?.scp
            || decodedACT?.preferred_username !== jwtDecode(accessToken)?.preferred_username)
            localStorage.setItem('ACT', accessToken);
          sessionStorage.setItem('IDT', idToken);
          sessionStorage.setItem('correlationId', coRelationId);
          // callMsGraph(accessTokenResponse.accessToken).then(response => setGraphData(response));
          // updatePolicy(accessTokenResponse.accessToken).then(response => console.log('updated ',response))
          // Call your API with token
          setActStrState(accessToken);
          setIdtStrState(idToken);
          setTimeout(() => {
            if (orgsFetched) {
              getverifytokenforresponse(false);
            }
            getBreadcrumbs();
            userProfile();
            apiCallOrganisationId();

          }, 200);
        })
        .catch(function (error) {
          //Acquire token silent failure, and send an interactive request
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                let accessToken = accessTokenResponse.accessToken;
                let idToken = accessTokenResponse.idToken;
                let coRelationId: string = accounts[0]?.idTokenClaims?.auth_time?.toString() as string
                // callMsGraph(accessTokenResponse.accessToken).then(response => setGraphData(response));
                // Call your API with token
                if (!localStorage.getItem('ACT')
                  || !decodedACT
                  || decodedACT?.exp < Math.floor((new Date()).getTime() / 1000)
                  || decodedACT?.scp !== jwtDecode(accessToken)?.scp
                  || decodedACT?.preferred_username !== jwtDecode(accessToken)?.preferred_username)
                  localStorage.setItem('ACT', accessToken);
                sessionStorage.setItem('IDT', idToken);
                sessionStorage.setItem('correlationId', coRelationId);
                setActStrState(accessToken);
                setIdtStrState(idToken);
                setTimeout(() => {
                  userProfile();
                  apiCallOrganisationId();
                }, 200);
              })
              .catch(function (error) { });
          }
        });
    }
    if (
      accounts &&
      accounts.length > 0 &&
      accounts[0].idTokenClaims &&
      accounts[0].idTokenClaims.roles &&
      accounts[0].idTokenClaims.roles.length > 0
    ) {
      let headerRole = accounts[0].idTokenClaims.roles;
      setRoleArray(headerRole);
    }
  }, [instance, accounts, inProgress, isAuthenticated, orgsFetched]);

  const initializeZendeskChat = () => {

    if (window.zE) {
      const snippetScript = document.createElement('script');
      snippetScript.src = getSnippetSrcUrl();
      snippetScript.id = 'ze-snippet';
      snippetScript.async = true;
      document.head.appendChild(snippetScript);

      zE("messenger", "loginUser", function (callback) {
        let globalzendeskurl = window.zendeskurl;
        let url = globalzendeskurl;

        fetch(url, {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('ACT'),
          },

        }).then(response => response.json())
          .then((response) => callback(response.jwttoken))
      });
      setTimeout(() => {
        zE("messenger", "logoutUser");
      }, 1000 * 800)
    }
  }
  const getSnippetSrcUrl = () => {
    const env = window.REACT_APP_ENV;
    const scriptLinks = {
      "SIT": `${process.env.PUBLIC_URL}/snippet-sit.js?key=2f4c3e9a-2b49-49f4-94be-a366033e1d50`,
      "STG": `${process.env.PUBLIC_URL}/snippet-uat.js?key=2f4c3e9a-2b49-49f4-94be-a366033e1d50`,
      "PRD": `${process.env.PUBLIC_URL}/snippet-prd.js?key=2fb43cb5-9cf6-4386-be11-758cc8c9155e`,
      "DEV": `${process.env.PUBLIC_URL}/snippet.js?key=934fcad7-7d31-4be8-b21f-8b2e08f63fdf`,
      "TRN": `${process.env.PUBLIC_URL}/snippet-trn.js?key=934fcad7-7d31-4be8-b21f-8b2e08f63fdf`,
    }
    return scriptLinks[env];
  }
  const loadZendesk = () => {

    const script = document.createElement("script");
    script.src = getSnippetSrcUrl();

    script.async = true;
    script.onload = initializeZendeskChat;
    document.head.appendChild(script);
  }
  useEffect(() => {

    if (isAuthenticated && actStrState) {
    //  loadZendesk();
    }
    return () => {
      const scriptElement = document.getElementById('ze-snippet');
      if (scriptElement) {
        scriptElement.remove();
      }
    }

  }, [actStrState, isAuthenticated])
  const [element] = useState(<OnboardBussinesDashboard />);

  const [openPrivacyPolicy, setPrivacyPolicy] = useState<boolean>();
  const [openCookies, setOpenCookies] = useState<boolean>();
  const [sessionDialog, setSessionDialog] = useState({ open: null, session1: false, title: '', text: '', button1: '', button2: '' });
  const [sessionTimer, setSessionTimer] = useState({ forcedlogouttimeinminutes: '20', popupshowcasetimeinminutes: '15', differenceinminutes: '5' })


  useEffect(() => {
    const commonApi = new CommonService();

    if (
      graphData &&
      graphData.officeLocation &&
      graphData.officeLocation === 'true'
    ) {
      commonApi
        .httpConnection(SERVER.SERVICES.updatePrivacyPolicy, 'get')
        .then((res) => console.warn(res))
        .catch((err: any) => {
          errorComponentToast(err.data, errorFromDrupal[0])
        });
      setPrivacyPolicy(false);
      setOpenCookies(false);
    } else if (graphData && graphData.hasOwnProperty('officeLocation')) {
      setPrivacyPolicy(true);
      setOpenCookies(true);
    }
  }, [graphData]);
  const [footerPrivacyPolicy, setFooterPrivacyPolicy] = useState<boolean>();
  const footerPrivacyPolicyFN = (value: boolean) => {
    setFooterPrivacyPolicy(value);
  };
  const apiCallOrganisationId = () => {
    const commonApi = new CommonService();

    if (
      !orgsFetched &&
      accounts &&
      accounts.length > 0 &&
      accounts[0].idTokenClaims &&
      (accounts[0].idTokenClaims.email ||
        accounts[0].idTokenClaims?.upn ||
        accounts[0].idTokenClaims?.preferred_username)
    ) {
      setOrgsFetched(true);
      commonApi
        .httpConnection(SERVER.SERVICES.getOrganisationId, 'get')
        .then((res: any) => {
          let actStr = `${''}${actStrState}`;
          let idtStr = `${''}${idtStrState}`;
          addOrgListFunc({
            act: actStr,
            idt: idtStr,
            orglist: res.organisations
          });
        })
        .catch((err: any) => {
          errorComponentToast(err.data, errorFromDrupal[0])
        });
    }
  };
  const getverifytokenforresponse = (locationRefreshedOrNavigated) => {
    const commonAPI = new CommonService();
    const tabsOpen = localStorage.getItem('tabsOpen') ? localStorage.getItem('tabsOpen') : 0;
    let baseUrl = SERVER.SERVICES.loginRequest
   if (window.performance && (performance.navigation.type == 1 || performance.navigation.type == 2)) {
      baseUrl = SERVER.SERVICES.ongoingActiveSession
    }
    else if (locationRefreshedOrNavigated === true) {
      baseUrl = SERVER.SERVICES.ongoingActiveSession
    }
    else if (parseInt(tabsOpen) > 1) {
      baseUrl = SERVER.SERVICES.ongoingActiveSession
    }
    commonAPI.httpConnection(baseUrl, 'get')
      .then(res => {
       // console.log(baseUrl, SERVER.SERVICES.loginRequest, tabsOpen, !tabsOpen)
        if(baseUrl === SERVER.SERVICES.loginRequest){
          if (!tabsOpen || parseInt(tabsOpen) === 0) {
            localStorage.setItem('tabsOpen', 1)
          }
        }
        if (res && res.status && res.message && res.message.includes("SESSION_") && res.status === "invalid") {
          drupalApi.httpConnection(DRUPALSERVER.SERVICES.getSessionManagement, 'get')
            .then((dres) => {
              let propsDialog = { open: false, session1: false, title: '', text: '', button1: '', button2: '' };
              if (res.message.includes("SESSION_2")) {
                propsDialog.open = true;
                propsDialog.session1 = false;
                propsDialog.title = dres[0].session_2_header_text;
                propsDialog.text = dres[0].session_2_text;
                propsDialog.button1 = dres[0].session_2_left_btn;
                propsDialog.button2 = dres[0].session_2_right_btn;
                setSessionDialog(propsDialog);
              } else if (res.message.includes("SESSION")) {
                propsDialog.open = true;
                propsDialog.session1 = true;
                propsDialog.title = dres[0].session_1_text;
                propsDialog.text = "";
                propsDialog.button1 = "";
                propsDialog.button2 = "";
                setSessionDialog(propsDialog);
              } else {
                propsDialog = { open: false, session1: false, title: '', text: '', button1: '', button2: '' };
                setSessionDialog(propsDialog);
              }
              setSessionDialog(propsDialog);
              //console.log('sessionDialog ',sessionDialog)
            })
        }
        else if (res && res.status && res.message && res.status === "invalid") {
          drupalApi.httpConnection(DRUPALSERVER.SERVICES.getSessionManagement, 'get')
            .then((dres) => {
              let propsDialog = { open: false, session1: false, title: '', text: '', button1: '', button2: '' };
              propsDialog.open = true;
              propsDialog.session1 = true;
              propsDialog.title = dres[0].session_1_text;
              propsDialog.text = "";
              propsDialog.button1 = "";
              propsDialog.button2 = "";
              setSessionDialog(propsDialog);
            })
        }
        else {
          setSessionDialog({ open: false, session1: false, title: '', text: '', button1: '', button2: '' });
          drupalApi.httpConnection(DRUPALSERVER.SERVICES.getSessionTimer, 'get')
            .then((res) => {
              setSessionTimer(res[0]);
                loadZendesk();
              apiCallOrganisationId();
            }).catch((err) => setSessionTimer({ forcedlogouttimeinminutes: '20', popupshowcasetimeinminutes: '15', differenceinminutes: '5' }))
        }
      })
      .catch((err) => { 
        console.warn(err); 
        setSessionDialog({ open: false, session1: false, title: '', text: '', button1: '', button2: '' }); 
        setSessionTimer({ forcedlogouttimeinminutes: '20', popupshowcasetimeinminutes: '15', differenceinminutes: '5' })
      })

  }
  const getBreadcrumbs = () => {

    if (fetched) return;
    setFetched(true);
    drupalApi
      .httpConnection(DRUPALSERVER.SERVICES.getBreadcrumbs, 'get')
      .then((res) => {
        if (res.length > 0) {
          setDrupalForBreadcrumb(res);
        } else {
          setDrupalForBreadcrumb(staticDrupalForBreadcrumbs);
        }
      })
      .catch(() => {
        setDrupalForBreadcrumb(staticDrupalForBreadcrumbs);
      });

    drupalApi
      .httpConnection(
        DRUPALSERVER.SERVICES.serverMessages,
        "get"
      )
      .then((res: any) => {
        serverMessageFunc({
          azureconnectivity: res[0] !== '<' ? res[0].azureconnectivity : drupalForErrors.azureconnectivity,
          phoenixconnectivity: res[0] !== '<' ? res[0].phoenixconnectivity : drupalForErrors.phoenixconnectivity,
          organisationnotfound: res[0] !== '<' ? res[0].organisationnotfound : drupalForErrors.organisationnotfound,
          cryptovalidationerror: res[0] !== '<' ? res[0].cryptovalidationerror : drupalForErrors.cryptovalidationerror,
          messagenotreadable: res[0] !== '<' ? res[0].messagenotreadable : drupalForErrors.messagenotreadable,
          networkerror: res[0] !== '<' ? res[0].networkerror : drupalForErrors.networkerror,
          field_message_7: res[0] !== '<' ? res[0].field_message_7 : drupalForErrors.field_message_7
        })
        // setErrorFromDrupal(res[0] !=='<' ? res[0] : drupalForErrors)
      })
      .catch(() => {
        setErrorFromDrupal(drupalForErrors)

      });

  };
  const selected = (crumbs: string) => {
    const path = window.location.pathname;
    let childRoute;
    let mainRoute;
    let pathName;
    switch (crumbs ? crumbs : path) {
      case '/initiate-onboarding':
        childRoute = drupalForBreadcrumb[0]?.mainRouteAdmin;
        break;
      case '/ombudsman-dashboard':
        childRoute = drupalForBreadcrumb[0]?.dashboard;
        break;
      case '/non-admin-respondent-table':
        childRoute = drupalForBreadcrumb[0]?.mainRouteNonAdmin;
        break;
      case '/view-cases':
        childRoute = drupalForBreadcrumb[0]?.viewCase;
        break;
      case '/manage-user':
        childRoute = drupalForBreadcrumb[0]?.manageUser;
        break;
      case '/manage-user-profile':
        childRoute = 'Manage user profile';
        break;
      case '/initiate-onboarding-preview':
        mainRoute = drupalForBreadcrumb[0]?.mainRouteAdmin;
        childRoute = drupalForBreadcrumb[0]?.childRoutePreviewAdmin;
        pathName = '/initiate-onboarding';
        break;
      case '/initiate-onboarding-invite-sent':
        mainRoute = drupalForBreadcrumb[0]?.mainRouteAdmin;
        childRoute = '';
        pathName = '/initiate-onboarding';
        break;
      case '/non-admin-respondent-preview-page':
        mainRoute = drupalForBreadcrumb[0]?.mainRouteNonAdmin;
        childRoute = drupalForBreadcrumb[0]?.childRoutePreviewNonAdmin;
        pathName = '/non-admin-respondent-table';
        break;
      case '/non-admin-respondent-invite-sent':
        mainRoute = drupalForBreadcrumb[0]?.mainRouteNonAdmin;
        childRoute = '';
        pathName = '/non-admin-respondent-table';
        break;
      case '/view-cases-details/':
        mainRoute = drupalForBreadcrumb[0]?.viewCase;
        childRoute = drupalForBreadcrumb[0]?.viewCaseDetails;
        pathName = '/view-cases';
        break;
        case '/notification':
          pathName = '/notification';
          break;  
      case '/track-uploads':
        childRoute = 'Track uploads';
    }
    const BreadCrumbs = [
      {
        path: childRoute,
        mainPath: mainRoute,
        urlName: pathName
      }
    ];

    setPageURL([BreadCrumbs[0]?.path]);
    setMainPageURL([BreadCrumbs[0]?.mainPath]);
    setURL(BreadCrumbs[0]?.urlName);
  };
  const childPathName = [
    '/initiate-onboarding-preview',
    '/non-admin-respondent-preview-page',
    '/non-admin-respondent-invite-sent',
    '/initiate-onboarding-invite-sent',
    '/view-cases-details/'
  ];
  const [browserPathRecord] = useState(window.location.pathname);
  // console.log((!Cookies.get('accepted-cookie')),(!Cookies.get('analytical-cookies') && !Cookies.get('functional-cookies')),openCookies)
  return actStrState && actStrState.length > 0 ? (
    <>
      <Suspense
        fallback={
          <div className="loader">
            <div className="loader__element"></div>
          </div>
        }>
        <AuthenticatedTemplate>
          {browserPathRecord === '/helpdesk' &&
            <Router>
              <section role="main" className="container">
                <Routes>
                  <Route
                    path="/helpdesk"
                    element={<ZendeskSSO />}
                  />
                </Routes>
              </section>
            </Router>
          }
          {
            sessionDialog.open === true &&
            <Router><SessionDialog
              open={sessionDialog?.open}
              session1={sessionDialog?.session1} title={sessionDialog?.title}
              text={sessionDialog?.text}
              button1={sessionDialog?.button1} button2={sessionDialog?.button2} />  </Router>
          }
          {sessionDialog.open === false && browserPathRecord !== '/helpdesk' && element && (
            <Router>
              {openPrivacyPolicy && (
                <PrivacyPolicyInfoDialog
                  open={openPrivacyPolicy}
                  handleClose={() => {
                    setPrivacyPolicy(false);
                  }}
                />
              )}
              {footerPrivacyPolicy && (
                <PrivacyPolicyInfoDialog
                  open={footerPrivacyPolicy}
                  handleClose={() => {
                    footerPrivacyPolicyFN(false);
                  }}
                />
              )}
              {((!Cookies.get('accepted-cookie')) || (!Cookies.get('analytical-cookies') && !Cookies.get('functional-cookies')) || openCookies) && <CookiesBannar />}
              <Navbar selected={selected} profile={drupalForBreadcrumb[0]?.profile}
                logout={drupalForBreadcrumb[0]?.logout}
              />
              {childPathName.includes('/view-cases-details/') ||
                childPathName.includes(window.location.pathname) ? (
                <BasicBreadcrumbs
                  parentpagelink={url}
                  parentpage={mainPageURL}
                  propCurrentPage={pageURL}
                  home={drupalForBreadcrumb[0]?.home}
                />
              ) : (
                <BasicBreadcrumbs
                  propCurrentPage={pageURL}
                  home={drupalForBreadcrumb[0]?.home}
                />
              )}
              <section role="main" className="container">
                <Routes>
                  {/* <Route path="/" element={<ChoosableComponent />} /> */}
                  <Route
                    path="/ombudsman-dashboard"
                    element={<OnboardBussinesDashboard selected={selected} />}
                  />
                  {roleArray.includes('OmbudsmanAdmin') && (
                    <Route
                      path="/initiate-onboarding"
                      element={<Table selected={selected} />}
                    />
                  )}

                  <Route
                    path="/signin"
                    element={<OnboardBussinesDashboard selected={selected} />}
                  />
                  <Route
                    path="/notification"
                    element={<NotificationPage />}
                  />
                  {roleArray.includes('OmbudsmanAdmin') && (
                    <Route path="/initiate-onboarding-preview">
                      <Route
                        index
                        element={<PreviewPage selected={selected} />}
                      />
                    </Route>
                  )}
                  {roleArray.includes('OmbudsmanAdmin') && (
                    <Route
                      path="/initiate-onboarding-invite-sent"
                      element={<InvitationSent selected={selected} />}
                    />
                  )}
                  {roleArray.includes('RespondentAdmin') && (
                    <Route
                      path="/non-admin-respondent-table"
                      element={<NonAdminRespondentPage selected={selected} />}
                    />
                  )}
                  {roleArray.includes('RespondentAdmin') && (
                    <Route
                      path="/non-admin-respondent-preview-page"
                      element={
                        <NonAdminRespondentPreviewPage selected={selected} />
                      }
                    />
                  )}
                  {roleArray.includes('RespondentAdmin') && (
                    <Route
                      path="/non-admin-respondent-invite-sent"
                      element={
                        <NonAdminRespondentInvitationSent
                          selected={selected}
                        />
                      }
                    />
                  )}

                  <Route path="/cookies" element={<CookiesBannar />} />
                  {(roleArray.includes('CaseWorker') ||
                    roleArray.includes('Supervisor')) && (
                      <Route
                        path="/view-cases"
                        element={<ViewCaseTable selected={selected} />}
                      />
                    )}
                  {(roleArray.includes('CaseWorker') ||
                    roleArray.includes('Supervisor')) && (
                      <Route
                        path="/view-cases-details/:incidentid"
                        element={<ViewCaseDetails selected={selected} />}
                      />
                    )}
                  {(roleArray.includes('CaseWorker') ||
                    roleArray.includes('Supervisor')) && (
                      <Route
                        path="/view-cases-upload/:incidentid"
                        element={<ViewCaseUploadDoc />}
                      />
                    )}
                  {(roleArray.includes('CaseWorker') ||
                    roleArray.includes('Supervisor')) && (
                      <Route
                        path="/view-cases-upload-scan"
                        element={<FileScan />}
                      />
                    )}
                  {(roleArray.includes('CaseWorker') ||
                    roleArray.includes('Supervisor')) && (
                      <Route
                        path="/view-cases-upload-preview"
                        element={<ViewCaseUploadDocPreview />}
                      />
                    )}

                  {/* <Route path="/view-cases-upload-preview" element={<ViewCaseUploadDocPreview />} /> */}

                  {(roleArray.includes('CaseWorker') ||
                    roleArray.includes('Supervisor')) && (
                      <Route
                        path="/view-case-document-upload-success"
                        element={<UploadSuccessfull />}
                      />
                    )}

                  <Route
                    path="/manage-user-profile"
                    element={<ManageProfile selected={selected} />}
                  />
                  <Route path="/track-uploads" element={<TrackUploads selected={selected} />} />
                  <Route
                    path="/account-blocked"
                    element={<AccountBlocked />}
                  />
                  <Route
                    path="/accessibility"
                    element={<Accessibility selected={selected} />}
                  />
                  <Route
                    path="/corporate"
                    element={<CorporateInformation selected={selected} />}
                  />
                  <Route
                    path="/legal-policy"
                    element={<LegalInfo selected={selected} />}
                  />
                  <Route
                    path="/modern-slavery-act"
                    element={<ModernSlaveryAct selected={selected} />}
                  />
                  <Route
                    path="/freedom-of-information"
                    element={<FreedomInfo selected={selected} />}
                  />
                  <Route
                    path="/procurement"
                    element={<Procurement selected={selected} />}
                  />

                  {(roleArray.includes('OmbudsmanAdmin') ||
                    roleArray.includes('RespondentAdmin')) && (
                      <Route
                        path="/manage-user"
                        element={<ManageUser selected={selected} />}
                      />
                    )}
                  <Route path="/logout" element={<Logout />} />
                  {/* <Route path="/update-document" element={<UpdateDocument />} /> */}
                </Routes>
              </section>
              <Footer footerPrivacyPolicyFN={footerPrivacyPolicyFN} />
              <IdleTimer
                forcedlogouttimeinminutes={sessionTimer?.forcedlogouttimeinminutes}
                popupshowcasetimeinminutes={sessionTimer?.popupshowcasetimeinminutes}
                differenceinminutes={sessionTimer?.differenceinminutes}></IdleTimer>
            </Router>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Router>
            {/* <Header /> */}
            <UnAuthenticatedHeader />
            <section className="container">
              <Routes>
                {footerPrivacyPolicy && (
                  <PrivacyPolicyInfoDialog
                    open={footerPrivacyPolicy}
                    handleClose={() => {
                      footerPrivacyPolicyFN(false);
                    }}
                  />
                )}
                <Route path="/logout" element={<Logout />} />
                <Route
                  path="/accessibility"
                  element={<Accessibility selected={selected} />}
                />
                <Route
                  path="/corporate"
                  element={<CorporateInformation selected={selected} />}
                />
                <Route
                  path="/legal-policy"
                  element={<LegalInfo selected={selected} />}
                />
                <Route
                  path="/modern-slavery-act"
                  element={<ModernSlaveryAct selected={selected} />}
                />
                <Route
                  path="/freedom-of-information"
                  element={<FreedomInfo selected={selected} />}
                />
                <Route
                  path="/procurement"
                  element={<Procurement selected={selected} />}
                />

              </Routes>
            </section>
          </Router>
        </UnauthenticatedTemplate>
      </Suspense>
    </>
  ) : (
    <>
      <Router>
        {/* <Header /> */}
        <UnauthenticatedTemplate >
          <UnAuthenticatedHeader />
          <section className="container">
            <Routes>
              <Route path="/logout" element={<Logout />} />
            </Routes>
          </section>
        </UnauthenticatedTemplate>
      </Router>
    </>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const zendeskMsalInstance = new PublicClientApplication(zendeskMsalConfig)
export default function App({ msalInstance }: AppProps) {
  const [browserPathRecord] = useState(window.location.pathname);
  msalInstance = browserPathRecord == '/helpdesk' ? zendeskMsalInstance : msalInstance;

  return (
    <MsalProvider instance={msalInstance}>
      <MainContent />
      {/* <PageLayout><MainContent /></PageLayout> */}
    </MsalProvider>
  );
}
