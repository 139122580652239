import { createSlice } from "@reduxjs/toolkit";

interface FileState {
    files: any[]
}
const initialState: FileState = {
    files: []
}

const UploadSlice = createSlice({
    name: 'uploadFiles',
    initialState,
    reducers: {
        addFile: (state, action) => {
            state.files.unshift(action.payload);
        },
        removeFile: (state, action) => {
            if (action.payload.fileName && action.payload.fileName !== null) {
                state.files = state.files.filter((file) => file.name !== action.payload.fileName);
            } else {
                state.files = state.files.filter((file) => file.name !== action.payload);
            }
            //console.log('action.payload.xhr'+action.payload.xhr)
            if (action.payload.abortController && action.payload.abortController !== null) {
                action.payload.abortController.abort();
            }
        },
        clearFiles: (state) => {
            state.files = [];
        }
    }
});
export const { addFile, removeFile, clearFiles } = UploadSlice.actions;
export default UploadSlice.reducer;
