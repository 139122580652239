import DrupalService from "../drupal-api/drupal-api-service";

const ShowFeatureFlag = (featureName: string, roles: string[], orgList: any, userId: string , featureFlagData:any) => {
    const roleCheck=(data: string)=>{
        if(!data || data.length===0) return true;
        data = data.replaceAll(" ","").toLowerCase();
        for(const role of roles){
            let temp = role.replaceAll(" ","").toLowerCase();
            if(data.indexOf(temp)!==-1) return true;
        }
        return false;
    }
    const groupCheck=(groups:any)=>{
        if(!groups || groups.length===0) return true;
        let groupIds = groups.map((group:string)=>group.split("/")[1].trim());
        for(const org of orgList){
            if(groupIds.includes(org.accountid)){
                return true;
            }
        }
        return false;
    }
    const userCheck=(user:string)=>{
        if(!user || user.length===0) return true;
        return user.indexOf(userId)!==-1;
    }
    let response = false;
    for (const featureFlag of featureFlagData) {
        if (featureFlag.feature === featureName) {
            let status = featureFlag.status==="true";
            response = (status && roleCheck(featureFlag.roles) && groupCheck(featureFlag.group) && userCheck(featureFlag.user));
        }
    }
    return response;
}

export default ShowFeatureFlag;