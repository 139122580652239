import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import './common/css/global.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './common/auth/authConfig';
import { combineReducers, legacy_createStore as createStore, applyMiddleware } from "redux";
import UploadSlice from './stores/uploadFiles/UploadSlice';
import UploadStatusSlice from './stores/uploadFiles/UploadStatusSlice';
import UploadProgressSlice from './stores/uploadFiles/UploadProgressSlice';
import UploadZipSlice from './stores/uploadFiles/UploadZipSlice';
import FeatureFlagReducer from './stores/featureflag/FeatureFlagSlice';
import { Provider } from "react-redux"
import thunk from "redux-thunk"
import reducer from "./stores/reducer"
import ServerMessageReducer from "./stores/serverMessage"
import { loadState, saveState } from './stores/localstorage';
import userDetailsReducer from './stores/userDetails/UserDetailsSlice'
const persistedState = loadState();

const rootReducer = combineReducers({
  stateDigitalPortal: reducer,
  uploadFiles: UploadSlice,
  uploadProgress: UploadProgressSlice,
  uploadStatusSlice: UploadStatusSlice,
  ServerMesagePortal: ServerMessageReducer,
  uploadZipSlice: UploadZipSlice,
  featureFlagReducer:FeatureFlagReducer,
  userDetailsReducer:userDetailsReducer,
});
export const today = new Date().getHours();
/**
 * Nigel confirmed working hours should be from 7AM to 6PM 
 * on 21st July 2023 - 4:18PM
 */
// Condition in such a way that it turns out false always
const store: any = createStore(rootReducer, persistedState, applyMiddleware(thunk))
store.subscribe(() => {
  saveState(store.getState());
});

export const msalInstance = new PublicClientApplication(msalConfig)
ReactDOM.createRoot(document.querySelector("#root")!).render(
  <React.Fragment>
    <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <App msalInstance={msalInstance} />
        </Provider>
    </StyledEngineProvider>
  </React.Fragment>
);