import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#ffffff',
    },
}));


const useStyles: any = makeStyles({
    padding: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: -20
    },
    striptwo: {
        border: '1px solid #4040401F',
        borderRadius: 2,
        padding: 15,
        backgroundColor: '#F2F2F2'
    },
    btn: {
        // border: '2px solid #D22D64',
        // backgroundColor: 'white',
        color: 'blue', cursor: 'pointer', marginTop: 20,
        fontFamily: 'AssistantSemiBold',
    },
    btn1: {
        width: 300,
        // height: 60,
        backgroundColor: 'black',
        color: 'white',
        cursor: 'pointer',
        padding: 20,
        textAlign: 'center',
        fontFamily: 'AssistantSemiBold',
    },
    strip: {
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 70, backgroundColor: '#DFE7ED',
        alignItems: 'center', width: '100%'
    },
    strippara: {
        fontSize: '1.3rem', fontFamily: 'AssistantSemiBold', color: '#292957', paddingLeft: 20, alignItems: 'center'
    },
    strippara1: {
        paddingLeft: 20, paddingTop: 20, fontSize: '1.3rem', fontFamily: 'AssistantSemiBold', color: '#292957', alignItems: 'center'
    },
    para1: {
        fontSize: '1.rem', color: '#404040', fontFamily: 'AssistantRegular', paddingLeft: 20
    },
    tableStrip: {
        backgroundColor: '#d2e3fc',
        width: '100%',
        padding: 5,
        borderLeft: '0.5px solid #dbdbdb !important',
        borderRight: '0.5px solid #dbdbdb !important',
        borderBottom: '0.5px solid #dbdbdb !important',
        borderTop: '0.5px solid #dbdbdb !important',
        cursor: 'pointer'
    },
    tableActiveStrip: {
        backgroundColor: '#2A364A',
        width: '100%',
        padding: 5,
        borderWidth: '1px !important',
        borderColor: '#000000 !important',
        cursor: 'pointer'
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 60
    },
    pActivetag: {
        color: '#ffffff',
        fontSize: '1.3rem',
        fontFamily: 'AssistantSemiBold',
        paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 20,
        alignItems: 'center'
    },
    ptag: {
        color: '#1b71fa',
        fontSize: '1.3rem',
        fontFamily: 'AssistantSemiBold',
        paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 20,
        alignItems: 'center'
    },

    group: {
        fontSize: '0.9rem',
        fontFamily: 'AssistantRegular',
        color: '#666666',
        marginTop: 10,
        marginLeft: 10
    },
    personal: {
        fontSize: '1rem',
        fontFamily: 'AssistantSemiBold',
        color: '#404040',
        marginTop: -10, marginLeft: 10
    },
    whiteBack: {
        backgroundColor: '#FFFFFF',
        width: '100%',
        borderLeft: '0.5px solid #7070704D !important',
        borderRight: '0.5px solid #7070704D !important',
        borderBottom: '0.5px solid #7070704D !important',
        borderTop: '0.5px solid #7070704D !important'
    },
    greyBack: {
        backgroundColor: '#F7F7F7',
        width: '100%',
        borderLeft: '0.5px solid #dbdbdb !important',
        borderRight: '0.5px solid #dbdbdb !important',
        borderBottom: '0.5px solid #dbdbdb !important',
        borderTop: '0.5px solid #dbdbdb !important'
    },
    details: {
        fontSize: '1rem',
        fontFamily: 'AssistantSemiBold',
        color: '#666666'
    },
    details1: {
        fontSize: '1.2rem',
        fontFamily: 'AssistantSemiBold',
        color: '#404040'
    },
    details12: {
        fontSize: '1.2rem',
        fontFamily: 'AssistantSemiBold',
        color: '#D22D64'
    },
    arrow_left: {
        width: 0,
        height: 0,
        borderTop: '20px solid transparent',
        borderBottom: '20px solid transparent',

        borderRight: '20px solid #f2eeed',
        marginTop: 50,
        marginLeft: -30
    },
    arrow_left1: {
        width: 0,
        height: 0,
        borderLeft: '20px solid transparent',
        borderRight: '20px solid transparent',
        borderTop: '20px solid #e8e6e6',
        marginLeft: 150
    },
    latest_box: {
        width: 400,
        backgroundColor: '#C6C6C61A',
        marginTop: -60,
        marginLeft: -10,
        // borderColor: '#7676764D',
        borderTop: '1px solid #7676764D',
        borderBottom: '1px solid #7676764D',
        borderLeft: '1px solid #7676764D',
        borderRight: '1px solid #7676764D',
        padding: 20
    },

    spacebetween: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    semiBold: {
        fontSize: '1.3rem',
        fontFamily: 'AssistantSemiBold',
        color: '#404040',
    },
    regular: {
        fontSize: '1rem',
        fontFamily: 'AssistantRegular',
        color: '#404040',
    },
    Only_flex: {
        flexDirection: 'row',
        display: 'flex'
    },

    // arrow_left:{
    //     width: 0,
    //     height: 0,
    //     borderTop: '20px solid transparent',
    //     borderBottom: '20px solid transparent', 

    //     borderRight:'20px solid #f2eeed',
    //     marginTop: 50,
    //     marginLeft: -30
    // },
    // latest_box:{
    //     width: 400,
    //     backgroundColor:'#C6C6C61A',
    //     marginTop: -60,
    //     marginLeft: -10,
    //     // borderColor: '#7676764D',
    //     borderTop: '1px solid #7676764D',
    //     borderBottom: '1px solid #7676764D',
    //     borderLeft: '1px solid #7676764D',
    //     borderRight: '1px solid #7676764D',
    //     padding: 20
    // },
    circle: {
        width: 15,
        height: 15,
        backgroundColor: '#fff',
        // borderColor: '#7676764D',
        borderTop: '2px solid #404040',
        borderBottom: '2px solid #404040',
        borderLeft: '2px solid #404040',
        borderRight: '2px solid #404040',
        borderRadius: 15
    },
    toolTipFont: {
        marginLeft: 10,
        color: '#404040',
        fontFamily: 'AssistantRegular',
        fontSize: '1rem',
        marginTop: -5
    },
    toolTipHead: {

        color: '#404040',
        fontFamily: 'AssistantSemiBold',
        fontSize: '1.2rem'
    },
    toolTipHead1: {

        color: '#7676764D',
        fontFamily: 'AssistantSemiBold',
        fontSize: '1.1rem'
    },
    toolTipHead2: {
        color: '#404040',
        fontFamily: 'AssistantSemiBold',
        fontSize: '1.1rem',
        marginLeft: 8
    },
});


export default function AccountBlocked() {

    const classes = useStyles();

    const [tab, setTab] = useState<any>(1);

    const [inbox, setInbox] = useState<any>(false);
    const [pwd, setPwd] = useState<any>(false);
    const [unlock] = useState<any>(false);



    useEffect(() => {
        document.title = 'Financial Ombudsman Portal - Account blocked'
    }, [])



    return (
        <div style={{ paddingTop: 50 }} id='main-content'>
            <Container sx={{ paddingBottom: 10 }}>
                <p className={classes.strippara}>Got your account locked?</p>
                <div style={{ display: 'flex' }}>
                    <p className={classes.para1}>Fill in the below to get your account unlock linkon your registered email ID.</p>
                </div><br />
                <Box>
                    <Grid container
                        sx={{
                            border: '1px solid #DDDDDD',
                            borderRadius: 2,
                            backgroundColor: '#F7F7F7'
                        }}
                    >
                        <p className={classes.strippara1}>Unlock your account in three simple steps!</p><br />
                        <Grid container sx={{
                            padding: 3
                        }}
                        >

                            <Grid item md={4} sm={12} xs={12}>
                                <div style={
                                    tab === 1 ?
                                        {
                                            border: '1px solid #DDDDDD',
                                            borderRadius: 2,
                                            padding: 15,
                                            backgroundColor: '#fff',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        } :
                                        inbox ? {
                                            padding: 15,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            opacity: 0.2,
                                        } :
                                            {

                                                // border:'1px solid #DDDDDD',
                                                // borderRadius: 2,
                                                padding: 15,
                                                // backgroundColor:'#fff',
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}>
                                    <div>
                                        <svg width="76px" height="76px" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M7 8l5 3 5-3" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10 20H4a2 2 0 01-2-2V6a2 2 0 012-2h16a2 2 0 012 2v6.857" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M22 17.111h-6.3c-3.6 0-3.6 4.889 0 4.889m6.3-4.889L18.85 14M22 17.111l-3.15 3.111" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        <div style={
                                            inbox ?
                                                {
                                                    border: '1px solid #DDDDDD',
                                                    borderRadius: 20,
                                                    padding: 15,
                                                    width: 40,
                                                    height: 40,
                                                    backgroundColor: 'green',
                                                    position: 'absolute',
                                                    marginTop: -90,
                                                    marginLeft: 40
                                                } :
                                                {
                                                    border: '1px solid #DDDDDD',
                                                    borderRadius: 20,
                                                    padding: 15,
                                                    width: 40,
                                                    height: 40,
                                                    backgroundColor: '#2A364A',
                                                    position: 'absolute',
                                                    marginTop: -90,
                                                    marginLeft: 40
                                                }}>
                                            <p style={{
                                                marginTop: -10,
                                                color: '#fff'
                                            }}>1</p>
                                        </div>
                                    </div>
                                    <h6 style={{
                                        marginTop: 10,
                                        marginLeft: 20,
                                    }}>Check your inbox for "Unlock your account" email</h6>
                                </div>
                                {tab === 1 && (<div className={classes.arrow_left1}></div>)}
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                                <div style={
                                    tab === 2 ?
                                        {
                                            border: '1px solid #DDDDDD',
                                            borderRadius: 2,
                                            padding: 15,
                                            backgroundColor: '#fff',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        } : pwd ? {
                                            padding: 15,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            opacity: 0.2,
                                        } : {
                                            // border:'1px solid #DDDDDD',
                                            // borderRadius: 2,
                                            padding: 15,
                                            // backgroundColor:'#fff',
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}>
                                    <div>
                                        <svg width="76px" height="76px" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M7 8l5 3 5-3" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10 20H4a2 2 0 01-2-2V6a2 2 0 012-2h16a2 2 0 012 2v6.857" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M22 17.111h-6.3c-3.6 0-3.6 4.889 0 4.889m6.3-4.889L18.85 14M22 17.111l-3.15 3.111" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        <div style={pwd ?
                                            {
                                                border: '1px solid #DDDDDD',
                                                borderRadius: 20,
                                                padding: 15,
                                                width: 40,
                                                height: 40,
                                                backgroundColor: 'green',
                                                position: 'absolute',
                                                marginTop: -90,
                                                marginLeft: 40
                                            } :
                                            {
                                                border: '1px solid #DDDDDD',
                                                borderRadius: 20,
                                                padding: 15,
                                                width: 40,
                                                height: 40,
                                                backgroundColor: '#2A364A',
                                                position: 'absolute',
                                                marginTop: -90,
                                                marginLeft: 40

                                            }}>
                                            <p style={{
                                                marginTop: -10,
                                                color: '#fff'
                                            }}>2</p>
                                        </div>
                                    </div>
                                    <h6 style={{
                                        marginTop: 10,
                                        marginLeft: 20,
                                    }}>Choose a new password to sign-in and you are ready</h6>
                                </div>
                                {tab === 2 && (<div className={classes.arrow_left1}></div>)}
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                                <div style={
                                    tab === 3 ?
                                        {
                                            border: '1px solid #DDDDDD',
                                            borderRadius: 2,
                                            padding: 15,
                                            backgroundColor: '#fff',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        } : unlock ? {
                                            padding: 15,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            opacity: 0.2,
                                        } : {
                                            // border:'1px solid #DDDDDD',
                                            // borderRadius: 2,
                                            padding: 15,
                                            // backgroundColor:'#fff',
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}>
                                    <div>
                                        <svg width="76px" height="76px" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M7 8l5 3 5-3" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10 20H4a2 2 0 01-2-2V6a2 2 0 012-2h16a2 2 0 012 2v6.857" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path><path d="M22 17.111h-6.3c-3.6 0-3.6 4.889 0 4.889m6.3-4.889L18.85 14M22 17.111l-3.15 3.111" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        <div style={{
                                            border: '1px solid #DDDDDD',
                                            borderRadius: 20,
                                            padding: 15,
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#2A364A',
                                            position: 'absolute',
                                            marginTop: -90,
                                            marginLeft: 40
                                        }}>
                                            <p style={{
                                                marginTop: -10,
                                                color: '#fff'
                                            }}>3</p>
                                        </div>
                                    </div>
                                    <h6 style={{
                                        marginTop: 10,
                                        marginLeft: 20,
                                    }}>Submit your request using this 'Unlock my account' from</h6>
                                </div>
                                {tab === 3 && (<div className={classes.arrow_left1}></div>)}
                            </Grid>

                        </Grid>
                        <Grid container
                            sx={{
                                border: '1px solid #4040401F',
                                borderRadius: 2,
                                padding: 5,
                                backgroundColor: '#F2F2F2'
                            }}
                        >
                            <Grid item md={12} sm={12} xs={12}>
                                {tab === 1 && (
                                    <>
                                        <h5>Enter your email ID registered with us, to get your account unlocking link:</h5><br />
                                        <div>
                                            <TextField id="outlined-basic"  variant="outlined" style={{ width: 500, color: '#fff', borderWidth: 2, borderColor: '#404040', backgroundColor: '#fff' }} />
                                        </div><br />
                                        <div
                                            className={classes.btn1}
                                            onClick={() => {
                                                setTab(2);
                                                setInbox(true)
                                            }}
                                        >
                                            Submit unlock request
                                        </div>
                                    </>
                                )}
                                {tab === 2 && (
                                    <>
                                        <div className={classes.striptwo}>
                                            <h6>Your request has been submitted successfully and email is on your way.</h6>
                                            <p>if you have provided a valid email ID that is already registered with us, you will get an account unlock link email soon.</p>
                                            <br />
                                            <p><b>Didn't get your unlock email?</b> PLease also check your Spam folder or request a new link.</p>
                                            <div
                                                className={classes.btn1}
                                                onClick={() => {
                                                    setTab(3);
                                                    setInbox(true)
                                                    setPwd(true)
                                                }}
                                            >
                                                {`< Back to Sign-in`}
                                            </div>

                                        </div>
                                    </>
                                )}
                                {tab === 3 && (
                                    <>
                                        <Grid container>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <h6>Choose you new password to sign-in</h6>
                                                <p>We recommended you to change your password regularly, complaint to our password policy shown below.</p><br />
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <div>
                                                    <div className={classes.Only_flex}>
                                                        <p className={classes.details}>Enter your new password:</p>
                                                        <p className={classes.details12}>*</p>
                                                    </div>
                                                    <div>
                                                        <TextField id="outlined-basic" label="Enter new password" variant="outlined" style={{ width: 300, color: '#404040', borderWidth: 2, borderColor: '#404040' }} />
                                                    </div>
                                                </div><br />
                                                <div>
                                                    <div className={classes.Only_flex}>
                                                        <p className={classes.details}>Confirm your new password:</p>
                                                        <p className={classes.details12}>*</p>
                                                    </div>
                                                    <div>
                                                        <TextField id="outlined-basic" label="Confirm your new password" variant="outlined" style={{ width: 300, color: '#404040', borderWidth: 2, borderColor: '#404040' }} />
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <div>
                                                    <div className={classes.arrow_left}></div>
                                                    <div className={classes.latest_box}>
                                                        <div className={classes.Only_flex}>
                                                            <p className={classes.toolTipHead1}>Password strength:</p>
                                                            <p className={classes.toolTipHead2}>{" "}No password entered</p>
                                                        </div><br />
                                                        <BorderLinearProgress variant="determinate" value={0} /><br />
                                                        <p className={classes.toolTipHead}>Your strong password must be:</p>
                                                        <div className={classes.Only_flex}>
                                                            <div className={classes.circle}></div>
                                                            <p className={classes.toolTipFont}>At least 10 characters</p>
                                                        </div>
                                                        <div className={classes.Only_flex}>
                                                            <div className={classes.circle}></div>
                                                            <p className={classes.toolTipFont}>Contains at least 1 uppercase (A-Z) and 1 lowercase (a-z) letter</p>
                                                        </div>
                                                        <div className={classes.Only_flex}>
                                                            <div className={classes.circle}></div>
                                                            <p className={classes.toolTipFont}>Includes at least 1 special character (one of these: @, &, $, !, #)</p>
                                                        </div>
                                                        <div className={classes.Only_flex}>
                                                            <div className={classes.circle}></div>
                                                            <p className={classes.toolTipFont}>Contain at least 1 digit (0-9)</p>
                                                        </div>
                                                        <div className={classes.Only_flex}>
                                                            <div className={classes.circle}></div>
                                                            <p className={classes.toolTipFont}>Is not same as previously used three passwords</p>
                                                        </div><br />
                                                        <p className={classes.toolTipFont}>Tip: We recommend you to not use personal information (like your name, date of birth, your pet’s name, etc.)</p>
                                                    </div>
                                                </div><br />

                                            </Grid>
                                            <hr />
                                            <div style={{ marginLeft: 700, flexDirection: 'row', display: 'flex' }}>
                                                <p className={classes.btn}>Cancel</p>&nbsp;&nbsp;&nbsp;
                                            <button className={classes.btn1}>Confirm password change</button>
                                            </div>
                                        </Grid>
                                    </>
                                )}

                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>


        </div>
    )
}