import {useEffect, useState} from 'react';
import FileUploadStepper from '../../components/complaint-table/FileUploadStepper';
import {makeStyles} from '@mui/styles';
import Icon_FileScan from '../../assets/icons/Icon_FileScan.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import checkIcon from '../../assets/icons/Check.svg';
import cancelIcon from '../../assets/icons/Cancel.svg';
import CancelPopup from '../../common/component-lib/CancelPopup';
import pdf from '../../assets/icons/Icon_Pdf.svg';
import word from '../../assets/icons/Icon_Word.svg';
import image from '../../assets/icons/Icon_Image.svg';
import { DRUPALSERVER } from '../../common/drupal-api/drupal-api-urls';
import DrupalService from '../../common/drupal-api/drupal-api-service';

const drupalApi = new DrupalService();
const defaultDrupalData = [
    {
        "title":"Virus scanning completed.",
        "description":"Based on the virus scan report below, you can choose to proceed to upload the files that have passed the virus scan by clicking on Continue button and scan and upload the files that failed the scan separately later. Alternatively, you can click on Cancel to go back to previous step.",
        "subTitle":"files scanned for virus in this session:",
        "passed":"File(s) passed the virus scan",
        "failed":"File(s) failed the virus scan",
        "clean":"Clean","infected":"Infected",
        "cancelBtn":"Cancel",
        "continueBtn":"Continue to upload clean files"
    }
];
const defaultDrupalData1 = [
    {
        "uploading":"Uploading your documents...",
        "pleasewait":"Please wait while we upload your files to our server.",
        "description":"You have successfully uploaded the files. We will take some time to review the files before it shows up on the case details.",
        "backBtn":"Back to case details",
        "title":"Scanning your files for virus...",
        "pleaseWaitScan":"Please wait while we scan your files for viruses.",
        "info":"We will shortly let you know if your files have passed the virus scan and are fit to be uploaded."
    }
];
const FileScan = () => {
    const classes = useStyles();
    const location:any = useLocation();
    const [files] = useState(location.state.files);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [drupalData, setDrupalData] = useState<any>(defaultDrupalData);
    const [drupalData1, setDrupalData1] = useState<any>(defaultDrupalData1);
    
    useEffect(() => {
        drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getFileScanPage, 'get')
            .then((res) => {
                if (res.length > 0) {
                    setDrupalData(res);
                } else {
                    setDrupalData(defaultDrupalData);
                }
            })
            .catch((_e: any) => {
                setDrupalData(defaultDrupalData);
            });

            drupalApi
            .httpConnection(DRUPALSERVER.SERVICES.getLoadAndSuccessPage, 'get')
            .then((res) => {
                if (res.length > 0) {
                    setDrupalData1(res);
                } else {
                    setDrupalData1(defaultDrupalData1);
                }
            })
            .catch((_e: any) => {
                setDrupalData1(defaultDrupalData1);
            });
    }, []);
    
    const handleClose = () => {
        setOpen(false);
    };
    const dialogHandling = () => {
        setOpen(true);
    }
    const decideImage=(type:string)=>{
        if(type==='Pdf') return (<img src={pdf} alt="" className={classes.imgIcon} />);
        else if(type==='png' || type==='jpg') return (<img src={image} alt="" className={classes.imgIcon} />);
        else if(type==='Word') return (<img src={word} alt="" className={classes.imgIcon} />);
        else return '';
    }
    return (
        <div id='main-content'>
            <FileUploadStepper step = {3} pnx = {location.state.pnx} />
            <hr className={classes.subDividerLine} />
            <h2 className = {classes.scanningTitle}>{drupalData1[0].title}</h2>
            <div className = {classes.scanContainer}>
                <div role={'alert'} aria-live={'assertive'} className = {classes.blueContainer}>
                    <img src={Icon_FileScan} alt=''/>
                    <h2 className = {classes.scanHeading}>{drupalData1[0].pleaseWaitScan}</h2>
                    <p className = {classes.scanContent}>{drupalData1[0].info}</p>
                </div>
            </div>
            <h1 className = {classes.scanningTitle}>{drupalData[0].title}</h1>
            <p className = {classes.scanPara}>{drupalData[0].description}</p>
            <div className = {classes.megaContainer}>
                <h2 className = {classes.scanningTitle}>{files.length} {drupalData[0].subTitle}</h2>
                <div className = {classes.passedTitle}>
                    <div className = {classes.passedCount}>{files.length}</div>
                    <p className = {classes.passedText}>{drupalData[0].passed}</p>
                </div>
                <div className = {classes.passFilesContainer}>
                {
                    files.map((file:any,index:any) => {
                        return(
                            <div key={index} className = {classes.fileContainer}>
                                {decideImage(file.path.split('.')[1])}
                                <p className = {classes.fileName}>{file.name}</p>
                                <div className = {classes.flexPass}>
                                    <img src={checkIcon} alt=''/>
                                    <p className = {classes.pass}>{drupalData[0].clean}</p>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
                <div className = {classes.passedTitle}>
                    <div className = {classes.failedCount}>{files.length}</div>
                    <p className = {classes.passedText}>{drupalData[0].failed}</p>
                </div>
                <div className = {classes.passFilesContainer}>
                {
                    files.map((file:any,index:number) => {
                        return(
                            <div key={index} className = {classes.fileContainer}>
                                {decideImage(file.path.split('.')[1])}
                                <p className = {classes.fileName}>{file.name}</p>
                                <div className = {classes.flexPass}>
                                    <img src={cancelIcon} alt=''/>
                                    <p className = {classes.fail}>{drupalData[0].infected}</p>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
                <div className = {classes.buttons}>
                <button className = {classes.backCta}  onClick = {dialogHandling} >{drupalData[0].cancelBtn}</button>
                <CancelPopup
                    open={open}
                    onClose={handleClose}
                    incId={location.state.incId}
                    />
                <button className = {classes.proceedBtn} onClick = {() => {navigate(`/view-case-document-upload-success`,{state:{'pnx':location.state.pnx, 'incId':location.state.incId}})}}>{drupalData[0].continueBtn}</button>
            </div>

            </div>
        </div>
    )
}

export default FileScan

const useStyles = makeStyles({
    imgIcon: {
        height: '3rem',
    },
    subDividerLine: {
        backgroundColor: '#70707030',
        height: '1px',
        border: 0
    },
    scanningTitle: {
        font: '1.75rem AssistantSemiBold',
        color: '#000000',
        marginBlockStart: '2rem',
        marginBlockEnd: '1.625rem'
    },
    scanPara: {
        marginBlockStart: '0',
        marginBlockEnd: '1.5rem',
        font: '1.375rem AssistantRegular',
        color: '#404040'
    },
    scanContainer: {
        marginTop: '1.5rem',
        marginBottom: '1.5rem'
    },
    blueContainer: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #404040',
        borderRadius: '0.5rem',
        padding: '4.75rem 1rem',
        textAlign: 'center'
    },
    scanHeading: {
        font: '1.625rem AssistantSemiBold',
        color: '#000000',
        marginBlockStart: '1.75rem',
        marginBlockEnd: '1.25rem'
    },
    scanContent: {
        font: '1.25rem AssistantRegular',
        color: '#1D1D1D',
        marginBlock: '0'
    },
    megaContainer: {
    },
    passedTitle: {
        backgroundColor: '#E1E1E1',
        display: 'flex',
        alignItems: 'center',
        padding: '1.125rem 1.5rem',
        border: '1px solid #CFCFCF',
        borderBottom: 'none'
    },
    passedCount: {
        backgroundColor: '#567B23',
        borderRadius: '4px',
        display: 'flex',
        width: '1.75rem',
        height: '1.75rem',
        justifyContent: 'center',
        alignItems: 'center',
        font: '1.25rem AssistantSemiBold',
        color: '#fff',
        marginRight: '1rem'
    },
    failedCount: {
        backgroundColor: '#962047',
        borderRadius: '4px',
        display: 'flex',
        width: '1.75rem',
        height: '1.75rem',
        justifyContent: 'center',
        alignItems: 'center',
        font: '1.25rem AssistantSemiBold',
        color: '#fff',
        marginRight: '1rem'
    },
    passedText: {
        font: '1.5rem AssistantSemiBold',
        color: '#404040',
        marginBlock: '0'
    },
    passFilesContainer: {
        border: '1px solid #CFCFCF',
        borderTop: 'none',
        borderBottom: 'none',
        marginBottom: '2.5rem'
    },
    fileContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '1.125rem 1.5rem',
        borderBottom: '1px solid #DADADA',
        background: '#FFFFFF',
        flexWrap: 'wrap'
    },
    fileName: {
        font: '1.375rem AssistantRegular',
        color: '#404040',
        marginBlock: '0',
        marginLeft: '1rem',
        overflowWrap: 'anywhere',
        flexGrow: '1'
    },
    flexPass: {
        display: 'flex',
        alignItems: 'center',
    },
    pass: {
        font: '1.25rem AssistantRegular',
        color: '#567B23',
        marginBlock: '0',
        marginLeft: '0.5rem'
    },
    fail: {
        font: '1.25rem AssistantRegular',
        color: '#962047',
        marginBlock: '0',
        marginLeft: '0.5rem'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '2.063rem',
        flexWrap: 'wrap'
    },
    backCta: {
        padding: '0.688rem 1.5rem',
        border: '1px solid #D22D64',
        color: '#D22D64',
        font: '1.25rem AssistantSemiBold',
        width: 'fit-content',
        borderRadius: '30px',
        marginRight: '1.5rem',
        backgroundColor: '#fff',
        cursor: 'pointer',
        '&:hover':{
            backgroundColor: '#FAE5EC'
        },
        gridArea: 'backCta',
        '@media (max-width: 640px)' : {
            width: '100%',
            marginRight: '0'
          }
    },
    proceedBtn: {
        backgroundColor: '#D22D64',
        color: '#fff',
        font: '1.25rem AssistantSemiBold',
        padding: '0.688rem 1.5rem',
        borderRadius: '1.5rem',
        border: 'none',
        cursor: 'pointer',
        '&:hover':{
            backgroundColor: '#B8134A'
        },
        '@media (max-width: 640px)' : {
            width: '100%',
            marginTop: '2.063rem'
          }

    }

})
