import { createSlice } from "@reduxjs/toolkit";

interface ProgressType {
    percentages: {}
}

const initialState:ProgressType ={
    percentages: {}
}

const UploadProgressSlice = createSlice({
    name: 'uploadProgress',
    initialState,
    reducers: {
        updatePercentage: (state:any,action)=>{
            if(state.percentages[action.payload.name]!==null){
                state.percentages[action.payload.name]={percentage: action.payload.percentage, documentId: action.payload.documentId,  uploadDone: action.payload.uploadDone}
            }else{
                let name = action.payload.name;
                let percentage = {
                    percentage: action.payload.percentage,
                    documentId: action.payload.documentId,
                    uploadDone: action.payload.uploadDone
                };
                state.percentages = {...state.percentages,[name]:percentage}
                console.log(percentage)
            }
        },
        clearPercentages: (state)=>{
            state.percentages = {};
        }
    }
});

export const {updatePercentage, clearPercentages} = UploadProgressSlice.actions;
export default UploadProgressSlice.reducer;