import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import BreadcrumbSeperator from '../assets/icons/Chevron_Right.svg'
import { makeStyles } from "@mui/styles";
import CompatabilityMessage from "../components/CompatabilityMsg";
import { useEffect, useState } from 'react';
function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.preventDefault();
}
const useStyles = makeStyles({
  subHeaders: {
    color: "#404040",
    fontFamily: "AssistantSemiBold",
    fontSize: '1.25rem',
    opacity:'100%'
  },
  menuItems: {
    '&:hover': {
      textDecoration: 'underline !important'
    },
    backgroundColor: "#EFEFEF",
    padding: '0px 2px 0px 2px',
    marginLeft: '4rem',
    color: '#271C6F',
    fontFamily: 'AssistantSemiBold',
    fontSize: '20px'
  },
  displayDiv: {
    display: 'block'
  },
  donotDisplayDiv: {
    display: 'none'
  },
  compatabilityDivClass: {
    height: '6rem',
    alignItems: 'center',
    display: 'flex',
    color: '#271C6F',
    fontFamily: 'AssistantSemiBold',
    fontSize: '20px'
  },
  mainDivClass: {
    backgroundColor: "#EFEFEF",
    height: '64px',
    alignItems: 'center',
    display: 'flex',
    color: '#271C6F',
    fontFamily: 'AssistantSemiBold',
    paddingLeft: '1rem',
  },
  navLinkClass: {
    backgroundColor: "#EFEFEF",
    color: '#271C6F',
    fontFamily: 'AssistantSemiBold',
    fontSize: '20px',
    '&:hover': {
      textDecoration: 'underline !important'
    },
  }
});

export default function BasicBreadcrumbs(prop: any) {
  const classes = useStyles();
  const [compDismiss, setCompDismiss] = useState(sessionStorage.getItem('compatability'))
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const hookUserAgent = window.navigator.userAgent;
  const IExplorerAgent = hookUserAgent.indexOf("MSIE") > -1 || hookUserAgent.indexOf("rv:") > -1 || hookUserAgent.indexOf("Edg") > -1;
  let chromeAgent = hookUserAgent.indexOf("Chrome") > -1;
  if ((chromeAgent) && (IExplorerAgent)) chromeAgent = false;
  // Detect Safari 
  let safariAgent = hookUserAgent.indexOf("Safari") > -1;
  // Discard Safari since it also matches Chrome 
  if (((IExplorerAgent) || (chromeAgent)) && (safariAgent)) safariAgent = false;

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  const handleCompDismiss = () =>{
    setCompDismiss('dismissed');
   // window.location.reload();
  }
  useEffect(() => { window.addEventListener("resize", handleResize); }, [])
  return (
    <>
      <div role="presentation" data-testid="id" onClick={handleClick} className={classes.mainDivClass}
      >
        <Breadcrumbs aria-label="breadcrumb"
          separator={<img alt='seperator' src={BreadcrumbSeperator} />}
        >
          <NavLink color="inherit"
            to="/ombudsman-dashboard"
            className={classes.menuItems}
          >
            {prop.home}
          </NavLink>
          {prop.parentpagelink &&
            <NavLink color="text.primary" to={prop.parentpagelink}
              style={{
                backgroundColor: "#EFEFEF",
                color: '#271C6F',
                fontFamily: 'AssistantSemiBold',
                fontSize: '20px',
              }}
              className={classes.navLinkClass}>
              {prop.parentpage}
            </NavLink>
          }
          <Typography className={classes.subHeaders} color="text.primary">{prop.propCurrentPage}</Typography>
        </Breadcrumbs>

      </div>
      {((!chromeAgent && !safariAgent && !IExplorerAgent)
        || (window.innerWidth && window.innerWidth < 992))
        &&
        <div className={
          (compDismiss === 'dismissed') ? classes.donotDisplayDiv : classes.displayDiv}>
          <div className={classes.compatabilityDivClass}
          > <CompatabilityMessage handleCompDismiss={handleCompDismiss}></CompatabilityMessage></div></div>}
    </>
  );
}
